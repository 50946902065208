import * as types from '../constants/actionTypes';
import { Modals } from './types';

export const initialState: Modals = {
  smsLimitModal: {
    shouldShow: false,
    errorMessage: null,
    smsMessage: {
      channelName: null,
      type: null,
      category: null,
      messageBody: null,
      attachments: null,
      garageLink: null,
      uuid: null,
    },
    smsMessageTempId: null,
  },
};

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case types.SHOW_SMS_LIMIT_MODAL:
      return {
        ...state,
        smsLimitModal: {
          ...state.smsLimitModal,
          shouldShow: true,
        },
      };

    case types.HIDE_SMS_LIMIT_MODAL:
      return {
        ...state,
        smsLimitModal: {
          ...state.smsLimitModal,
          shouldShow: false,
        },
      };

    case types.SET_SMS_LIMIT_MODAL_ERROR_CODE:
      return {
        ...state,
        smsLimitModal: {
          ...state.smsLimitModal,
          errorMessage: payload,
        },
      };

    case types.SET_SMS_LIMIT_MESSAGE_TO_SEND:
      return {
        ...state,
        smsLimitModal: {
          ...state.smsLimitModal,
          smsMessage: payload,
        },
      };

    case types.SET_SMS_LIMIT_MESSAGE_TEMP_ID:
      return {
        ...state,
        smsLimitModal: {
          ...state.smsLimitModal,
          smsMessageTempId: payload,
        },
      };

    default:
      return state;
  }
};
