import assign from 'lodash.assign';
import { redirectToRoute, buildFormErrors } from 'businessLogic/util';
import * as types from 'redux/constants/actionTypes';
import { getActiveConversation } from 'selectors/conversationSelectors';
import { getSelectedCustomerLocations } from 'selectors/locationSelectors';
import { addErrorNotification } from './notificationActions';
import { FORM_ERROR } from 'final-form';

export function fetchLocationHotkeys(locationId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_HOTKEYS_FETCHING, payload: { locationId } });

    const api = APIFactory.getInstance(getState());
    return api
      .get(`/location/${locationId}/rawhotkeys`)
      .then(response => {
        dispatch({
          type: types.LOCATION_HOTKEYS_FETCHED,
          payload: { locationId, hotkeys: response.data.items },
        });
      })
      .catch(() => {
        dispatch({ type: types.LOCATION_HOTKEYS_FETCH_ERROR, payload: { locationId } });
      });
  };
}

export function fetchLocationHotkeysAdmin(locationId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_HOTKEYS_FETCHING, payload: { locationId } });

    const api = APIFactory.getInstance(getState());
    return api
      .get(`admin/hotkey?locationId=${locationId}`)
      .then(response => {
        dispatch({
          type: types.LOCATION_HOTKEYS_FETCHED,
          payload: { locationId, hotkeys: response.data.items },
        });
      })
      .catch(() => {
        dispatch({ type: types.LOCATION_HOTKEYS_FETCH_ERROR, payload: { locationId } });
      });
  };
}

export function deleteHotkey(hotkeyId, locationId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.HOTKEY_DELETING });

    const api = APIFactory.getInstance(getState());
    return api
      .delete(`/admin/hotkey/${hotkeyId}`)
      .then(() => {
        dispatch({ type: types.HOTKEY_DELETED, payload: { locationId, hotkeyId } });
      })
      .catch(() => {
        dispatch({ type: types.HOTKEY_DELETE_ERROR, payload: { hotkeyId } });
        dispatch(addErrorNotification('Error deleting hotkey'));
      });
  };
}

export function createHotkey({ name, category, hotkey, locationId, favorite, text }) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.HOTKEY_SAVING });

    const payload = {
      name,
      category,
      hotkey,
      locationId: Number(locationId),
      favorite: !!favorite,
      text,
    };

    const api = APIFactory.getInstance(getState());
    return api
      .post('/admin/hotkey', payload)
      .then(response => {
        const hotkeyId = response.data.id;
        dispatch({
          type: types.HOTKEY_SAVED,
          payload: assign({}, payload, { id: hotkeyId }),
        });
        setTimeout(() => redirectToRoute(`/admin/hotkey`), 3000);
        return undefined;
      })
      .catch(() => {
        dispatch({ type: types.HOTKEY_SAVE_ERROR });
        return {[FORM_ERROR]: 'Error creating hotkey.'};
      });
  };
}

export function updateHotkey({ hotkeyId, name, category, hotkey, locationId, favorite, text }) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.HOTKEY_SAVING });

    const payload = {
      name,
      category,
      hotkey,
      locationId: Number(locationId),
      favorite: !!favorite,
      text,
    };

    const api = APIFactory.getInstance(getState());
    return api
      .patch(`/admin/hotkey/${hotkeyId}`, payload)
      .then(() => {
        dispatch({
          type: types.HOTKEY_SAVED,
          payload: assign({}, payload, { id: hotkeyId }),
        });
        return undefined;
      })
      .catch(() => {
        dispatch({ type: types.HOTKEY_SAVE_ERROR });
        return {[FORM_ERROR]: 'Error updating hotkey.'};
      });
  };
}

export function fetchHotkey(hotkeyId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.HOTKEY_FETCHING, payload: { hotkeyId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/admin/hotkey/${hotkeyId}`)
      .then(response => {
        dispatch({ type: types.HOTKEY_FETCHED, payload: response.data });
      })
      .catch(() => {
        dispatch({ type: types.HOTKEY_FETCH_ERROR, payload: { hotkeyId } });
      });
  };
}

export function toggleHotkeyPanel(channel) {
  return {
    type: types.TOGGLE_HOTKEY_PANEL,
    payload: { channel },
  };
}

export function logHotkeyUse(hotkey) {
  return (dispatch, getState, { APIFactory }) => {
    const channel = getActiveConversation(getState()).get('channel');
    const api = APIFactory.getInstance(getState());
    return api
      .post('/conversation/agent/usedhotkey', {
        channelName: channel,
        hotkeyId: hotkey.get('id'),
      })
      .catch(() => {
        // error logging this hotkey usage, but it's not something we want to notify the user about
      });
  };
}

export function setActiveHotkey(hotkey) {
  return dispatch => {
    dispatch({ type: types.SET_ACTIVE_HOTKEY, payload: { hotkey, timestamp: Date.now() } });
    dispatch(logHotkeyUse(hotkey));
  };
}

export function fetchCustomerHotkeys(customerId) {
  return (dispatch, getState) => {
    const locations = getSelectedCustomerLocations(getState());
    if (locations.size === 0) return Promise.resolve();

    dispatch({ type: types.CUSTOMER_HOTKEYS_FETCHING, payload: { customerId } });

    const promises = [];
    locations.forEach(location => {
      promises.push(dispatch(fetchLocationHotkeysAdmin(location.get('id'))));
    });

    return Promise.all(promises)
      .then(() => dispatch({ type: types.CUSTOMER_HOTKEYS_FETCHED, payload: { customerId } }))
      .catch(() => {
        dispatch({ type: types.CUSTOMER_HOTKEYS_FETCH_ERROR, payload: { customerId } });
        dispatch(addErrorNotification('Error fetching hotkeys.'));
      });
  };
}
