import 'styles/login.scss';

import React from 'react';
import { ChangeEvent, FormEvent } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from 'redux/actions/usersActions';
import { Link, RouteComponentProps } from 'react-router-dom';
import Spinner from '../Shared/Spinner/Spinner';
import { parse } from 'query-string';

import logo from 'images/logo.svg';

export interface Props extends RouteComponentProps<any> {
  resetPassword: (token: string, username: string, password: string) => Promise<any>;
}

interface State {
  username: string;
  password: string;
  passwordConfirmation: string;
  submitting: boolean;
  submitSuccess: boolean;
  error: string;
}

export class ResetPassword extends React.Component<Props, State> {
  state = {
    username: '',
    password: '',
    passwordConfirmation: '',
    submitting: false,
    error: '',
    submitSuccess: false,
  };
  passwordEl: HTMLInputElement | null;

  componentDidMount() {
    // use email parameter (the name is wrong) to prefill the username
    const parsed = parse(this.props.location.search);
    if (parsed.email) this.setState({ username: parsed.email });

    // autofocus email field
    if (this.passwordEl) this.passwordEl.focus();
  }

  handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: e.target.value });
  };

  handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.target.value });
  };

  handlePasswordConfirmationChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ passwordConfirmation: e.target.value });
  };

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { password, passwordConfirmation, submitting } = this.state;

    if (password.trim().length < 8) {
      this.setState({ error: 'The password must be at least 8 characters.' });
      return;
    }
    if (password !== passwordConfirmation) {
      this.setState({ error: 'The password and password confirmations do not match.' });
      return;
    }

    if (submitting) return;

    try {
      this.setState({ submitting: true, error: '', submitSuccess: false });

      await this.props.resetPassword(
        this.props.match.params.hash,
        this.state.username,
        this.state.password
      );
      this.setState({ submitSuccess: true, error: '' });
    } catch (e) {
      this.setState({
        submitSuccess: false,
        error: 'There was an error resetting your password.',
      });
    } finally {
      this.setState({ submitting: false });
    }
  };

  renderSuccess() {
    return (
      <div className="successful-reset">
        <div>
          <span className="check-mark" dangerouslySetInnerHTML={{ __html: '&check;' }} />
        </div>
        <div>
          Your password has been reset! <Link to="/login">Click here</Link> to return to
          the login page.
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        <h1>Change Your Password</h1>

        {this.state.error && <div className="auth-errors">{this.state.error}</div>}

        <form role="form" onSubmit={this.handleSubmit}>
          <div>
            <input
              type="text"
              placeholder="Username"
              name="username"
              required={true}
              autoComplete="off"
              value={this.state.username}
              onChange={this.handleUsernameChange}
            />
          </div>
          <div>
            <input
              ref={c => (this.passwordEl = c)}
              type="password"
              name="password"
              placeholder="New Password"
              required={true}
              autoComplete="off"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Confirm New Password"
              name="password_confirmation"
              required={true}
              autoComplete="off"
              value={this.state.passwordConfirmation}
              onChange={this.handlePasswordConfirmationChange}
            />
          </div>
          <div>
            <button type="submit" disabled={this.state.submitting}>
              {this.state.submitting && <Spinner />}
              Reset Password
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="reset-wrapper login">
        <div className="reset-overlay-wrapper">
          <div>
            <div className="logo-wrapper">
              <img src={logo} alt="conversations logo" />
            </div>
            {this.state.submitSuccess ? this.renderSuccess() : this.renderForm()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(undefined, { resetPassword })(ResetPassword as any);
