/* eslint-disable @typescript-eslint/no-explicit-any */
import {ThunkAction} from 'redux-thunk';
import Constants from 'redux/constants';
import { AxiosStatic } from 'axios';
import * as types from 'redux/constants/actionTypes';
import { buildFormErrors } from 'businessLogic/util';
import { Template } from 'redux/Oem/types';

export function fetchOems(): ThunkAction<any, any, any, any> {
  return (dispatch: any, getState: any, { APIFactory }: any): any => {
    dispatch({
      type: Constants.OEMS_REQUESTED,
      payload: {},
    });
    return APIFactory.getInstance(getState())
      .get('/admin/oem')
      .then((response: any) => {
        dispatch({
          type: Constants.OEMS_RESPONDED,
          payload: { response },
        });
      })
      .catch((errors: any) => {
        dispatch({
          type: Constants.OEMS_EXCEPTION,
          payload: { errors: Array.isArray(errors) ? errors : [errors] },
        });
      });
  };
}

function uploadFile(
  api: AxiosStatic,
  file: string | Blob,
) {
  const data = new window.FormData();
  data.append('upload', file);
  return api
    .post('/utility/file', data, { timeout: 60000 })
    .then((response: any) => Promise.resolve(response));
}

function getAttachmentPromise(
  attachment: Blob | null | undefined,
  api: any,
) {
  if (attachment) {
    return uploadFile(api, attachment)
      .catch((response: any) => {
        throw buildFormErrors('Error uploading file.', response);
      });
  }

  return Promise.resolve();
}

export function updateTemplate(
  id: string,
  template: Template,
  avatarFile: Blob | null,
) {
  return (dispatch: any, getState: any, { APIFactory }: any) => {
    dispatch({ type: types.OEM_TEMPLATES_UPDATING });
    const api = APIFactory.getInstance(getState());

    const attachmentPromise = getAttachmentPromise(avatarFile, api);

    let attachmentUrl: string | null = null;
    const payload = { template };

    return Promise.all([attachmentPromise])
      .then((responses: any) => {
        if (responses && responses[0] && responses[0].data) {
          attachmentUrl = responses[0].data.url;
          payload.template.avatar = attachmentUrl;
        }
      })
      .then(() => api.put(
        `/admin/oem/${id}`,
        { template: JSON.stringify(payload.template) },
        { timeout: 60000 }
      ))
      .then(() => {
        dispatch({ type: types.OEM_TEMPLATES_SAVED });
        return payload;
      })
      .catch((error: any) => {
        dispatch({ type: types.OEM_TEMPLATES_SAVE_ERROR });
        return buildFormErrors('Error saving OEM Template', error.response);
      });
  };
}
