/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Map } from 'immutable';
import Constants from '../constants';


const initialState = Map();


interface Action {
  payload: {
    room: string;
    token?: string;
  };
  type: string;
}


export const reducer = (state = initialState, { payload, type }: Action) => {
  switch (type) {
    case Constants.VIDEO_ROOM_CONNECT:
      return state.setIn([payload.room, 'token'], undefined);

    case Constants.VIDEO_ROOM_CONNECTED:
      return state.setIn([payload.room, 'token'], payload.token);

    case Constants.VIDEO_ROOM_DISCONNECT:
      return state.setIn([payload.room, 'token'], undefined);

    case Constants.VIDEO_ROOM_TOGGLE:
      return state.setIn([payload.room, 'active'], !state.getIn([payload.room, 'active'], false));

    default:
      return state;
  }
};
