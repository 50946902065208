import { Action } from 'typedefs/redux';
import { AxiosResponse } from 'axios';
import * as types from "redux/constants/actionTypes";

export function fetchFeatures(): Action {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.FEATURE_FETCHING });
    const api = APIFactory.getInstance(getState());

    try {
      const response: AxiosResponse = await api.get(`/features`);
      const features = response.data;
      dispatch({
        type: types.FEATURE_FETCHED,
        payload: { features },
      });
    } catch {
      dispatch({ type: types.FEATURE_FETCH_ERROR });
    }
  };
}
