export interface Props {
  ejectUser: () => void;
  logoutUrl: string;
}

const Logout = ({ ejectUser, logoutUrl }: Props): null => {
  if (window) {
    ejectUser();
    window.location.href = logoutUrl;
  }

  return null;
};

export default Logout;

