import { Set, Map } from 'immutable';
import { getAllLocations } from './locationSelectors';

export const getAllTeams = state => (state.teams ? state.teams : Map());

export const makeGetUserTeams = state => userId => {
  const allTeams = getAllTeams(state);
  const allLocations = getAllLocations(state);

  let userTeams = allTeams.filter(team => team.get('userIds', Set()).has(userId));
  userTeams = userTeams.map(team => {
    const location = allLocations.find(internal =>
      internal.get('teamIds', Set()).has(team.get('id'))
    );
    return team.set('location', location);
  });

  return userTeams;
};
