import {Map} from "immutable";
import * as types from "redux/constants/actionTypes";
import {Feature, MappedFeatures} from "redux/Feature/types";

const initialState: MappedFeatures = Map({
  test: {
    status: false,
  },
});

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case types.FEATURE_FETCHED:
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return setFeatures(state, payload.features);
    default:
      return state;
  }
};

export const setFeatures = (state: MappedFeatures, features: Feature[]) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return state.withMutations(internal => {
    for (const feature in features) {
      internal.set(feature, {
        status: Boolean(features[feature]),
      });
    }
  });
};
