import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../redux/reducers';
import bugsnagErrorCatcherMiddleware from '../redux/middleware/bugsnag/errorCatcher';
import bugsnagBreadcrumbLoggerMiddleware from '../redux/middleware/bugsnag/breadcrumbLogger';
import * as APIFactory from '../services/APIFactory';
import Pusher from '../services/PusherManager';

const PusherManager = new Pusher(false);

export default function configureStore(initialState) {
  const middleware = [
    bugsnagErrorCatcherMiddleware(),
    thunk.withExtraArgument({ APIFactory, PusherManager }),
    bugsnagBreadcrumbLoggerMiddleware(),
  ];

  return createStore(rootReducer, initialState, applyMiddleware(...middleware));
}
