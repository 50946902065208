import assign from 'lodash.assign';
import { redirectToRoute, buildFormErrors } from 'businessLogic/util';
import * as types from 'redux/constants/actionTypes';
import { getSelectedCustomerLocations } from 'selectors/locationSelectors';
import { addErrorNotification } from './notificationActions';
import { FORM_ERROR } from 'final-form';

export function fetchLocationCRMs(locationId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_CRMS_FETCHING, payload: { locationId } });

    const api = APIFactory.getInstance(getState());
    return api
      .get(`/location/${locationId}/crms`)
      .then(response => {
        dispatch({
          type: types.LOCATION_CRMS_FETCHED,
          payload: { locationId, crms: response.data.crms },
        });
      })
      .catch(() => {
        dispatch({ type: types.LOCATION_CRMS_FETCH_ERROR, payload: { locationId } });
      });
  };
}

export function deleteCRM(crmId, teamId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CRM_DELETING });

    const api = APIFactory.getInstance(getState());
    return api
      .delete(`/admin/crm/${crmId}`)
      .then(() => {
        dispatch({ type: types.CRM_DELETED, payload: { teamId, crmId } });
      })
      .catch(() => {
        dispatch({ type: types.CRM_DELETE_ERROR, payload: { crmId } });
        dispatch(addErrorNotification('Error deleting CRM'));
      });
  };
}

export function fetchCustomerCRMs(customerId) {
  return (dispatch, getState) => {
    const locations = getSelectedCustomerLocations(getState());
    if (locations.size === 0) return Promise.resolve();

    dispatch({ type: types.CUSTOMER_CRMS_FETCHING, payload: { customerId } });

    const promises = [];
    locations.forEach(location => {
      promises.push(dispatch(fetchLocationCRMs(location.get('id'))));
    });

    return Promise.all(promises)
      .then(() => dispatch({ type: types.CUSTOMER_CRMS_FETCHED, payload: { customerId } }))
      .catch(() => {
        dispatch({ type: types.CUSTOMER_CRMS_FETCH_ERROR, payload: { customerId } });
        dispatch(addErrorNotification('Error fetching CRMs.'));
      });
  };
}

function buildCrmPayload({
  name,
  type,
  customerId,
  teamId,
  isDefault,
  email,
  useStandardADF,
  dealerId,
  messageAdfContent,
  visitAdfContent,
  callAdfContent,
  conversationAdfContent,
}) {
  return type === 'email'
    ? {
      name,
      type,
      isDefault,
      customerId: Number(customerId),
      teamId: Number(teamId),
      email,
      messageAdfContent: useStandardADF ? null : messageAdfContent,
      visitAdfContent: useStandardADF ? null : visitAdfContent,
      callAdfContent: useStandardADF ? null : callAdfContent,
      conversationAdfContent: useStandardADF ? null : conversationAdfContent,
    }
    : {
      name,
      type,
      isDefault,
      customerId: Number(customerId),
      teamId: Number(teamId),
      dealerId,
    };
}

export function createCRM(formData) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CRM_SAVING });

    const payload = buildCrmPayload(formData);
    const api = APIFactory.getInstance(getState());
    return api
      .post('/admin/crm', payload)
      .then(response => {
        const crmId = response.data.id;
        dispatch({ type: types.CRM_SAVED, payload: assign({}, payload, { id: crmId }) });
        setTimeout(() =>redirectToRoute('/admin/crms'), 3000);
        return undefined;
      })
      .catch(() => {
        dispatch({ type: types.CRM_SAVE_ERROR });
        return {[FORM_ERROR]: 'Error creating CRM.'};
      });
  };
}

export function updateCRM(formData) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CRM_SAVING });

    const payload = buildCrmPayload(formData);
    const api = APIFactory.getInstance(getState());
    return api
      .patch(`/admin/crm/${formData.crmId}`, payload)
      .then(() => {
        dispatch({
          type: types.CRM_SAVED,
          payload: assign({}, payload, { id: formData.crmId }),
        });
        return undefined;
      })
      .catch(() => {
        dispatch({ type: types.CRM_SAVE_ERROR });
        return {[FORM_ERROR]: 'Error updating CRM.'};
      });
  };
}

export function fetchCRM(crmId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CRM_FETCHING, payload: { crmId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/admin/crm/${crmId}`)
      .then(response => {
        dispatch({ type: types.CRM_FETCHED, payload: response.data });
      })
      .catch(() => {
        dispatch({ type: types.CRM_FETCH_ERROR, payload: { crmId } });
      });
  };
}

export function fetchCrmTemplates() {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    return api
      .get('/admin/crm/templates')
      .then(response => {
        dispatch({ type: types.CRM_TEMPLATES_FETCHED, payload: response.data });
      })
      .catch(() => {
        dispatch({ type: types.CRM_TEMPLATES_FETCH_ERROR });
      });
  };
}

export function auditCrm(crmId, withVehicle, isSms) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CRM_AUDIT_REQUESTED });
    const api = APIFactory.getInstance(getState());
    return api
      .post('/audit/crm', { crmId, withVehicle, isSms })
      .then(response => {
        dispatch({ type: types.CRM_AUDIT_COMPLETED });
        return response.data;
      })
      .catch(error => {
        dispatch({ type: types.CRM_AUDIT_FAILED });
        const errorMessage =
            error.response.data && error.response.data.errors
              ? error.response.data.errors.join('. ')
              : error.response.statusText;
        dispatch(
          addErrorNotification(
            `There was an error running this CRM audit. (${errorMessage})`
          )
        );
        return {};
      });
  };
}
