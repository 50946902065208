/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Map, List, Set } from "immutable";
import forOwn from "lodash.forown";
import has from "lodash.has";
import moment from "moment";
import { CRM } from "redux/constants/records";
import SupportedLanguages from "redux/constants/SupportedLanguages";
import {
  AVAILABLE,
  AWAY,
  DO_NOT_DISTURB,
  OFFLINE,
} from "redux/reducers/statuses";
import { history } from "./historyHack";

export const formatNumber = (val, digits = 0) => {
  if (val === undefined) val = 0;

  return val.toLocaleString(undefined, {
    style: "decimal",
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
};

export const formatCurrency = (val, digits = 2, locale = "en-US") => {
  if (val === undefined) val = 0;

  return val.toLocaleString(locale, {
    style: "currency",
    currencyDisplay: "symbol",
    currency: "USD",
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
};

export const formatPercent = (val, digits = 0) => {
  if (val === undefined) val = 0;

  return val.toLocaleString(undefined, {
    style: "percent",
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
};

export const formatVehiclePrice = (val, locale = "en-US") => {
  const price = parseFloat(val);

  if (!price) return "Contact us";

  return price.toLocaleString(locale, {
    style: "currency",
    currencyDisplay: "symbol",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
};

export const ucwords = (str) =>
  String(str).replace(/^([a-z])|\s+([a-z])/g, ($1) => $1.toUpperCase());

/**
 * A "Stop the madness" util for fetching the app env.
 */
export const getAppEnv = () => {
  return window.CONFIG?.config.releaseStage || process.env.APP_ENV;
};

export const getLanguageName = (key) =>
  Object.keys(SupportedLanguages).includes(key) ? SupportedLanguages[key] : key;

export const getRandomString = (length = 5) =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, length);

export const redirectToRoute = (path) => {
  if (history) {
    history.push(path);
  }
};

export const mapFeedField = (field) =>
  Map({
    id: field.id,
    key: field.key,
    name: field.name,
    ordering: field.ordering,
  });

export const mapFeedFields = (fields) => {
  const result = Map();
  return result.withMutations((internal) => {
    fields.forEach((fieldData) => {
      const field = mapFeedField(fieldData);
      internal.set(field.get("id"), field);
    });
  });
};

export const mapFeed = (feed) =>
  Map({
    id: feed.id,
    name: feed.name,
    url: feed.url,
    isDiFeed: !!feed.isDiFeed,
    lastFetched: moment(feed.lastFetched),
    lastFetchCount: feed.lastFetchCount,
    locationCountry: feed.locationCountry,
    nextScheduledFetch: moment(feed.nextScheduledFetch),
    refreshPeriod: feed.refreshPeriod || 6,
    refreshValue: feed.refreshValue || "Hours",
    referenceId: feed.referencePk,
    mappings: Map(),
  });

export const mapFeeds = (feeds) => {
  const result = Map();
  return result.withMutations((internal) => {
    feeds.forEach((feedData) => {
      const feed = mapFeed(feedData);
      internal.set(feed.get("id"), feed);
    });
  });
};

export const mapFeedMapping = (mapping) =>
  Map({
    id: mapping.id,
    key: mapping.key,
    name: mapping.name,
    property: mapping.property,
  });

export const mapFeedMappings = (mappings) => {
  const result = Map();
  return result
    .withMutations((internal) => {
      mappings.forEach((mappingData) => {
        const mapping = mapFeedMapping(mappingData);
        internal.set(mapping.get("id"), mapping);
      });
    })
    .sortBy((item) => item.get("property"));
};

export const mapBanner = (banner) =>
  Map({
    banner: banner.banner,
    id: banner.id,
    language: banner.language,
    linkUrl: banner.linkUrl,
  });

export const mapBanners = (banners) => {
  const result = Map();
  return result.withMutations((internal) => {
    banners.forEach((bannerData) => {
      const banner = mapBanner(bannerData);
      internal.set(banner.get("id"), banner);
    });
  });
};

export const mapHotkey = (hotkey) =>
  Map({
    id: hotkey.id,
    name: hotkey.name,
    text: hotkey.text,
    category: hotkey.category,
    hotkey: hotkey.hotkey,
    favorite: !!hotkey.favorite,
  });

export const mapHotkeys = (hotkeys) => {
  const result = Map();
  return result.withMutations((internal) => {
    hotkeys.forEach((hotkeyData) => {
      const hotkey = mapHotkey(hotkeyData);
      internal.set(hotkey.get("id"), hotkey);
    });
  });
};

export const mapStarter = (starter) =>
  Map({
    id: starter.id,
    language: starter.language,
    starter: starter.starter,
  });

export const mapStarters = (starters) => {
  const result = Map();
  return result.withMutations((internal) => {
    starters.forEach((starterData) => {
      const starter = mapStarter(starterData);
      internal.set(starter.get("id"), starter);
    });
  });
};

export const mapLink = (link) =>
  Map({
    icon: link.icon,
    id: link.id,
    language: link.language,
    text: link.text,
    url: link.url,
  });

export const mapLinks = (links) => {
  const result = Map();
  return result.withMutations((internal) => {
    links.forEach((linkData) => {
      const link = mapLink(linkData);
      internal.set(link.get("id"), link);
    });
  });
};

export const mapCRM = (crm) =>
  new CRM({
    id: crm.id,
    customerId: crm.customerId,
    name: crm.name,
    type: crm.type,
    email: crm.email,
    messageAdfContent: crm.messageAdfContent,
    visitAdfContent: crm.visitAdfContent,
    callAdfContent: crm.callAdfContent,
    conversationAdfContent: crm.conversationAdfContent,
    teamId: crm.teamId,
    isDefault: crm.isDefault,
    dealerId: crm.dealerId,
  });

export const mapAccolade = (accolade) =>
  Map({
    id: accolade.id,
    name: accolade.name,
    year: accolade.year,
    make: accolade.make,
    model: accolade.model,
    accolade: accolade.accolade,
    isPinned: accolade.isPinned,
  });

export const mapAccolades = (accolades) => {
  const result = Map();
  return result.withMutations((internal) => {
    accolades.forEach((accoladeData) => {
      const accolade = mapAccolade(accoladeData);
      internal.set(accolade.get("id"), accolade);
    });
  });
};

export const mapContent = (content) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, name, attachment, message, ...meta } = content;

  return Map({
    id,
    name,
    attachment,
    message,
    meta,
  });
};

export const mapContentList = (contentList) => {
  const result = Map();
  return result.withMutations((internal) => {
    contentList.forEach((contentData) => {
      const genericContent = mapContent(contentData);
      internal.set(genericContent.get("id"), genericContent);
    });
  });
};

export const mapBrochure = (brochure) =>
  Map({
    id: brochure.id,
    name: brochure.name,
    year: brochure.year,
    make: brochure.make,
    model: brochure.model,
    fileUrl: brochure.fileUrl,
    thumbUrl: brochure.thumbUrl,
    isPinned: brochure.isPinned,
  });

export const mapBrochures = (brochures) => {
  const result = Map();
  return result.withMutations((internal) => {
    brochures.forEach((brochureData) => {
      const brochure = mapBrochure(brochureData);
      internal.set(brochure.get("id"), brochure);
    });
  });
};

export const mapIncentive = (incentive) =>
  Map({
    id: incentive.id,
    name: incentive.name,
    year: incentive.year,
    make: incentive.make,
    model: incentive.model,
    title: incentive.title,
    incentive: incentive.incentive,
    isPinned: incentive.isPinned,
  });

export const mapIncentives = (incentives) => {
  const result = Map();
  return result.withMutations((internal) => {
    incentives.forEach((incentiveData) => {
      const incentive = mapIncentive(incentiveData);
      internal.set(incentive.get("id"), incentive);
    });
  });
};

export const mapVideo = (video) =>
  Map({
    id: video.id,
    name: video.name,
    year: video.year,
    make: video.make,
    model: video.model,
    fileUrl: video.fileUrl,
    thumbUrl: video.thumbUrl,
    isPinned: video.isPinned,
  });

export const mapVideos = (videos) => {
  const result = Map();
  return result.withMutations((internal) => {
    videos.forEach((videoData) => {
      const video = mapVideo(videoData);
      internal.set(video.get("id"), video);
    });
  });
};

/**
 * Create a function that returns a function that can be used to sort an iterable of Immutable Maps by a particular numeric key
 * @param {string} key (must be numeric value)
 * @param {boolean} ascending
 * @returns {function}
 */
export const makeSortByNumericKey =
  (key, ascending = true) =>
  /**
   * @param {Map} a
   * @param {Map} b
   * @returns {number}
   */
  (a, b) => {
    if (typeof a.get(key) !== "number" || typeof b.get(key) !== "number") {
      return 0;
    }

    const aVal = a.get(key, 0);
    const bVal = b.get(key, 0);

    return ascending ? aVal - bVal : bVal - aVal;
  };

/**
 * Create a function that returns a function that can be used to sort an iterable of Immutable Maps by a particular string key
 * @param {string} key (must be string value)
 * @param {boolean} ascending
 * @returns {function}
 */
export const makeSortByStringKey =
  (key, ascending = true) =>
  /**
   * @param {Map} a
   * @param {Map} b
   * @returns {number}
   */
  (a, b) => {
    if (
      typeof a.get(key, "") !== "string" ||
      typeof b.get(key, "") !== "string"
    ) {
      return 0;
    }

    const aVal = a.get(key, "").toLowerCase();
    const bVal = b.get(key, "").toLowerCase();

    if (aVal < bVal) {
      return ascending ? -1 : 1;
    } else if (aVal > bVal) {
      return ascending ? 1 : -1;
    }
    return 0;
  };

/**
 * Create a function that returns a function that can be used to sort an iterable of Immutable Maps by a particular boolean key
 * @param {string} key (must be boolean value)
 * @param {boolean} trueFirst
 * @return {function}
 */

export const makeSortByBooleanKey =
  (key, trueFirst = true) =>
  /**
   * @param {Map} a
   * @param {Map} b
   * @returns {number}
   */
  (a, b) => {
    if (
      typeof a.get(key, false) !== "boolean" ||
      typeof b.get(key, false) !== "boolean"
    ) {
      return 0;
    }

    const aVal = a.get(key, false);
    const bVal = b.get(key, false);

    if (aVal === bVal) {
      return 0;
    } else if (aVal === true) {
      return trueFirst ? -1 : 1;
    }
    return trueFirst ? 1 : -1;
  };

/**
 * Create a function that returns a function that can be used to sort an iterable of Immutable Maps by a particular momentjs key
 * @param key (must be momentjs object)
 * @param {boolean} ascending
 */
export const makeSortByMomentKey =
  (key, ascending = true) =>
  /**
   * @param {Map} a
   * @param {Map} b
   * @returns {number}
   */
  (a, b) => {
    const aVal = a.get(key);
    const bVal = b.get(key);

    if (!moment.isMoment(aVal) && !moment.isMoment(bVal)) {
      // neither is a moment object, so don't sort
      return 0;
    } else if (!moment.isMoment(aVal)) {
      // first value isn't a moment object, so move it down the list
      return 1;
    } else if (!moment.isMoment(bVal)) {
      // second value isn't a moment object, so move first value up the list
      return -1;
    }

    if (aVal.isBefore(bVal)) {
      return ascending ? -1 : 1;
    } else if (aVal.isAfter(bVal)) {
      return ascending ? 1 : -1;
    }
    return 0;
  };

/**
 * Create a function that returns a function that can be used to sort an iterable of Immutable Maps by a particular date string key
 * @param key (must be date string object)
 * @param {boolean} ascending
 */
export const makeSortByDateStringKey =
  (key, ascending = true) =>
  /**
   * @param {Map} a
   * @param {Map} b
   * @returns {number}
   */
  (a, b) => {
    const aVal = moment(a.get(key));
    const bVal = moment(b.get(key));

    if (aVal.isBefore(bVal)) {
      return ascending ? -1 : 1;
    } else if (aVal.isAfter(bVal)) {
      return ascending ? 1 : -1;
    }
    return 0;
  };

/**
 * Build error object for redux-form based on validation errors from API
 */
export const buildFormErrors = (defaultError, response) => {
  let errors = { _error: defaultError };

  if (response) {
    const { status, data } = response;

    if (status === 422) {
      if (has(data, "errors")) {
        errors = { _error: `${defaultError} ${data.errors.join(" ")}` };
      } else {
        forOwn(
          data,
          (fieldErrors, fieldName) =>
            (errors[fieldName] = fieldErrors.join(" "))
        );
      }
    }
  }

  return errors;
};

export const timezones = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Fort_Nelson",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port_of_Spain",
  "America/Port-au-Prince",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Colombo",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kathmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qyzylorda",
  "Asia/Riyadh",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ulaanbaatar",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faroe",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Wake",
  "Pacific/Wallis",
];

export const locales = [
  {
    code: "",
    label: "",
  },
  {
    code: "en-us",
    label: "English",
  },
  {
    code: "es-us",
    label: "Spanish",
  },
  {
    code: "fr-ca",
    label: "French",
  },
];

/**
 * get color in format R, G, B for use in rgb() and rgba() CSS
 *
 * uses a preselected list of 30 colors, and uses random colors for indexes > 29
 * @param index
 * @return {string}
 */
export const getColor = (index) => {
  const colors = {
    0: "25, 118, 210",
    1: "191, 191, 191",
    2: "222, 65, 30",
    3: "250, 179, 77",
    4: "16, 101, 144",
    5: "75, 68, 28",
    6: "55, 86, 121",
    7: "208, 74, 142",
    8: "138, 172, 218",
    9: "145, 155, 69",
    10: "166, 239, 160",
    11: "188, 48, 43",
    12: "160, 206, 181",
    13: "211, 248, 12",
    14: "42, 220, 82",
    15: "16, 143, 242",
    16: "11, 227, 193",
    17: "246, 27, 92",
    18: "3, 0, 176",
    19: "206, 170, 175",
    20: "203, 4, 176",
    21: "123, 182, 248",
    22: "127, 232, 164",
    23: "91, 117, 178",
    24: "87, 0, 43",
    25: "14, 96, 50",
    26: "10, 220, 13",
    27: "72, 194, 22",
    28: "118, 46, 205",
    29: "18, 82, 39",
    30: "210, 100, 57",
  };

  if (colors[index]) return colors[index];
  return `${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256
  )}, ${Math.floor(Math.random() * 256)}`;
};

export const states = [
  { name: "Alabama", code: "AL" },
  { name: "Alaska", code: "AK" },
  { name: "Arizona", code: "AZ" },
  { name: "Arkansas", code: "AR" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Hawaii", code: "HI" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Iowa", code: "IA" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Maine", code: "ME" },
  { name: "Maryland", code: "MD" },
  { name: "Massachusetts", code: "MA" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Mississippi", code: "MS" },
  { name: "Missouri", code: "MO" },
  { name: "Montana", code: "MT" },
  { name: "Nebraska", code: "NE" },
  { name: "Nevada", code: "NV" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "New York", code: "NY" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania", code: "PA" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "Utah", code: "UT" },
  { name: "Vermont", code: "VT" },
  { name: "Virginia", code: "VA" },
  { name: "Washington", code: "WA" },
  { name: "West Virginia", code: "WV" },
  { name: "Wisconsin", code: "WI" },
  { name: "Wyoming", code: "WY" },
];

export const guam = [{ name: "Guam", code: "GU" }];

export const provinces = [
  { name: "Alberta", code: "AB" },
  { name: "British Columbia", code: "BC" },
  { name: "Manitoba", code: "MB" },
  { name: "New Brunswick", code: "NB" },
  { name: "Newfoundland and Labrador", code: "NL" },
  { name: "Nova Scotia", code: "NS" },
  { name: "Northwest Territories", code: "NT" },
  { name: "Nunavut", code: "NU" },
  { name: "Ontario", code: "ON" },
  { name: "Prince Edward Island", code: "PE" },
  { name: "Quebec", code: "QC" },
  { name: "Saskatchewan", code: "SK" },
  { name: "Yukon", code: "YT" },
];

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia, Plurinational State of", code: "BO" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, the Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Côte d'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and McDonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People's Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People's Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, the Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine, State of", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Réunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Saint Barthélemy", code: "BL" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Martin (French part)", code: "MF" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Sint Maarten (Dutch part)", code: "SX" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela, Bolivarian Republic of", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const getStatesForCountry = (countryCode) => {
  if (countryCode === "CA") {
    return provinces;
  } else if (countryCode === "GU") {
    return guam;
  }

  return states;
};

export const mapPolicy = (policy) =>
  Map({
    id: policy.id,
    name: policy.name,
    managedChatEnabled: !!policy.managedChatEnabled,
    managedChatAlways: !!policy.managedChatAlways,
    managedChatMaxWaitSeconds: policy.managedChatMaxWaitSeconds,
    managedChatNonWorkDays: !!policy.managedChatNonWorkDays,
    managedChatOutsideHours: !!policy.managedChatOutsideHours,
  });

export const mapPolicies = (policies) => List(policies.map(mapPolicy));

export const mapDealerMessage = (message) =>
  Map({
    id: message.id,
    locationId: message.locationId,
    expiresAt: message.expiresAt,
    subject: message.subject,
    messageBody: message.messageBody,
    createdAt: moment(message.createdAt),
  });

export const mapDealerMessages = (messages) =>
  List(messages.map(mapDealerMessage));

export const zeroPad = (num, size = 2) => {
  const padded = `000000000${num}`;
  return padded.substr(padded.length - size);
};

export function mapDayNameToDayNumber(dayName) {
  switch (dayName.toLowerCase()) {
    case "sunday":
      return 1;
    case "monday":
      return 2;
    case "tuesday":
      return 3;
    case "wednesday":
      return 4;
    case "thursday":
      return 5;
    case "friday":
      return 6;
    case "saturday":
      return 7;
    default:
      throw new Error(`Unknown day ${dayName}`);
  }
}

export function mapDayNumberToDayName(dayNumber) {
  switch (dayNumber) {
    case 1:
      return "Sunday";
    case 2:
      return "Monday";
    case 3:
      return "Tuesday";
    case 4:
      return "Wednesday";
    case 5:
      return "Thursday";
    case 6:
      return "Friday";
    case 7:
      return "Saturday";
    default:
      throw new Error(`Unknown day number ${dayNumber}`);
  }
}

/**
 * get English ordinal number - https://en.wikipedia.org/wiki/Ordinal_indicator#English
 * @param {int} i
 * @returns {string}
 */
export const getOrdinal = (i) => {
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

/**
 * Generate a generic rate limiting function
 * @param fn
 * @param delay
 * @param [context]
 */
export function rateLimit(fn, delay, context) {
  let queue = [];
  let timer = null;

  function processQueue() {
    const item = queue.shift();
    if (item) {
      fn.apply(item.context, item.arguments);
    }
    if (queue.length === 0) {
      clearInterval(timer);
      timer = null;
    }
  }

  return function limited(...args) {
    if (args[0] === "CLEAR_QUEUE") {
      queue = [];
      return;
    }
    queue.push({
      context: context || this,
      arguments: [].slice.call(args),
    });

    if (!timer) {
      processQueue(); // start immediately on the first invocation
      timer = setInterval(processQueue, delay);
    }
  };
}

/**
 * utility function to use in filter/filterNot calls on Immutable Maps
 * @param keys
 * @returns {Function}
 */
export function keyIn(...keys) {
  const keySet = Set(keys);
  return (v, k) => keySet.has(k);
}

/**
 * We can't use statusId to sort because the order we want is different than the statusIds would sort by. This generates proper a sort order integer.
 * @param statusId
 * @returns {number}
 */
const getStatusSortOrder = (statusId) => {
  switch (statusId) {
    case AVAILABLE:
      return 1;
    case AWAY:
      return 2;
    case DO_NOT_DISTURB:
      return 3;
    case OFFLINE:
      return 4;
    default:
      return 5;
  }
};

export const sortUserByStatus = (userA, userB) => {
  const aStatus = getStatusSortOrder(userA.get("statusId", OFFLINE));
  const bStatus = getStatusSortOrder(userB.get("statusId", OFFLINE));

  if (aStatus < bStatus) {
    return -1;
  } else if (aStatus > bStatus) {
    return 1;
  }
  return 0;
};

export const getQueryParams = () => {
  const queryString = document.location.search.split("+").join(" ");
  const params = {};
  let tokens;
  const regex = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = regex.exec(queryString))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const labsEnabled = () => {
  const params = getQueryParams();
  return params.labs === "true";
};

export const buildErrorMessageFromResponse = (
  response,
  defaultMessage = "An error occurred."
) => {
  if (
    response.data &&
    response.data.errors &&
    response.data.errors.length > 0
  ) {
    return response.data.errors.join(" ");
  } else if (
    response.status &&
    response.data &&
    response.status === 422 &&
    Object.keys(response.data).length > 0
  ) {
    return Object.keys(response.data)
      .map((key) => response.data[key].join(" "))
      .join(" ");
  }

  return defaultMessage;
};

/**
 * Higher-order function that returns a function which returns a boolean value for the requested setting. If
 * the value is "0" or 0, false is returned. If the value is undefined, the default value is returned.  Otherwise,
 * true is returned.
 *
 * @param {Object} settings
 */
export function makeGetBooleanSetting(settings) {
  /**
   * @param {string} key
   * @param {bool} [defaultVal=false]
   */
  return function getVal(key, defaultVal = false) {
    if (!settings || settings[key] === undefined) return defaultVal;
    if (settings[key] === "0" || settings[key] === 0 || settings[key] === false)
      return false;
    return true;
  };
}

/**
 * Handles casting values that may be considered null to null
 */
export function castToNull(value) {
  if (value === null) {
    return null;
  }

  if (typeof value === "string") {
    return value.trim().length === 0 ? null : value;
  }

  return value;
}

/**
 * Capitalize a string
 */
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Turns a promise into a cancelable promise!
 */

export const makeCancelable = (promise) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};
