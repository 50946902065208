export const messageSendErrors = {
  NO_MATCHING_CONVO: 'NO_MATCHING_CONVO',
  ALREADY_ENDED: 'ALREADY_ENDED',
  SMS_NOT_CONFIRMED: 'SMS_NOT_CONFIRMED',
  SMS_REVOKED: 'SMS_REVOKED',
  AUTH_ERROR: 'AUTH_ERROR',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  BAD_REQUEST: 'BAD_REQUEST',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  TIMEOUT: 'TIMEOUT',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',

  /* OEM SMS LIMITATIONS */
  UNAVAILABLE_FOR_LEGAL_REASONS: 'UNAVAILABLE_FOR_LEGAL_REASONS',
  NO_VISITOR_MESSAGE_PAST_72_HOURS: '1001',
  AGENT_ATTEMPT_SEND_MORE_THAN_2_MESSAGES: '1002',
};

export enum MessageResponseCodes {
  BadRequest = 400,
  AuthError = 401,
  NoMatchingConvo = 404,
  SmsError = 400,
  AlreadyEnded = 410,
  ValidationError = 422,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500,
}

export default {};
