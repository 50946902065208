import { Map } from 'immutable';
import { get } from 'lodash';
import Constants from '../../constants';

const initialState: any = Map();

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case Constants.CONVERSATION_AGENT_TRANSCRIPT_CANCELLED:
      return state.setIn([payload.channelName, 'loading'], false);

    case Constants.CONVERSATION_AGENT_TRANSCRIPT_EXCEPTION:
      return state
        .setIn([payload.channelName, 'errors'], payload.errors)
        .setIn([payload.channelName, 'loading'], false);

    case Constants.CONVERSATION_AGENT_TRANSCRIPT_REQUESTED:
      return state
        .setIn(
          [payload.channelName, 'data'],
          state.getIn([payload.channelName, 'data']) || []
        )
        .setIn([payload.channelName, 'errors'], [])
        .setIn([payload.channelName, 'loading'], true);

    case Constants.CONVERSATION_AGENT_TRANSCRIPT_RESPONDED:
      return state
        .setIn([payload.channelName, 'data'], get(payload, 'response.data.transcript', []))
        .setIn([payload.channelName, 'errors'], [])
        .setIn([payload.channelName, 'loading'], false);

    default:
      return state;
  }
};
