/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { combineReducers } from 'redux';
import channels from './channels';
import config from './config';
import conversations from './conversations';
import customers from './customers';
import locations from './locations';
import notification from './notification';
import offers from './offers';
import pusher from './pusher';
import regions from './regions';
import reports from './reports';
import search from './search';
import sendForm from './sendForm';
import statuses from './statuses';
import teams from './teams';
import ui from './ui';
import users from './users';
import utility from './utility';
import vehicleOfInterest from './vehicleOfInterest';
import visitors from './visitors';
import { reducer as conversationAgentTakeover } from '../Conversation/AgentTakeover';
import { reducer as conversationAgentTranscriptHistory } from '../Conversation/AgentTranscriptHistory';
import { reducer as customerActiveCallCenterConversations } from '../Customer/ActiveCallCenterConversations';
import { reducer as features } from '../Feature';
import { reducer as reportCallCenter } from '../Report/CallCenter';
import { reducer as videoChats } from '../VideoChats';
import { reducer as locationSettings } from '../Location/Settings';
import { reducer as oems } from '../Oem';
import { reducer as modals } from '../Modals';
import gptReviews from './gptReviews';

export const selectConversationAgentTakeover = (state, _props) =>
  state.conversationAgentTakeover;

export const selectConversationAgentTranscriptHistory = (state, _props) =>
  state.conversationAgentTranscriptHistory;

export const selectCustomerActiveCallCenterConversations = (state, _props) =>
  state.customerActiveCallCenterConversations;

export const selectCustomers = (state, _props) =>
  state.customers;

export const selectLocations = (state, _props) =>
  state.locations;

export const selectVideoChats = (state, _props) =>
  state.videoChats;

export const selectLocationSettings = (state, _props) =>
  state.locationSettings;

export const selectOems = (state, _props) =>
  state.oems;

export const selectGptReviews = (state, _props) =>
  state.gptReviews;

export default combineReducers({
  // legacy
  channels,
  config,
  conversations,
  customers,
  locations,
  notification,
  offers,
  pusher,
  regions,
  reports,
  search,
  sendForm,
  statuses,
  teams,
  ui,
  users,
  utility,
  visitors,

  // modern
  conversationAgentTakeover,
  conversationAgentTranscriptHistory,
  customerActiveCallCenterConversations,
  features,
  reportCallCenter,
  videoChats,
  locationSettings,
  oems,
  gptReviews,
  vehicleOfInterest,
  modals,
});
