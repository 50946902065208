import assign from 'lodash.assign';
import * as types from '../constants/actionTypes';
import { getRandomString } from '../../businessLogic/util';

/**
 * @param {string} channel
 * @param {object} attachment
 * @param {string} attachment.title
 * @param {string} attachment.iconClass
 * @param {Map} attachment.item
 * @returns object
 */
export function addAttachment(channel, attachment) {
  return {
    type: types.SEND_FORM_ADD_ATTACHMENT,
    payload: {
      channel,
      attachment: assign({ id: getRandomString() }, attachment),
    },
  };
}

/**
 * @param {string} channel
 * @param {string} id
 * @returns object
 */
export function removeAttachment(channel, id) {
  return { type: types.SEND_FORM_REMOVE_ATTACHMENT, payload: { channel, id } };
}

/**
 * @param {string} channel
 * @param {string} text
 * @returns object
 */
export function updateText(channel, text) {
  return { type: types.SEND_FORM_UPDATE_TEXT, payload: { channel, text } };
}

/**
 * @param {string} channel
 * @param {string} garageLink
 * @param {string} uuid
 * @returns object
 */
export function addGarageLink(channel, garageLink, uuid) {
  return {
    type: types.SEND_FORM_ADD_GARAGE_LINK,
    payload: { channel, garageLink, uuid }
  };
}

/**
 * @param {string} channel
 * @returns object
 */
export function removeGarageLink(channel) {
  return {
    type: types.SEND_FORM_REMOVE_GARAGE_LINK,
    payload: { channel, garageLink: '', uuid: '' }
  };
}
