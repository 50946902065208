import Immutable from 'immutable';

export const AWAY = 1;
export const DO_NOT_DISTURB = 2;
export const OFFLINE = 3;
export const AVAILABLE = 4;

// key = statusId
const initialState = Immutable.fromJS({
  [AWAY]: { name: 'Away' },
  [DO_NOT_DISTURB]: { name: 'Do Not Disturb' },
  [OFFLINE]: { name: 'Offline' },
  [AVAILABLE]: { name: 'Available' },
});

export default function statuses(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
