/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Map, Set } from 'immutable';
import assign from 'lodash.assign';
import { addUser, addUsers } from '../../businessLogic/userHelper';
import * as types from '../constants/actionTypes';
import { AVAILABLE } from './statuses';

const initialState = Map({
  loggedInUser: Map({
    id: null,
    token: null,
    tokenIsValid: true,
    locationIds: Set(),
    hotkeys: Map(),
    hotkeysFetched: false,
    isStartingAgentChat: false,
    statusId: AVAILABLE,
  }),
  overviewIsFetching: false,
  overviewFetched: false,
  users: Map(),
  userAccounts: Set(),
  customerUserStatus: Map(),
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.LOGIN_SUCCESS:
      return loginSuccess(state, action.payload);
    case types.SWITCH_SUCCESS:
      return switchSuccess(state, action.payload);
    case types.ADD_USER_FROM_PAGE:
      return addUserFromPage(state, action.payload);
    case types.OFFERS_FETCHED:
      return offersFetched(state, action.payload);
    case types.CURRENT_CONVERSATIONS_FETCHED:
      return addUsersFromCurrentConversations(state, action.payload);
    case types.CONVERSATION_FETCHED:
      return conversationFetched(state, action.payload);
    case types.USER_OVERVIEW_FETCHING:
      return state.set('overviewIsFetching', true);
    case types.USER_OVERVIEW_FETCHED:
      return userOverviewFetched(state, action.payload);
    case types.USER_OVERVIEW_FETCH_ERROR:
      return userOverviewFetchError(state, action.payload);
    case types.LOCATION_FETCHED:
      return locationFetched(state, action.payload);
    case types.LOCATION_USER_STATUS_FETCHED:
      return locationUserStatusFetched(state, action.payload);
    case types.CUSTOMER_USER_STATUS_FETCHED:
      return customerUserStatusFetched(state, action.payload);
    case types.USER_HOTKEYS_FETCHED:
      return state.setIn(['loggedInUser', 'hotkeysFetched'], true);
    case types.SET_ACTIVE_HOTKEY:
      return state.setIn(
        ['loggedInUser', 'activeHotkey'],
        Map({ hotkey: action.payload.hotkey, timestamp: action.payload.timestamp })
      );
    case types.AGENT_ACCEPTED:
      return agentAccepted(state, action.payload);
    case types.AGENT_CHAT_STARTING:
      return state.setIn(['loggedInUser', 'isStartingAgentChat'], true);
    case types.AGENT_CHAT_STARTED:
    case types.AGENT_CHAT_START_ERROR:
      return state.setIn(['loggedInUser', 'isStartingAgentChat'], false);
    case types.PUSHER_TRANSFER_WAITING:
      return pusherTransferWaiting(state, action.payload);
    case types.VALID_TOKEN:
      return state.setIn(['loggedInUser', 'tokenIsValid'], true);
    case types.INVALID_TOKEN:
      return state.setIn(['loggedInUser', 'tokenIsValid'], false);
    case types.SET_USER_STATUS:
      return state.setIn(['loggedInUser', 'statusId'], action.payload.statusId);
    case types.USER_PROFILE_FETCHING:
      return state.set('profileIsFetching', true);
    case types.USER_PROFILE_FETCHED:
      return userProfileFetched(state, action.payload);
    case types.USER_PROFILE_FETCH_ERROR:
      return state.set('profileIsFetching', false);
    case types.LOCATION_TEAMS_FETCHED:
      return locationTeamsFetched(state, action.payload);
    case types.CUSTOMER_USERS_FETCHED:
      return customerUsersFetched(state, action.payload);
    case types.AGENT_SCORES_FETCHED:
      return agentScoresFetched(state, action.payload);
    case types.USER_DELETED_SUPERADMIN:
      return state.mergeIn(['users', action.payload.userId], Map({ deletedAt: Date() }));
    case types.CUSTOMER_USER_MIGRATED:
      return state.mergeIn(
        ['users', action.payload.userId],
        Map({ customerId: action.payload.customerId })
      );
      // CRUD
    case types.USER_AVATAR_DELETED:
      return state.mergeIn(
        ['users', action.payload.userId],
        Map({ avatar: undefined, avatarType: undefined })
      );
    case types.USER_PROFILE_INFO_UPDATED:
      return userProfileUpdated(state, action.payload);
    case types.USER_INFO_UPDATED:
      return userUpdated(state, action.payload);
    case types.USER_AVATAR_SAVED:
      return state.mergeIn(
        ['users', action.payload.userId],
        Map({ avatar: action.payload.avatar, avatarType: action.payload.avatarType })
      );
    case types.USER_DELETED:
      return state.deleteIn(['users', action.payload.userId]);
    case types.ALTERNATE_ACCOUNTS_FETCHED:
      return userAccountsFetched(state, action.payload);
    default:
      return state;
  }
}

function loginSuccess(state, { id, token }) {
  return state.setIn(['loggedInUser'], Map({ id, token }));
}

function switchSuccess(state, { id, token }) {
  return state.mergeDeep({loggedInUser: {id, token, tokenIsValid: true}});
}

/**
 * Add a user from initial page data
 * @param state
 * @param payload
 * @returns {*}
 */
function addUserFromPage(state, payload) {
  return state.mergeIn(
    ['loggedInUser'],
    Map({
      id: payload.profile.id,
      token: payload.token,
    })
  );
}

function offersFetched(state, { offers }) {
  offers.forEach(offerData => {
    if (offerData.fromUser) {
      state = addUser(state, offerData.fromUser);
    }
  });
  return state;
}

function addUsersFromCurrentConversations(state, payload) {
  const users = [];
  const conversations = payload.conversations.concat(payload.internalConversations);
  conversations.forEach(conversation => {
    users.push(...conversation.involvedAgents);
  });

  return addUsers(state, users);
}

function conversationFetched(state, { data: { involvedAgents } }) {
  if (!involvedAgents) return state;

  const users = [];
  users.push(...involvedAgents);
  return addUsers(state, users);
}

function userOverviewFetched(state, payload) {
  payload.users.forEach(user => {
    state = addUser(state, user);
  });

  state = state.setIn(['loggedInUser', 'locationIds'], Set(payload.myLocationIds));

  state = state.set('overviewIsFetching', false);
  return state.set('overviewFetched', true);
}

function userOverviewFetchError(state) {
  return state
    .set('overviewError', true)
    .set('overviewIsFetching', false);
}

function userAccountsFetched(state, payload) {
  return state.set(['userAccounts'], Set(payload.map((obj) => Map(obj))));
}

function locationFetched(state, { response: { teams } }) {
  teams.forEach(team => {
    state = addUsers(state, team.members);
  });
  return state;
}

function locationUserStatusFetched(state, { response: { location: { agents } } }) {
  agents.forEach(agent => {
    state = state.setIn(['users', agent.id, 'statusId'], agent.status.id);
  });
  return state;
}

function customerUserStatusFetched(state, { customerId, response: { customer: { agents } } }) {
  agents.forEach(agent => {
    state = state.setIn(['users', agent.id, 'statusId'], agent.status.id);
  });

  state = state.setIn(['customerUserStatus', customerId], Set(agents.map((obj) => Map({ ...obj, statusId: obj.status.id }))));
  return state;
}

function agentAccepted(state, { user }) {
  if (!state.hasIn(['users', user.userId])) {
    state = addUser(state, assign(user, { id: user.userId }));
  }
  return state;
}

function pusherTransferWaiting(state, payload) {
  if (payload.fromUser) {
    state = addUser(state, payload.fromUser);
  }

  return state;
}

function userProfileFetched(state, { response: { data } }) {
  return state.withMutations(state => {
    addUser(state, data);
    state.set('profileIsFetching', false);
    state.set('profileFetched', true);
  });
}

function locationTeamsFetched(state, { teams }) {
  teams.forEach(team => {
    state = addUsers(state, team.members);
  });
  return state;
}

function customerUsersFetched(state, { users }) {
  return addUsers(state, users);
}

function userProfileUpdated(state, { id, firstName, lastName, timezone, email, phoneNumber }) {
  return state.mergeIn(
    ['users', id],
    Map({ id, firstName, lastName, timezone, email, phoneNumber })
  );
}

function userUpdated(
  state,
  {
    id,
    firstName,
    lastName,
    timezone,
    email,
    phoneNumber,
    isAdmin,
    isSuperAdmin,
    customerId,
    username,
    dmsId,
    locale,
    locations,
  }
) {
  return state.mergeIn(
    ['users', id],
    Map({
      id,
      firstName,
      lastName,
      timezone,
      email,
      phoneNumber,
      isAdmin,
      isSuperAdmin,
      customerId,
      username,
      dmsId,
      locale,
      locations,
    })
  );
}

function agentScoresFetched(state, { userId, scores }) {
  return state.setIn(
    ['users', userId, 'scores'],
    Map({ monthlyAverage: scores.monthly, allTimeAverage: scores.alltime })
  );
}
