import 'styles/login.scss';
import conversationsLogo from 'images/logo.svg';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LoggedOutAlert from './LoggedOutAlert';

export interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configData: any;
}

export interface State {
  isLoggedOut: boolean;
}

function redirectToAuth0Login(url: string): void {
  if (typeof window !== 'undefined') {
    window.location.href = url;
  }
}

export class Auth0Redirect extends React.Component<Props, {}> {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoggedOut: false
    };
  }

  componentDidMount(): void {
    redirectToAuth0Login(this.props.configData.auth0.loginUrl);
  }

  render(): JSX.Element {
    if (this.props.configData.auth0.action) {
      this.state.isLoggedOut = this.props.configData.auth0.action == 'return-logout' ? true : false;
    }

    return (
      <div className="auth0-login-container">
        <img className="logo" src={conversationsLogo} />
        <div className="auth0-login">
          <LoggedOutAlert show={this.state.isLoggedOut}></LoggedOutAlert>
          <p>If you are not redirected within 5 seconds, <br />please click the button below.</p>
          <form className="button_to" method="get" action={this.props.configData.auth0.loginUrl}>
            <input id="auth0_login" className="btn btn-primary" type="submit" value="Log In" />
          </form>
          <script>
            // eslint-disable-next-line react/no-unescaped-entities
            document.getElementById('auth0_login').form.submit();
          </script>
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect(undefined)(withRouter(Auth0Redirect as any) as any);
