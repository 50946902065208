import React from 'react';

export const ConversationsLoader = () => (
  <div className="conversations-loader">
    <svg viewBox="0 0 28 28">
      <title>Conversations</title>
      <g>
        <path
          className="logo-stroke"
          d="m23.189107,20.84103c-1.888287,1.813923 -4.43831,2.959603 -7.239655,2.959603c-1.617248,0 -3.141431,-0.374666 -4.494492,-1.040742c-0.683187,                    -0.336504 -1.32176,-0.748028 -1.906732,-1.223298c-0.075051,0.045533 -0.146241,0.097566 -0.210147,0.159146c-0.476893,0.47224 -1.024128,                    0.845602 -1.622389,1.126164c-0.782248,0.367734 -1.65413,0.572844 -2.592917,0.59236l-0.431432,0.008667l0.244023,-0.359483c0.446447,                    -0.658705 0.797687,-1.38158 1.065296,-2.136116c0.383836,-1.082374 0.575962,-2.234122 0.565672,-3.38587c-0.274901,-0.662607 -0.480755,                    -1.362064 -0.612844,-2.087985c-0.114084,-0.625741 -0.174121,-1.271429 -0.170691,-1.932733c-0.031733,-5.697179 4.500929,-10.311984 10.166654,                    -10.311984c2.738726,0 5.256592,1.113587 7.239655,3.055435c0.409141,0.41369 1.070445,0.41369 1.510895,0c0.409133,-0.41369 0.377824                    ,-1.114459 -0.031309,-1.528149c-2.266551,-2.29136 -5.350936,-3.723678 -8.75055,-3.691585c-6.798781,-0.032093 -12.338853,5.569678 -12.338853,                    12.476283c0,1.098844 0.143243,2.164299 0.408709,3.179889c0.001719,0.01041 0.000432,0.020812 0.002143,0.03079c0.457169,2.270109 -0.416857,4.579242 -2.39649,                    6.334624c-0.331939,0.298343 -0.520211,0.67388 -0.530933,1.078032c-0.000432,0.025585 0,0.050738 0.001711,0.081088c0.002151,0.074588 0.008579,                    0.150039 0.028735,0.243711c0.039888,0.282728 0.187849,0.663902 0.656594,0.876816c0.954649,0.39722 1.989499,0.598429 3.077102,0.598429l0.000424,                    0c1.78622,0 3.584025,-0.555926 5.103491,-1.532051c1.775066,0.996936 3.816463,1.563272 5.987366,1.552862c3.367873,0.032093 6.421389,                    -1.304826 8.75055,-3.596617c0.409133,-0.41369 0.440443,-1.081934 0.031309,-1.495624c-0.409141,-0.413698 -1.101754,-0.413698 -1.510895,-0.031661"
        />
      </g>
    </svg>
  </div>
);

export default ConversationsLoader;
