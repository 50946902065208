import { Map, fromJS, Set } from "immutable";
import * as types from "redux/constants/actionTypes";
import {
  addTeam,
  addUserToTeam,
  deleteUserFromTeam,
} from "businessLogic/teamHelper";
import { mapCRM } from "businessLogic/util";

const initialState = Map();

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.USER_OVERVIEW_FETCHED:
      return userOverviewFetched(state, payload);
    case types.LOCATION_FETCHED:
      return locationFetched(state, payload);
    case types.LOCATION_TEAMS_FETCHED:
      return locationTeamsFetched(state, payload);
    case types.TEAM_USER_STATUS_FETCHED:
      return teamStatusFetched(state, payload);
    case types.TEAM_DASHBOARD_FETCHING:
      return state.setIn([payload.teamId, "dashboardFetching"], true);
    case types.TEAM_DASHBOARD_FETCHED:
    case types.TEAM_DASHBOARD_FETCH_ERROR:
      return state.setIn([payload.teamId, "dashboardFetching"], false);
    case types.OFFERS_FETCHED:
      return offersFetched(state, payload);
    case types.PUSHER_VISITOR_WAITING:
      return pusherVisitorWaiting(state, payload);
    case types.CONVERSATION_FETCHED:
      return conversationFetched(state, payload);

    // team/user CRUD
    case types.TEAM_DELETED:
      return state.delete(payload.teamId);
    case types.USER_ADDED_TO_TEAM:
      return userAddedToTeam(state, payload);
    case types.USER_DELETED_FROM_TEAM:
      return userDeletedFromTeam(state, payload);
    case types.TEAM_SAVED:
      return addTeam(state, payload);
    case types.TEAM_POLICY_SAVED:
      state = state.setIn(
        [payload.teamId, "managedChatPolicyId"],
        payload.managedChatPolicyId
      );
      return state.setIn(
        [payload.teamId, "callCenterTeamId"],
        payload.callCenterTeamId
      );
    case types.USER_DELETED:
      return userDeleted(state, payload);

    // working hours
    case types.TEAM_HOUR_DELETED:
      return teamHourDeleted(state, payload);
    case types.TEAM_HOUR_ADDED:
      return teamHourAdded(state, payload);

    // closed dates
    case types.TEAM_CLOSED_DATE_DELETED:
      return teamClosedDateDeleted(state, payload);
    case types.TEAM_CLOSED_DATES_ADDED:
      return teamClosedDatesAdded(state, payload);

    // CRMs
    case types.LOCATION_CRMS_FETCHED:
      return locationCRMsFetched(state, payload);
    case types.CRM_FETCHED:
      return crmFetched(state, payload);
    case types.CRM_DELETED:
      return state.deleteIn([payload.teamId, "crms", payload.crmId]);
    case types.CRM_SAVED:
      return state.mergeIn(
        [payload.teamId, "crms", payload.id],
        mapCRM(payload)
      );

    default:
      return state;
  }
}

function userOverviewFetched(state, payload) {
  payload.teams.forEach((team) => {
    state = addTeam(state, team);
  });

  return state;
}

function conversationFetched(state, payload) {
  if (!payload.data.team) return state;
  state = addTeam(state, payload.data.team);
  payload.data.team.members.forEach(
    (user) => (state = addUserToTeam(state, payload.data.team.id, user.id))
  );
  return state;
}

function locationFetched(state, { response: { teams } }) {
  teams.forEach((team) => {
    state = addTeam(state, team);
    team.members.forEach(
      (user) => (state = addUserToTeam(state, team.id, user.id))
    );
  });
  return state;
}

function locationTeamsFetched(state, { teams }) {
  teams.forEach((team) => {
    state = addTeam(state, team);
    team.members.forEach(
      (user) => (state = addUserToTeam(state, team.id, user.id))
    );
  });
  return state;
}

function offersFetched(state, { offers }) {
  offers.forEach((offerData) => {
    if (offerData.team) {
      state = addTeam(state, offerData.team);
    }
  });
  return state;
}

function pusherVisitorWaiting(state, payload) {
  const team = payload.team;
  if (!team) return state;
  return addTeam(state, team);
}

function teamStatusFetched(
  state,
  {
    teamId,
    response: {
      team: { statusId },
    },
  }
) {
  return state.setIn([teamId, "statusId"], statusId);
}

function userAddedToTeam(state, { teamId, userId }) {
  return addUserToTeam(state, teamId, userId);
}

function userDeletedFromTeam(state, { teamId, userId }) {
  return deleteUserFromTeam(state, teamId, userId);
}

function teamHourDeleted(state, { teamId, hourId }) {
  if (!state.has(teamId)) return state;

  const filteredHours = state
    .getIn([teamId, "workingHours"])
    .filter((workingHour) => workingHour.get("id") !== hourId);
  return state.setIn([teamId, "workingHours"], filteredHours);
}

function teamHourAdded(state, { teamId, hourData }) {
  if (!state.has(teamId)) return state;
  return state.setIn(
    [teamId, "workingHours"],
    state.getIn([teamId, "workingHours"]).push(fromJS(hourData))
  );
}

function teamClosedDateDeleted(state, { teamId, date }) {
  if (!state.has(teamId)) return state;

  return state.setIn(
    [teamId, "closedDates"],
    state
      .getIn([teamId, "closedDates"])
      .filterNot((internal) => internal.isSame(date, "day"))
  );
}

function teamClosedDatesAdded(state, { teamId, dates }) {
  if (!state.has(teamId)) return state;
  return state.setIn(
    [teamId, "closedDates"],
    state.getIn([teamId, "closedDates"]).concat(dates)
  );
}

function userDeleted(state, { userId }) {
  return state.map((entry) => {
    if (!Map.isMap(entry)) return entry;
    return entry.set("userIds", entry.get("userIds", Set()).delete(userId));
  });
}

function locationCRMsFetched(state, { crms }) {
  crms.forEach((crm) => {
    state = state.setIn([crm.teamId, "crms", crm.id], mapCRM(crm));
  });

  return state;
}

function crmFetched(state, data) {
  return state.setIn([data.teamId, "crms", data.id], mapCRM(data));
}
