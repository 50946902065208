import { Map } from 'immutable';
import * as APIFactory from 'services/APIFactory';
import avatarUser from 'images/avatar-user.svg';
import avatarBot from 'images/avatar-bot.png';

export const DEFAULT_AVATAR = avatarUser;
export const DEFAULT_BOT_AVATAR = avatarBot;
export const DEFAULT_THEME_ID = 1;
export const THEME_DEALER = 1;
export const THEME_CALL_CENTER = 2;

export function addUser(state, payload) {
  const data = {
    id: payload.id,
    firstName: payload.firstName,
    lastName: payload.lastName,
    avatar: payload.avatar,
    avatarType: payload.avatarType,
    isAdmin: payload.isAdmin,
    isSuperAdmin: payload.isSuperAdmin,
    timezone: payload.timezone,
    themeId: payload.themeId || DEFAULT_THEME_ID,
    username: payload.username,
    email: payload.email,
    phoneNumber: payload.phoneNumber,
    dmsId: payload.dmsId,
    deletedAt: payload.deletedAt,
    locale: payload.locale,
    locations: payload.locations,
  };

  // if status is not passed in as part of the payload, then don't update it at all (default to what's already in the store)
  if (payload.statusId) {
    data.statusId = payload.statusId;
  }

  return state.mergeIn(['users', payload.id], Map(data));
}

export function addUsers(state, users) {
  users.forEach((userData) => {
    state = addUser(state, userData);
  });

  return state;
}

/**
 * @param {Map} user
 * @returns {string}
 */
export function getFullName(user) {
  if (!user) return 'Unknown Agent';
  return `${user.get('firstName', '')} ${user.get('lastName', '')}`;
}

export function getUserEmail(user) {
  if (!user) return 'Unknown Email';
  return `${user.get('email', '')}`;
}

export function getAvatar(user) {
  if (!user) return DEFAULT_AVATAR;
  if (!user.get('avatar') || user.get('avatarType') !== 'user') return null; // we'll use their initials if it's a location or customer avatar
  return user.get('avatar');
}

export function getInitials(user) {
  const letters = [];
  if (!user) return 'UA'; // unknown agent
  if (user.get('firstName')) letters.push(user.get('firstName')[0]);
  if (user.get('lastName')) letters.push(user.get('lastName')[0]);

  const initials = letters.join('');

  return initials || 'UA';
}

export function getUser(state, userId) {
  return state.users.getIn(['users', userId]);
}

export const validateUsername =
  (state) =>
  ({ username, userId }) => {
    const api = APIFactory.getInstance(state);

    let url;
    if (userId) url = `/user/username/check/${username}/${userId}`;
    else url = `/user/username/check/${username}`;

    return api.get(url).then((response) => {
      if (response.data.available !== true) {
        return Promise.reject({
          username: `The username ${username} is taken`,
        });
      }
      return Promise.resolve();
    });
  };
