import ConversationsLoader from 'components/Shared/ConversationsLoader/ConversationsLoader';
import React from 'react';
import { LoadingComponentProps } from 'react-loadable';

const FullPageLoading: React.SFC<LoadingComponentProps> = props => {
  if (props.error) {
    return (
      <div className="full-page-loading centered-in-viewport">
        <div>
          <i className="ion-ios-cloud-download" />
          <p>There was a network error while downloading Conversations.</p>
          <p>Please refresh your browser.</p>
        </div>
      </div>
    );
  }
  return (
    <div className="full-page-loading centered-in-viewport">
      <ConversationsLoader />
      <p
        style={{
          marginTop: '1em',
          visibility: props.pastDelay ? 'visible' : 'hidden',
        }}
      >
        The bits are flowing slowly today
      </p>
    </div>
  );
};

export default FullPageLoading;
