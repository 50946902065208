/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkAction } from 'redux-thunk';
import Constants from '../../constants';
import { getSelectedCustomerId } from 'selectors/customerSelectors';

export interface FetchReportCallCenterResponse {
  agentData: {
    id: number;
    activeChats: number;
    averageResponseTime: number;
    engageTime: number;
    firstChatRes: number;
    firstResponseTime: number;
    firstName: string;
    lastName: string;
    leadPercent: number;
    percentAnswered: number | null;
    totalChats: number;
    totalLeads: number;
  }[];
  chatVolume: {
    date: string;
    missedChats: number;
    totalChats: number;
  }[];
  summaryData: {
    engageTime: number;
    firstChatRes: number;
    firstResponseTime: number;
    averageResponseTime: number;
    leadPercent: number;
    percentAnswered: number | null;
    totalChats: number;
    totalLeads: number;
    pendingOffers: number;
  };
}

export function fetchReportCallCenter(request: {
  dateStart: string;
  dateEnd: string;
  interactive: 'true' | null;
  gm: 'true' | null;
}): ThunkAction<any, any, any, any> {
  return (dispatch: any, getState: any, { APIFactory }: any): any => {
    const startDate = encodeURIComponent(request.dateStart);
    const endDate = encodeURIComponent(request.dateEnd);
    const interactive = encodeURIComponent(request.interactive === 'true');
    const gm = encodeURIComponent(request.gm === 'true');
    const customerId = getSelectedCustomerId(getState());

    dispatch({
      type: Constants.REPORT_CALL_CENTER_REQUESTED,
      payload: { request },
    });
    return APIFactory.getInstance(getState())
      .get(`/report/callcenter?startDate=${startDate}&endDate=${endDate}&interactive=${interactive}&gm=${gm}&customerId=${customerId}`)
      .then((response: any) => {
        dispatch({
          type: Constants.REPORT_CALL_CENTER_RESPONDED,
          payload: { request, response },
        });
      })
      .catch((errors: any) => {
        dispatch({
          type: Constants.REPORT_CALL_CENTER_EXCEPTION,
          payload: { request, errors: Array.isArray(errors) ? errors : [errors] },
        });
      });
  };
}
