import { createSelector } from 'reselect';
import { Map, Set, OrderedSet } from 'immutable';
import { makeSortByBooleanKey, makeSortByStringKey, makeSortByMomentKey } from 'businessLogic/util';
import { THEME_CALL_CENTER } from 'businessLogic/userHelper';
import { LOCATION_OWNER, LOCATION_TYPE } from 'redux/reducers/locations';
import { getActiveConversation } from './conversationSelectors';
import { getAllTeams } from './teamSelectors';
import { getAllUsers, getLoggedInUserData, getLoggedInUserId, getTheme } from './userSelectors';
import { getSelectedCustomer } from './customerSelectors';

const allLocations = state => (state.locations ? state.locations.get('locations') : Map());

export const makeDisplayName = location => {
  // cars.com locations have specific types
  if (location.get('locationOwnerId') === LOCATION_OWNER.CARS) {
    let type = '';
    switch (location.get('locationTypeId')) {
      case LOCATION_TYPE.FACEBOOK_MARKETPLACE:
        type = 'Cars.com FBM';
        break;
      case LOCATION_TYPE.STARTER_PACKAGE:
        type = 'Cars.com SP';
        break;
      default:
        type = 'Cars.com';
    }
    return `${location.get('name')} (${type})`;
  }

  // all other locations just use their name
  return location.get('name');
};
export const getAllLocations = createSelector([allLocations], locations =>
  locations.map(location => location.set('displayName', makeDisplayName(location)))
);

export const getFetchingLocationIds = state => state.locations.get('fetching');

/**
 * Add hydrated team.users (based on existing team.userIds)
 * @param team
 * @param allUsers
 * @returns {*}
 */
const addUsersToTeam = (team, allUsers) => {
  let teamUsers = new Map();
  team.get('userIds', Set()).forEach(userId => {
    const user = allUsers.get(userId);
    if (user) teamUsers = teamUsers.set(userId, user);
  });
  teamUsers = teamUsers.sort(makeSortByStringKey('firstName'));
  return team.set('users', teamUsers);
};

/**
 * Add hydrated location.teams (based on existing location.teamIds)
 * @param {Map} location
 * @param {Map} allTeams
 * @param {Map} allUsers
 * @returns {Map} location
 */
const addTeamsToLocation = (location, allTeams, allUsers) => {
  let locationTeams = new Map();
  location.get('teamIds', Set()).forEach(teamId => {
    let team = allTeams.get(teamId);
    if (team) {
      team = addUsersToTeam(team, allUsers);
      locationTeams = locationTeams.set(teamId, team);
    }
  });
  return location.set('teams', locationTeams);
};

export const getActiveLocation = createSelector(
  [
    (state) => getActiveConversation(state),
    getAllLocations,
    getAllTeams,
    getAllUsers,
  ],
  (activeConvo, locations, allTeams, allUsers) => {
    if (!activeConvo) return Map();
    let activeLocation = locations.get(activeConvo.get('locationId'));
    if (activeLocation) {
      // add teams
      activeLocation = addTeamsToLocation(activeLocation, allTeams, allUsers);
      // add sorted dealer messages
      return activeLocation.set(
        'dealerMessages',
        activeLocation.get('dealerMessages', Map()).sort(makeSortByMomentKey('date', false))
      );
    }
    return Map();
  }
);

export const isActiveLocationFetching = createSelector(
  [getActiveLocation, getFetchingLocationIds],
  (activeLocation, fetchingLocationIds) => {
    if (!activeLocation) return false;
    return fetchingLocationIds.has(activeLocation.get('id'));
  }
);

export const getLoggedInUserLocations = createSelector(
  [getLoggedInUserData, getAllLocations, getAllTeams, getAllUsers],
  (userData, locations, allTeams, allUsers) => {
    let userLocations = Map();
    userData.get('locationIds').forEach(locationId => {
      let location = locations.get(locationId);
      location = addTeamsToLocation(location, allTeams, allUsers);
      location = location.set(
        'teams',
        location.get('teams').map(team => team.set('statusId', 9))
      );
      userLocations = userLocations.set(locationId, location);
    });
    return userLocations;
  }
);

const reduceUsersFromLocations = (result, location) => {
  location.get('teams').forEach(team => {
    team.get('users').forEach(user => {
      result = result.set(user.get('id'), user);
    });
  });
  return result;
};

// todo test
export const getLoggedInUserAgents = createSelector(
  [getLoggedInUserLocations, getLoggedInUserId],
  (locations, loggedInUserId) =>
    locations
      .reduce(reduceUsersFromLocations, Map()) // convert to user list
      .filter(user => user.get('id') !== loggedInUserId) // remove logged in user
      .sort(makeSortByStringKey('firstName'))
);

/**
 * @return {OrderedSet}
 */
export const getSelectedCustomerLocations = createSelector(
  [getSelectedCustomer, getAllLocations, getAllTeams, getAllUsers],
  (customer, locations, allTeams, allUsers) => {
    if (!customer || !customer.get('locationIds')) return OrderedSet();
    let customerLocations = customer
      .get('locationIds')
      .map(locationId => locations.get(locationId))
      .sort(makeSortByStringKey('name'));
    customerLocations = customerLocations.map(location =>
      addTeamsToLocation(location, allTeams, allUsers)
    );

    return customerLocations;
  }
);

/**
 * Get a combined map of all feeds for a customer with location included in the feed object
 * @return {OrderedMap}
 */
export const getSelectedCustomerFeeds = createSelector(
  [getSelectedCustomerLocations],
  locations => {
    let feeds = Map();
    locations.forEach(location => {
      let locationFeeds = location.get('feeds', Map());
      if (locationFeeds.size) {
        locationFeeds = locationFeeds.map(feed => feed.set('location', location));
      }
      feeds = feeds.merge(locationFeeds);
    });
    return feeds.sort(makeSortByStringKey('name'));
  }
);

/**
 * Get a combined map of all hotkeys for a customer with location included in the hotkey object
 * @return {Set}
 */
export const getSelectedCustomerHotkeys = createSelector(
  [getSelectedCustomerLocations],
  locations => {
    let hotkeys = Map();
    locations.forEach(location => {
      let locationHotkeys = location.get('hotkeys', Map());
      locationHotkeys = locationHotkeys.map(hotkey => hotkey.set('location', location));
      hotkeys = hotkeys.merge(locationHotkeys);
    });
    return hotkeys.sort(makeSortByStringKey('name')).sort(makeSortByBooleanKey('favorite'));
  }
);

/**
 * Get a combined map of all starters for a customer with location included in the starter object
 * @return {Set}
 */
export const getSelectedCustomerStarters = createSelector(
  [getSelectedCustomerLocations],
  locations => {
    let starters = Map();
    locations.forEach(location => {
      let locationStarters = location.get('starters', Map());
      locationStarters = locationStarters.map(starter => starter.set('location', location));
      starters = starters.merge(locationStarters);
    });
    return starters.sort(makeSortByStringKey('starter'));
  }
);

/**
 * Get a combined map of all links for a customer with location included in the link object
 * @return {Set}
 */
export const getSelectedCustomerLinks = createSelector(
  [getSelectedCustomerLocations],
  locations => {
    let links = Map();
    locations.forEach(location => {
      let locationLinks = location.get('links', Map());
      locationLinks = locationLinks.map(link => link.set('location', location));
      links = links.merge(locationLinks);
    });
    return links.sort(makeSortByStringKey('text'));
  }
);

/**
 * This method ideally would exist in teamSelectors, but it causes a circular dependency error because teamSelectors > locationSelectors > teamSelectors
 */
export const getSelectedCustomerTeams = createSelector(
  [getSelectedCustomerLocations, getAllTeams, getAllUsers],
  (locations, teams, allUsers) => {
    let customerTeams = Map();
    if (teams && teams.size > 0) {
      locations.forEach(location => {
        location.get('teamIds', Set()).forEach(teamId => {
          let team = teams.get(teamId);
          if (team && typeof team.get('id') === 'number') {
            team = team.set('location', location);
            team = addUsersToTeam(team, allUsers);
            customerTeams = customerTeams.set(team.get('id'), team);
          }
        });
      });
    }
    return customerTeams.sort(makeSortByStringKey('name'));
  }
);

export const getAllCustomerTeams = createSelector(
  [getAllLocations, getAllTeams, getAllUsers],
  (locations, teams, allUsers) => {
    let customerTeams = Map();
    if (teams && teams.size > 0) {
      locations.forEach(location => {
        location.get('teamIds', Set()).forEach(teamId => {
          let team = teams.get(teamId);
          if (team && typeof team.get('id') === 'number') {
            team = team.set('location', location);
            team = addUsersToTeam(team, allUsers);
            customerTeams = customerTeams.set(team.get('id'), team);
          }
        });
      });
    }
    return customerTeams.sort(makeSortByStringKey('name'));
  }
);

export const getLocationForTeam = (team, locations) => {
  if (!locations) return Map();

  return locations.reduce((matched, location) => {
    if (matched) return matched;
    if (location.get('teamIds', Set()).contains(team.get('id'))) {
      return location;
    }
    return null;
  }, null);
};
/**
 * Get a combined map of all CRMs for a customer with location and team included in the CRM object
 * @return {Set}
 */
export const getSelectedCustomerCRMs = createSelector(
  [getSelectedCustomerTeams, getSelectedCustomerLocations],
  (teams, locations) => {
    let crms = Map();
    teams.forEach(team => {
      const location = getLocationForTeam(team, locations);
      const teamCRMs = team.get('crms', Set()).map(crm =>
        crm
          .toMap()
          .set('team', team)
          .set('location', location)
      );
      crms = crms.merge(teamCRMs);
    });
    return crms.sort(makeSortByStringKey('name'));
  }
);

/**
 * Get a combined map of all accolades for a customer with location included in the accolade object
 * @return {Set}
 */
export const getSelectedCustomerAccolades = createSelector(
  [getSelectedCustomerLocations],
  locations => {
    let accolades = Map();
    locations.forEach(location => {
      let locationAccolades = location.get('accolades', Map());
      locationAccolades = locationAccolades.map(accolade =>
        accolade.set('location', location)
      );
      accolades = accolades.merge(locationAccolades);
    });
    return accolades.sort(makeSortByStringKey('accolade'));
  }
);

/**
 * Get a combined map of all generic content for a customer with location included in the content object
 * @return {Set}
 */
export const getSelectedCustomerGenericContent = createSelector(
  [getSelectedCustomerLocations],
  locations => {
    let contentList = Map();
    locations.forEach(location => {
      let locationContentList = location.get('content', Map());
      locationContentList = locationContentList.map(content => 
        content.set('location', location)
      );
      contentList = contentList.merge(locationContentList);
    });

    return contentList.sort(makeSortByStringKey('content'));
  }
);

/**
 * Get a combined map of all brochures for a customer with location included in the brochure object
 * @return {Set}
 */
export const getSelectedCustomerBrochures = createSelector(
  [getSelectedCustomerLocations],
  locations => {
    let brochures = Map();
    locations.forEach(location => {
      let locationBrochures = location.get('brochures', Map());
      locationBrochures = locationBrochures.map(brochure =>
        brochure.set('location', location)
      );
      brochures = brochures.merge(locationBrochures);
    });
    return brochures.sort(makeSortByStringKey('brochure'));
  }
);

/**
 * Get a combined map of all incentives for a customer with location included in the incentive object
 * @return {Set}
 */
export const getSelectedCustomerIncentives = createSelector(
  [getSelectedCustomerLocations],
  locations => {
    let incentives = Map();
    locations.forEach(location => {
      let locationIncentives = location.get('incentives', Map());
      locationIncentives = locationIncentives.map(incentive =>
        incentive.set('location', location)
      );
      incentives = incentives.merge(locationIncentives);
    });
    return incentives.sort(makeSortByStringKey('name'));
  }
);

/**
 * Get a combined map of all videos for a customer with location included in the video object
 * @return {Set}
 */
export const getSelectedCustomerVideos = createSelector(
  [getSelectedCustomerLocations],
  locations => {
    let videos = Map();
    locations.forEach(location => {
      let locationVideos = location.get('videos', Map());
      locationVideos = locationVideos.map(video => video.set('location', location));
      videos = videos.merge(locationVideos);
    });
    return videos.sort(makeSortByStringKey('name'));
  }
);

export const getSelectedLocationTeams = createSelector(
  [getSelectedCustomerLocations, getAllTeams],
  (locations, locationId, teams) => {
    let locationTeams = Set();
    locations.forEach(location => {
      if (location.get('id') === parseInt(locationId)) {
        location.get('teamIds', Set()).forEach(teamId => {
          let team = teams.get(teamId);
          team = team.set('location', location);
          locationTeams = locationTeams.add(team);
        });
      }
    });
    return locationTeams;
  }
);

export const getUserHotkeys = createSelector([getLoggedInUserLocations], locations =>
  locations.flatMap(location => location.get('hotkeys'))
);

export const getHotkeysForActiveConversation = createSelector(
  [getUserHotkeys, getActiveLocation, getTheme],
  (userHotkeys, convoLocation, themeId) => {
    let hotkeys = Map();
    let convoLocationHotkeys = convoLocation.get('hotkeys', Map());
    if (themeId === THEME_CALL_CENTER) {
      // call center users should have hotkeys from THEIR locations AND hotkeys from the convo location
      convoLocationHotkeys = convoLocationHotkeys.map(hotkey => {
        // it doesn't matter what category the dealer hotkey has, change it to "Dealer" so it shows in it's own tab
        hotkey = hotkey.set('category', 'Dealer');
        hotkey = hotkey.set('fromDealer', true);
        return hotkey.set('locationName', convoLocation.get('name'));
      });
      hotkeys = userHotkeys.merge(convoLocationHotkeys);
    } else {
      // regular users should only have hotkeys from the convo location
      hotkeys = convoLocationHotkeys;
    }

    return hotkeys
      .sort(makeSortByStringKey('name')) // sort by hotkey name
      .sort(makeSortByBooleanKey('fromDealer')) // put dealer hotkeys ahead of call center user hotkeys
      .sort(makeSortByBooleanKey('favorite')); // put favorites first
  }
);

/**
 * Get a combined map of all banners for a customer with location included in the banner object
 * @return {Set}
 */
export const getSelectedCustomerBanners = createSelector(
  [getSelectedCustomerLocations],
  locations => {
    let banners = Map();
    locations.forEach(location => {
      let locationBanners = location.get('banners', Map());
      if (locationBanners.size) {
        locationBanners = locationBanners.map(banner => banner.set('location', location));
      }
      banners = banners.merge(locationBanners);
    });
    return banners.sort(makeSortByStringKey('banner'));
  }
);

export const getSelectedLocation = (state) => state.locations.get('selectedLocationId');
