import { fromJS } from 'immutable';

export const getChromeExtensionConfig = state => state.config.getIn(['browser', 'chromeExtension']);
export const isBrowserFocused = state => state.config.getIn(['browser', 'isFocused'], false);
export const isOnline = state => state.config.getIn(['connection', 'isOnline'], false);
export const browserSupportsWebRTC = state =>
  state.config.getIn(['browser', 'webRtcSupport'], false);
export const isChromeExtensionInstalled = state =>
  state.config.getIn(['browser', 'chromeExtension', 'installed'], false);
export const getFeedFields = state => state.config.get('feedFields');
export const getCallCenterTeams = state =>
  state.config.get('callCenterTeams').map(team =>
    fromJS({
      id: team.get('id'),
      name: `${team.get('customerName')} - ${team.get('locationName')} - ${team.get('name')}`,
    })
  );

export const isChromeBrowser = state => state.config.getIn(['browser', 'isChromeBrowser'], false);
export const isBrowserSupported = state =>
  state.config.getIn(['browser', 'isSupportedBrowser'], false);
export const isElectron = state => state.config.getIn(['browser', 'isElectron'], false);
export const getReleaseStage = state => state.config.get('releaseStage');
