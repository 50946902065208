import { Map, Set } from 'immutable';
import * as types from '../constants/actionTypes';

const initialState = Map({
  connecting: false,
  connected: false,
  joinedNotificationChannels: Set(),
  joinedConversationChannels: Set(),
  joinedAllNotificationChannels: false,
});

export default function pusher(state = initialState, action) {
  switch (action.type) {
    case types.CONNECTING_TO_PUSHER:
      return state.set('connected', false).set('connecting', true);
    case types.CONNECTED_TO_PUSHER:
      return state.set('connecting', false).set('connected', true);
    case types.JOINED_NOTIFICATION_CHANNEL:
      return state.updateIn(['joinedNotificationChannels'], channels =>
        channels.add(action.payload)
      );
    case types.JOINED_ALL_NOTIFICATION_CHANNELS:
      return state.set('joinedAllNotificationChannels', true);
    case types.JOINED_CONVERSATION_CHANNEL:
      return state.updateIn(['joinedConversationChannels'], channels =>
        channels.add(action.payload)
      );
    default:
      return state;
  }
}
