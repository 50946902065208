import { Map, Set } from 'immutable';
import * as types from '../constants/actionTypes';

const initialState = Map({
  notification: Map({
    internal: Set(),
    external: Set(),
  }),
  subscribed: Set(),
});

export default function channels(state = initialState, action) {
  switch (action.type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.USER_OVERVIEW_FETCHED:
      return userOverviewFetched(state, action.payload.channels);
    case types.JOINED_NOTIFICATION_CHANNEL:
    case types.JOINED_CONVERSATION_CHANNEL:
    case types.JOINED_CONTROL_CHANNEL:
      return state.update('subscribed', subscribed => subscribed.add(action.payload));
    case types.LEFT_NOTIFICATION_CHANNEL:
    case types.LEFT_CONVERSATION_CHANNEL:
      return state.update('subscribed', subscribed => subscribed.delete(action.payload));
    default:
      return state;
  }
}

function userOverviewFetched(state, { internal, availableAgents: { location, team } }) {
  state = state.setIn(['notification', 'internal'], Set(internal));
  state = state.setIn(['notification', 'external'], Set(location.concat(team)));
  return state;
}
