import { Map } from 'immutable';
import * as types from '../constants/actionTypes';

const initialState = Map();

export default function config(state = initialState, action) {
  switch (action.type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.ADD_NOTIFICATION:
      return Map(action.payload);
    default:
      return state;
  }
}
