/* eslint-disable @typescript-eslint/no-explicit-any */
import Actions from 'redux/constants';
import { AxiosResponse } from 'axios';
import { getActiveConversation } from 'selectors/conversationSelectors';


export const connect = (payload: {
  identity: string;
  room: string;
  userId: number;
}): object => {
  return (dispatch: any, getState: any, { APIFactory, PusherManager }: any): any => {
    const subscription = PusherManager.getSubscription(payload.room);

    subscription.trigger('client-video-request', payload);

    dispatch({
      payload: {
        room: payload.room,
      },
      type: Actions.VIDEO_ROOM_CONNECT,
    });

    return APIFactory.getInstance(getState())
      .get(`/twilio/video/token/agent?identity=${payload.identity}&room=${payload.room}&userId=${payload.userId}`)
      .then((response: AxiosResponse) => {
        dispatch({
          payload: {
            room: payload.room,
            token: response.data,
          },
          type: Actions.VIDEO_ROOM_CONNECTED,
        });
      });
  };
};


export const disconnect = (payload: {
  identity: string;
  room: string;
}): object => {
  return (dispatch: any, _: any, { PusherManager }: any): any => {
    const subscription = PusherManager.getSubscription(payload.room);

    subscription.trigger('client-video-request-revoked', payload);

    dispatch({
      payload,
      type: Actions.VIDEO_ROOM_DISCONNECT,
    });
  };
};


export const toggle = (payload: {
  identity: string;
  room: string;
}): object => {
  return {
    payload,
    type: Actions.VIDEO_ROOM_TOGGLE,
  };
};

export function logVideoChatUsage() {
  return (dispatch: any, getState: any, { APIFactory }: any) => {
    const channelName = getActiveConversation(getState()).get('channel');
    const payload = {
      channelName,
    };
    const api = APIFactory.getInstance(getState());

    return api
      .post('/conversation/agent/used-video-chat', payload)
      .catch(() => {
        // do nothing
      });
  };
}
