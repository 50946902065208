import * as types from 'redux/constants/actionTypes';
import { getSmsLimitMessageTempId, getSmsLimitMessageToSend, getSmsLimitModalErrorMessage } from 'selectors/modalSelectors';
import { getErrorCode, getOemSmsLimitError } from 'businessLogic/conversationHelper';
import { messageSendError, messageSent, removeTempMessage } from 'redux/actions/conversationsActions';
import { Message } from './types';
import { messageSendErrors } from 'redux/constants/messages';
import { ThunkAction } from 'redux-thunk';
import { initialState } from './reducer';
import { AxiosError } from 'axios';

export function showSmsLimitModal() {
  return {
    type: types.SHOW_SMS_LIMIT_MODAL,
    payload: true,
  };
}

export function hideSmsLimitModal() {
  return {
    type: types.HIDE_SMS_LIMIT_MODAL,
    payload: false,
  };
}

export function handleSmsLimitModalCancel(): ThunkAction<void, any, any, any> {
  return async (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    const message = getSmsLimitMessageToSend(getState());
    const errorMessage = getSmsLimitModalErrorMessage(getState());
    const oemLimitation = getOemSmsLimitError(errorMessage);
    const tempId = getSmsLimitMessageTempId(getState());
    const payload = {
      channelName: message.channelName,
      oemLimitation,
    };

    try {
      await api.post(`/conversation/agent/message/oem-limitation`, payload);
    } catch {
      // do nothing
    }

    dispatch(removeTempMessage(message.channelName, tempId));

    dispatch(setSmsLimitModalToInitialState());
  };
}

export function handleSmsLimitModalAccept(): ThunkAction<void, any, any, any> {
  return async (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    const message = getSmsLimitMessageToSend(getState());
    const errorMessage = getSmsLimitModalErrorMessage(getState());
    const oemLimitation = getOemSmsLimitError(errorMessage);
    const tempId = getSmsLimitMessageTempId(getState());

    if (!oemLimitation) {
      dispatch(messageSendError(
        message.channelName,
        tempId,
        message.messageBody,
        messageSendErrors.UNAVAILABLE_FOR_LEGAL_REASONS
      ));
      dispatch(hideSmsLimitModal());
      return;
    }
    
    const payload = { oemLimitation, ...message };

    return api
      .post(`/conversation/agent/message/send/override`, payload)
      .then((response: any) => {
        dispatch(messageSent(message.channelName, tempId, response.data.messageId));
      })
      .catch((error: AxiosError) => {
        const errorCode = getErrorCode(error.response);
        dispatch(messageSendError(
          message.channelName,
          tempId,
          message.messageBody,
          errorCode
        ));
      })
      .finally(() => {
        dispatch(setSmsLimitModalToInitialState());
      });
  };
}

export function setSmsLimitModalErrorCode(errorMessage: string | null) {
  return {
    type: types.SET_SMS_LIMIT_MODAL_ERROR_CODE,
    payload: errorMessage,
  };
}

export function setSmsLimitMessageToSend(message: Message) {
  return {
    type: types.SET_SMS_LIMIT_MESSAGE_TO_SEND,
    payload: message,
  };
}

export function setSmsLimitMessageTempId(tempId: string | null) {
  return {
    type: types.SET_SMS_LIMIT_MESSAGE_TEMP_ID,
    payload: tempId,
  };
}

export function setSmsLimitModalToInitialState(): ThunkAction<void, any, any, any> {
  return async (dispatch) => {
    dispatch(setSmsLimitModalErrorCode(initialState.smsLimitModal.errorMessage));
    dispatch(setSmsLimitMessageToSend(initialState.smsLimitModal.smsMessage));
    dispatch(setSmsLimitMessageTempId(initialState.smsLimitModal.smsMessageTempId));
    dispatch(hideSmsLimitModal());
  };
}

export function handleUnavailableForLegalReasons(oemLimitationException: string, message: Message, tempId: string): ThunkAction<any, any, any, any> {
  return (dispatch) => {
    dispatch(setSmsLimitModalErrorCode(oemLimitationException));
    dispatch(setSmsLimitMessageToSend(message));
    dispatch(setSmsLimitMessageTempId(tempId));
    dispatch(showSmsLimitModal());
  };
}
