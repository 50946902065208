import { Map, Set } from 'immutable';
import moment from 'moment';
import * as types from '../constants/actionTypes';

const initialState = Map({
  holidays: Set(),
  holidaysFetching: false,
});

export default function channels(state = initialState, { type, payload }) {
  switch (type) {
    case types.HOLIDAYS_FETCHING:
      return state.set('holidaysFetching', true);
    case types.HOLIDAYS_FETCH_ERROR:
      return state.set('holidaysFetching', false);
    case types.HOLIDAYS_FETCHED:
      return holidaysFetched(state, payload);
    default:
      return state;
  }
}

function holidaysFetched(state, { holidays }) {
  state = state.set('holidaysFetching', false);
  return state.set('holidays', mapHolidays(holidays));
}

function mapHolidays(holidays) {
  return Set(holidays.map(holiday => mapHoliday(holiday)));
}

function mapHoliday(holiday) {
  return Map({
    name: holiday.name,
    date: moment(holiday.date),
  });
}
