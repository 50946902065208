/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Map } from 'immutable';
import assign from 'lodash.assign';
import get from 'lodash.get';
import moment from 'moment';
import * as types from 'redux/constants/actionTypes';
import { messageSendErrors } from 'redux/constants/messages';
import { getLoggedInUserId, getTheme } from 'selectors/userSelectors';
import { getSelectedCustomerId } from 'selectors/customerSelectors';
import { getAllConversations, getActiveConversation } from 'selectors/conversationSelectors';
import { leaveConversationChannel } from 'redux/actions/pusherActions';
import { fetchLocationHotkeys } from 'redux/actions/hotkeyActions';
import { fetchLocation, fetchLocationDealerMessages } from 'redux/actions/locationActions';
import { fetchLocationCRMs } from 'redux/actions/crmActions';
import { getUnreadMessages, getMinMessageId, getErrorCode } from 'businessLogic/conversationHelper';
import { addNotification, addErrorNotification } from 'redux/actions/notificationActions';
import { fetchAverageScores } from 'redux/actions/usersActions';
import { fetchAgentSummaryData } from 'redux/actions/reportActions';
import { addAttachment } from 'redux/actions/sendFormActions';
import { getUser, getFullName, THEME_CALL_CENTER } from 'businessLogic/userHelper';
import { getTeam } from 'businessLogic/teamHelper';
import { buildErrorMessageFromResponse, getRandomString } from 'businessLogic/util';
import { getBugsnag } from '../../../src/app/services';
import { handleUnavailableForLegalReasons } from 'redux/Modals';

const bugsnag = getBugsnag();

/**
 * @returns {Promise}
 */
export function fetchCurrentConversations() {
  return (dispatch, getState, { APIFactory }) => {
    if (getState().conversations.get('isFetchingCurrentConversations')) {
      return Promise.reject();
    }

    dispatch({ type: types.CURRENT_CONVERSATIONS_FETCHING });

    const api = APIFactory.getInstance(getState());
    return api
      .get('/conversation/agent/current')
      .then(response => {
        dispatch({ type: types.CURRENT_CONVERSATIONS_FETCHED, payload: response.data });
      })
      .catch(() => {
        dispatch({ type: types.CURRENT_CONVERSATIONS_FETCH_ERROR });
      });
  };
}

/**
 * @param {string} channel
 * @return {Object}
 */
export function activateConversation(channel) {
  return (dispatch, getState) => {
    // activate conversation
    dispatch({ type: types.CONVERSATION_ACTIVATE, payload: channel });
    const convo = getActiveConversation(getState());
    const themeId = getTheme(getState());

    // fetch location info
    dispatch(fetchLocation(convo.get('locationId')));

    // fetch location hotkeys
    dispatch(fetchLocationHotkeys(convo.get('locationId')));

    // fetch location CRMs
    dispatch(fetchLocationCRMs(convo.get('locationId')));

    // fetch location dealer messages
    if (themeId === THEME_CALL_CENTER) {
      dispatch(fetchLocationDealerMessages(convo.get('locationId')));
    }
  };
}

export function startOutboundConversation(teamId, visitor, message) {
  return (dispatch, getState, { APIFactory }) => {
    const newConvoData = {
      id: visitor.id,
      teamId,
      visitor,
      message,
    };
    const api = APIFactory.getInstance(getState());
    let channelName;

    return api
      .post('/conversation/agent/start/outbound', newConvoData)
      .then(response => {
        channelName = response.data.channelName;
      })
      .then(() => dispatch(fetchConversationDetail(channelName)))
      .then(() => dispatch(activateConversation(channelName)))
      .catch(error => {
        dispatch(
          addErrorNotification(
            buildErrorMessageFromResponse(
              error.response,
              'Error starting conversation.'
            )
          )
        );
        return Promise.reject();
      });
  };
}

export function fetchAllTranscripts() {
  return (dispatch, getState) => {
    dispatch({ type: types.ALL_TRANSCRIPTS_FETCHING });
    const allConvos = getAllConversations(getState());
    const fetches = allConvos.map(convo => dispatch(fetchTranscript(convo.get('channel'))));
    return Promise.all(fetches).then(() => dispatch({ type: types.ALL_TRANSCRIPTS_FETCHED }));
  };
}

/**
 * @param {string} channel
 * @param {bool} getPreviousPage
 * @param {int} limit
 */
export function fetchTranscript(channel, { getPreviousPage = false, limit = 50 } = {}) {
  return (dispatch, getState, { APIFactory }) => {
    const state = getState();

    dispatch({
      type: types.TRANSCRIPT_FETCHING,
      payload: { channel, isPreviousPage: getPreviousPage },
    });

    const requestData = { channelName: channel, limit };

    if (getPreviousPage) {
      const conversation = state.conversations.getIn(['conversations', channel], Map());
      requestData.maxMessageId = getMinMessageId(conversation);
    }

    const api = APIFactory.getInstance(state);
    return api
      .get('/conversation/agent/transcript-history', { timeout: 10000, params: requestData })
      .then(response => {
        dispatch({
          type: types.TRANSCRIPT_FETCHED,
          payload: {
            channel,
            response: response.data,
            limit: requestData.limit,
            isPreviousPage: getPreviousPage,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: types.TRANSCRIPT_FETCH_ERROR,
          payload: { channel, isPreviousPage: getPreviousPage },
        });
      });
  };
}

export function sendMessage(channel, message, translationPlugin) {
  return (dispatch, getState, { APIFactory }) => {
    const messageData = (
      message &&
      message.messageText &&
      translationPlugin &&
      translationPlugin.enabled &&
      translationPlugin.languageSource &&
      translationPlugin.languageTarget
    )
      ? new Promise((resolve, reject) => {
        APIFactory.getInstance(getState())
          .post(`/conversation/agent/message/translate`, {
            messages: [message.messageText],
            sourceLang: translationPlugin.languageTarget,
            targetLang: translationPlugin.languageSource,
          })
          .then((response) => {
            resolve({
              ...message,
              messageText: get(response, 'data.translatedMessages[0]', ''),
            });
          })
          .catch(reject);
      })
      : new Promise((resolve) => {
        resolve(message);
      });

    return messageData
      .then((data) => {
        const tempId = getRandomString();
        dispatch(
          messageSending(
            channel,
            assign({}, data, { agentId: getLoggedInUserId(getState()), messageId: tempId }),
            translationPlugin,
            {
              source: message.messageText,
              target: data.messageText,
            }
          )
        );

        const api = APIFactory.getInstance(getState());

        const payload = {
          channelName: channel,
          type: data.type,
          category: data.category,
          messageBody: data.messageText,
          attachments:
            data.attachments && data.attachments.size > 0
              ? JSON.stringify(data.attachments)
              : null,
          garageLink: data.garageLink,
          uuid: data.uuid,
        };

        return api
          .post('/conversation/agent/message/send', payload)
          .then(response => {
            dispatch(messageSent(channel, tempId, response.data.messageId));
          })
          .catch(error => {
            const errorMessage = error.message;
            let errorCode;

            if (error.response) {
              errorCode = getErrorCode(error.response);
            } else if (error.message && error.message.match(/timeout/g)) {
              errorCode = messageSendErrors.TIMEOUT;
            } else {
              errorCode = messageSendErrors.UNKNOWN_ERROR;
            }

            if (errorCode === messageSendErrors.UNAVAILABLE_FOR_LEGAL_REASONS) {
              const oemLimitationException = error.response.data.errors[0];
              dispatch(handleUnavailableForLegalReasons(oemLimitationException, payload, tempId));
              return;
            }

            reportToBugsnag(
              `MESSAGE_SEND_ERROR_${errorCode}`,
              'Error sending message',
              { channel, data, errorMessage },
              'info'
            );

            dispatch(messageSendError(channel, tempId, data.messageText, errorCode));
          });
      });
  };
}

function messageSending(channel, data, translationPlugin, message) {
  return {
    type: types.MESSAGE_SENDING,
    payload: {
      channel,
      data,
      translationPlugin,
      message,
    },
  };
}

export function messageSent(channel, tempId, messageId) {
  return { type: types.MESSAGE_SENT, payload: { channel, tempId, messageId } };
}

export function messageSendError(channel, tempId, text, errorCode) {
  return { type: types.MESSAGE_SEND_ERROR, payload: { channel, tempId, text, errorCode } };
}

export function endConversation(channel) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.ENDING_CONVERSATION, payload: channel });

    dispatch(leaveConversationChannel(channel));

    const api = APIFactory.getInstance(getState());
    return api
      .post('/conversation/agent/end', { channelName: channel })
      .then(() => {
        dispatch(endedConversation(channel));
        dispatch(hideTransferPanel(channel));
        dispatch(fetchTranscript(channel));
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 410) {
            // error ending this convo because it was already ended, so just treat it like it was successful
            dispatch(endedConversation(channel));
            dispatch(hideTransferPanel(channel));
            dispatch(fetchTranscript(channel));
          } else {
            dispatch({ type: types.ENDING_CONVERSATION_ERROR, payload: channel });
            reportToBugsnag(
              'error_ending',
              'Error ending conversation',
              {
                channel,
                response: error.response,
              },
              'warning'
            );

            const errorMessage =
                            error.response.data && error.response.data.errors
                              ? error.response.data.errors.join('. ')
                              : error.response.statusText;
            dispatch(
              addErrorNotification(
                `There was an error ending this conversation. (${errorMessage})`
              )
            );
          }
        } else {
          dispatch({ type: types.ENDING_CONVERSATION_ERROR, payload: channel });
          reportToBugsnag(error, 'error_ending', { channel }, 'error');
          dispatch(
            addErrorNotification('There was an unknown error ending this conversation.')
          );
        }
      });
  };
}

export function toggleCreatingConversationPanel(shouldShow) {
  return { type: types.TOGGLE_NEW_CONVERSATION_PANEL, payload: shouldShow };
}

export function endedConversation(channel) {
  return { type: types.ENDED_CONVERSATION, payload: channel };
}

export function leaveConversation(channel) {
  return (dispatch, getState, { APIFactory, PusherManager }) => {
    dispatch(leavingConversation(channel));

    leaveConversationChannel(getState(), dispatch, PusherManager, channel);

    const api = APIFactory.getInstance(getState());
    return api
      .post('/conversation/agent/leave', { channelName: channel })
      .then(() => {
        dispatch(leftConversation(channel));
      })
      .catch(error => {
        dispatch(leavingConversationError(channel));

        const errorMessage = error.response && error.response.data.errors
          ? error.response.data.errors.join('. ')
          : error.response.statusText;
        dispatch(
          addNotification({
            title: 'Error',
            message: `There was an error leaving this conversation. (${errorMessage})`,
            level: 'error',
          })
        );
      });
  };
}

function leavingConversation(channel) {
  return { type: types.LEAVING_CONVERSATION, payload: channel };
}

export function leftConversation(channel) {
  return { type: types.LEFT_CONVERSATION, payload: channel };
}

function leavingConversationError(channel) {
  return { type: types.LEAVING_CONVERSATION_ERROR, payload: channel };
}

export function closeConversation(channel) {
  return { type: types.CLOSE_CONVERSATION, payload: channel };
}

export function markAllMessagesRead(channel) {
  return (dispatch, getState, { APIFactory }) => {
    const conversation = getState().conversations.getIn(['conversations', channel], Map());
    const loggedInUserId = getLoggedInUserId(getState());
    const unreadMessages = getUnreadMessages(loggedInUserId, conversation);

    if (!unreadMessages || unreadMessages.size === 0) return Promise.resolve();

    const api = APIFactory.getInstance(getState());

    const messageIds = unreadMessages.map(message => message.get('id')).toArray();
    return api
      .post('/conversation/agent/mark/read', { messageId: messageIds })
      .then(response => {
        const successfulMessageIds = response.data.result.successes;
        dispatch({
          type: types.MARKED_MESSAGES_READ,
          payload: { channel, messageIds: successfulMessageIds, loggedInUserId },
        });
      })
      .catch(() => {
        /* ignore error */
      });
  };
}

export function sendTypingStatus(status, channel) {
  return (dispatch, getState, { PusherManager }) => {
    const subscription = PusherManager.getSubscription(channel);
    if (subscription && subscription.subscribed) {
      subscription.trigger('client-chat-status-notification', {
        status,
        fromType: 'agent',
        fromId: getLoggedInUserId(getState()),
      });
    }
  };
}

export function toggleTransferPanel(channel) {
  return { type: types.TOGGLE_TRANSFER_PANEL, payload: channel };
}

export function hideTransferPanel(channel) {
  return { type: types.HIDE_TRANSFER_PANEL, payload: channel };
}

function requestingTransfer(channel) {
  return { type: types.REQUESTING_TRANSFER, payload: channel };
}

function requestedTransfer(channel) {
  return { type: types.REQUESTED_TRANSFER, payload: channel };
}

function transferRequestError(channel) {
  return { type: types.REQUESTED_TRANSFER_ERROR, payload: channel };
}

export function transferToUser(channel, userId) {
  return (dispatch, getState, { APIFactory }) => {
    const targetName = getFullName(getUser(getState(), userId));

    dispatch(requestingTransfer(channel));

    const api = APIFactory.getInstance(getState());
    return api
      .post('/conversation/agent/transfer', { channelName: channel, toUserId: userId })
      .then(response => {
        dispatch(
          addNotification({
            title: 'Transfer Request Sent',
            message: `Successfully sent transfer request to ${targetName}. Please wait for their response.`,
            level: 'success',
          })
        );

        dispatch(hideTransferPanel(channel));
      })
      .then(() => {
        dispatch(requestedTransfer(channel));
      })
      .catch(() => {
        dispatch(transferRequestError(channel));
        dispatch(
          addNotification({
            title: 'Transfer Request Error',
            message: `There was an error requesting a transfer to ${targetName}.`,
            level: 'error',
          })
        );
      });
  };
}

export function transferToTeam(channel, teamId) {
  return (dispatch, getState, { APIFactory }) => {
    const targetName = getTeam(getState(), teamId).get('name');

    dispatch(requestingTransfer(channel));

    const api = APIFactory.getInstance(getState());
    return api
      .post('/conversation/agent/transfer/team', { channelName: channel, toTeamId: teamId })
      .then(response => {
        dispatch(
          addNotification({
            title: 'Shout Transfer Request Sent',
            message: `Successfully sent transfer request to team ${targetName}. Please wait for a response.`,
            level: 'success',
          })
        );

        dispatch(hideTransferPanel(channel));
      })
      .then(() => {
        dispatch(requestedTransfer(channel));
      })
      .catch(() => {
        dispatch(transferRequestError(channel));
        dispatch(
          addNotification({
            title: 'Shout Transfer Request Error',
            message: `There was an error requesting a transfer to team ${targetName}.`,
            level: 'error',
          })
        );
      });
  };
}

export function setSearchFilter(channel, filter) {
  return {
    type: types.SET_GC_SEARCH_FILTER,
    payload: { channel, filter },
  };
}

export function setSearchPhrase(channel, searchPhrase) {
  return {
    type: types.SET_GC_SEARCH_PHRASE,
    payload: { channel, searchPhrase },
  };
}

export function setVehicleDetailSearchParams(channel, vehicleDetails) {
  return {
    type: types.SET_GC_VEHICLE_DETAILS_SEARCH_PARAMS,
    payload: { channel, vehicleDetails },
  };
}

/**
 * fetch info on a single conversation
 * @param channel
 */
export function fetchConversation(channel) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CONVERSATION_FETCHING, payload: { channel } });
    const api = APIFactory.getInstance(getState());
    return api
      .get('/conversation/agent/show', { params: { channelName: channel } })
      .then(response =>
        dispatch({
          type: types.CONVERSATION_FETCHED,
          payload: { channel, data: response.data.conversation },
        })
      );
  };
}

/**
 * fetch conversation info, then transcript
 * @param channel
 */
export function fetchConversationDetail(channel) {
  return (dispatch, getState, { APIFactory }) =>
    dispatch(fetchConversation(channel)).then(() =>
      dispatch(fetchTranscript(channel, { limit: 10000 }))
    );
}

export function setCrmNotes(channel, notes) {
  return { type: types.CONVERSATION_SET_CRM_NOTES, payload: { channel, notes } };
}

export function setCrmSendType(channel, type) {
  return { type: types.CONVERSATION_SET_CRM_SEND_TYPE, payload: { channel, type } };
}

export function resetSentToCrm(channel) {
  return { type: types.RESET_SET_SENT_TO_CRM, payload: { channel } };
}

export function fetchScorePanelData() {
  return (dispatch, getState) => {
    const userId = getLoggedInUserId(getState());
    const customerId = getSelectedCustomerId(getState());
    const activeConvo = getActiveConversation(getState());

    // fetch agent summary data for today
    const todayStart = moment(moment().format('YYYY-MM-DD 00:00:00'));
    const todayEnd = moment(moment().format('YYYY-MM-DD 23:59:59'));
    const summaryDataPromise = dispatch(fetchAgentSummaryData(userId, customerId, todayStart, todayEnd));

    // fetch average scores
    const averageScorePromise = dispatch(fetchAverageScores(userId));

    // fetch active conversation score
    let convoScorePromise;
    if (activeConvo) {
      convoScorePromise = dispatch(
        fetchConversationScore(activeConvo.get('channel'), userId)
      );
    } else {
      convoScorePromise = Promise.resolve();
    }

    dispatch({ type: types.SCORE_PANEL_DATA_FETCHING });
    return Promise.all([summaryDataPromise, averageScorePromise, convoScorePromise])
      .then(() => dispatch({ type: types.SCORE_PANEL_DATA_FETCHED }))
      .catch(() => dispatch({ type: types.SCORE_PANEL_DATA_FETCH_ERROR }));
  };
}

export function fetchConversationScore(channel, userId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CONVERSATION_SCORES_FETCHING, payload: { userId, channel } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/conversation/agent/${userId}/scores?channelName=${channel}`)
      .then(response => {
        dispatch({
          type: types.CONVERSATION_SCORES_FETCHED,
          payload: { userId, channel, scores: response.data },
        });
      })
      .catch(() => {
        dispatch({
          type: types.CONVERSATION_SCORES_FETCH_ERROR,
          payload: { userId, channel },
        });
      });
  };
}

export function attachImage({ chatImage }, channel, onUploadProgress) {
  return (dispatch, getState, { APIFactory }) => {
    const data = new window.FormData();
    data.append('image', chatImage[0]);

    const api = APIFactory.getInstance(getState());
    const config = {
      timeout: 60000,
      onUploadProgress,
    };
    return api.post('/utility/image', data, config).then(response => {
      const attachment = {
        item: {
          meta: JSON.stringify({
            doctype: 'image',
            url: response.data.url,
            filename: 'foobar',
          }),
        },
        title: 'Image',
        iconClass: 'ion-camera',
      };
      dispatch(addAttachment(channel, attachment));
      return Promise.resolve(response);
    });
  };
}

export function toggleExplodeToText(channel) {
  return { type: types.TOGGLE_EXPLODE_TO_TEXT, payload: { channel } };
}

function reportToBugsnag(type, message, errorData, level) {
  if (process.env.BABEL_ENV !== 'mocha') {
    bugsnag.notify(new Error(message), {
      severity: level,
      metaData: {
        type,
        errorData,
      },
    });
  }
}

export function setConversationUserIds({ channelName, userIds }) {
  return {
    type: types.CONVERSATION_SET_USER_IDS,
    payload: { channelName, userIds },
  };
}

export function logExplodeToTextUsage(field) {
  return (dispatch, getState, { APIFactory }) => {
    const channelName = getActiveConversation(getState()).get("channel");
    const payload = {
      channelName,
      field,
    };
    const api = APIFactory.getInstance(getState());

    return api
      .post("/conversation/agent/used-explode-to-text", payload)
      .catch(() => {
        // do nothing
      });
  };
}

export function removeTempMessage(channel, tempId) {
  return { type: types.REMOVE_TEMP_MESSAGE, payload: { channel, tempId } };
}
