import { Map } from 'immutable';
import moment from 'moment';
import * as types from '../constants/actionTypes';

const initialState = Map({
  busy: false,
  showScoresPanel: false,
  scorePanelDataFetching: false,
  gloveCompartment: Map({
    selectedTab: 0,
  }),
  dateRange: Map({
    startDate: moment(
      moment()
        .subtract(6, 'days')
        .format('YYYY-MM-DD 00:00:00')
    ),
    endDate: moment(
      moment()
        .subtract(0, 'days')
        .format('YYYY-MM-DD 23:59:59')
    ),
    selected: 'Last 7 Days',
  }),
  crmTemplates: Map({
    fetched: false,
  }),
});

export default function(state = initialState, { type, payload }) {
  /* eslint-disable no-fallthrough */
  switch (type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.TOGGLE_SCORE_PANEL:
      return state.set('showScoresPanel', !state.get('showScoresPanel'));
    case types.SCORE_PANEL_DATA_FETCHING:
      return state.set('scorePanelDataFetching', true);
    case types.SCORE_PANEL_DATA_FETCHED:
    case types.SCORE_PANEL_DATA_FETCH_ERROR:
      return state.set('scorePanelDataFetching', false);
    case types.CUSTOMER_DELETING:
    case types.CUSTOMER_UPDATING:
    case types.CUSTOMER_AVATAR_DELETING:
    case types.CUSTOMER_HOTKEYS_FETCHING:
    case types.CUSTOMER_STARTERS_FETCHING:
    case types.CUSTOMER_LINKS_FETCHING:
    case types.CUSTOMER_CRMS_FETCHING:
    case types.CUSTOMER_FEEDS_FETCHING:
    case types.CUSTOMER_GENERIC_CONTENT_FETCHING:
    case types.FEED_DELETING:
    case types.FEED_SAVING:
    case types.FEED_UPDATING:
    case types.GENERIC_CONTENT_DELETING:
    case types.GENERIC_CONTENT_SAVING:
    case types.GENERIC_CONTENT_UPDATING:
    case types.HOTKEY_DELETING:
    case types.HOTKEY_SAVING:
    case types.STARTER_DELETING:
    case types.STARTER_SAVING:
    case types.STARTER_FETCHING:
    case types.LINK_DELETING:
    case types.LINK_SAVING:
    case types.LINK_FETCHING:
    case types.CRM_DELETING:
    case types.CRM_SAVING:
    case types.CRM_AUDIT_REQUESTED:
    case types.CRM_FETCHING:
    case types.USER_AVATAR_DELETING:
    case types.USER_LOCATIONS_UPDATING:
    case types.USER_PROFILE_UPDATING:
    case types.USER_UPDATING:
    case types.USER_PASSWORD_CHANGING:
    case types.USER_TEAMS_UPDATING:
    case types.LOCATION_DELETING:
    case types.LOCATION_UPDATING:
    case types.LOCATION_REACTIVATING:
    case types.TEAM_USERS_UPDATING:
    case types.LOCATION_BANNERS_FETCHING:
    case types.BANNER_SAVING:
    case types.BANNER_DELETING:
    case types.TEAM_SAVING:
    case types.TEAM_HOUR_DELETING:
    case types.TEAM_HOUR_ADDING:
    case types.TEAM_CLOSED_DATE_DELETING:
    case types.TEAM_CLOSED_DATE_ADDING:
    case types.USER_DELETING:
    case types.USER_RESTORING:
    case types.CUSTOMER_SUMMARY_FETCHING:
    case types.LOCATION_SUMMARY_FETCHING:
    case types.TEAM_SUMMARY_FETCHING:
    case types.AGENT_SUMMARY_FETCHING:
    case types.CONVO_HISTORY_REPORT_FETCHING:
    case types.CONVO_HISTORY_REPORT_CSV_FETCHING:
    case types.CUSTOMER_MANAGED_CHAT_POLICY_SAVING:
    case types.SOLD_CUSTOMERS_SUMMARY_FETCHING:
    case types.SOLD_CUSTOMERS_DETAILS_FETCHING:
    case types.SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCHING:
    case types.SOLD_CUSTOMERS_LOCATION_DETAILS_FETCHING:
    case types.TEAM_POLICY_SAVING:
    case types.FB_MARKETPLACE_IMPORTING:
    case types.OEM_TEMPLATES_UPDATING:
    case types.LOCATION_GBM_STARTING:
    case types.LOCATION_GBM_REMOVING:
    case types.LOCATION_GBM_MESSAGE_TOGGLING:
      return state.set('busy', true);
    case types.CUSTOMER_DELETED:
    case types.CUSTOMER_DELETE_ERROR:
    case types.CUSTOMER_UPDATED:
    case types.CUSTOMER_UPDATE_ERROR:
    case types.CUSTOMER_AVATAR_DELETED:
    case types.CUSTOMER_AVATAR_DELETE_ERROR:
    case types.CUSTOMER_HOTKEYS_FETCHED:
    case types.CUSTOMER_HOTKEYS_FETCH_ERROR:
    case types.CUSTOMER_STARTERS_FETCHED:
    case types.CUSTOMER_STARTERS_FETCH_ERROR:
    case types.CUSTOMER_LINKS_FETCHED:
    case types.CUSTOMER_LINKS_FETCH_ERROR:
    case types.CUSTOMER_CRMS_FETCHED:
    case types.CUSTOMER_CRMS_FETCH_ERROR:
    case types.CUSTOMER_FEEDS_FETCHED:
    case types.CUSTOMER_FEEDS_FETCH_ERROR:
    case types.CUSTOMER_GENERIC_CONTENT_FETCHED:
    case types.CUSTOMER_GENERIC_CONTENT_FETCH_ERROR:
    case types.FEED_DELETED:
    case types.FEED_DELETE_ERROR:
    case types.FEED_ADDED:
    case types.FEED_SAVE_ERROR:
    case types.FEED_UPDATED:
    case types.FEED_UPDATE_ERROR:
    case types.GENERIC_CONTENT_DELETED:
    case types.GENERIC_CONTENT_DELETE_ERROR:
    case types.GENERIC_CONTENT_ADDED:
    case types.GENERIC_CONTENT_SAVE_ERROR:
    case types.GENERIC_CONTENT_UPDATED:
    case types.GENERIC_CONTENT_UPDATE_ERROR:
    case types.HOTKEY_DELETED:
    case types.HOTKEY_DELETE_ERROR:
    case types.HOTKEY_SAVED:
    case types.HOTKEY_SAVE_ERROR:
    case types.HOTKEY_FETCHED:
    case types.HOTKEY_FETCH_ERROR:
    case types.STARTER_FETCHED:
    case types.STARTER_FETCH_ERROR:
    case types.STARTER_DELETED:
    case types.STARTER_DELETE_ERROR:
    case types.STARTER_SAVED:
    case types.STARTER_SAVE_ERROR:
    case types.LINK_FETCHED:
    case types.LINK_FETCH_ERROR:
    case types.LINK_DELETED:
    case types.LINK_DELETE_ERROR:
    case types.LINK_SAVED:
    case types.LINK_SAVE_ERROR:
    case types.CRM_FETCHED:
    case types.CRM_FETCH_ERROR:
    case types.CRM_DELETED:
    case types.CRM_DELETE_ERROR:
    case types.CRM_SAVED:
    case types.CRM_SAVE_ERROR:
    case types.CRM_AUDIT_FAILED:
    case types.CRM_AUDIT_COMPLETED:
    case types.USER_AVATAR_DELETED:
    case types.USER_AVATAR_DELETE_ERROR:
    case types.USER_LOCATIONS_UPDATED:
    case types.USER_LOCATIONS_UPDATE_ERROR:
    case types.USER_PROFILE_UPDATED:
    case types.USER_PROFILE_INFO_UPDATED:
    case types.USER_PROFILE_UPDATE_ERROR:
    case types.USER_UPDATED:
    case types.USER_UPDATE_ERROR:
    case types.USER_PASSWORD_CHANGED:
    case types.USER_PASSWORD_CHANGE_ERROR:
    case types.USER_TEAMS_UPDATED:
    case types.USER_TEAMS_UPDATE_ERROR:
    case types.LOCATION_DELETED:
    case types.LOCATION_DELETE_ERROR:
    case types.LOCATION_UPDATED:
    case types.LOCATION_UPDATE_ERROR:
    case types.TEAM_USERS_UPDATED:
    case types.TEAM_USERS_UPDATE_ERROR:
    case types.LOCATION_BANNERS_FETCHED:
    case types.LOCATION_BANNERS_FETCH_ERROR:
    case types.BANNER_SAVED:
    case types.BANNER_SAVE_ERROR:
    case types.BANNER_DELETED:
    case types.BANNER_DELETE_ERROR:
    case types.TEAM_SAVED:
    case types.TEAM_SAVE_ERROR:
    case types.TEAM_HOUR_DELETED:
    case types.TEAM_HOUR_DELETE_ERROR:
    case types.TEAM_HOUR_ADDED:
    case types.TEAM_HOUR_ADD_ERROR:
    case types.TEAM_CLOSED_DATE_DELETED:
    case types.TEAM_CLOSED_DATE_DELETE_ERROR:
    case types.TEAM_CLOSED_DATES_ADDED:
    case types.TEAM_CLOSED_DATE_ADD_ERROR:
    case types.USER_ADDED_TO_LOCATION:
    case types.USER_DELETED:
    case types.USER_DELETED_FROM_LOCATION:
    case types.USER_DELETED_SUPERADMIN:
    case types.USER_DELETE_ERROR:
    case types.CUSTOMER_SUMMARY_FETCHED:
    case types.CUSTOMER_SUMMARY_FETCH_ERROR:
    case types.LOCATION_SUMMARY_FETCHED:
    case types.LOCATION_SUMMARY_FETCH_ERROR:
    case types.TEAM_SUMMARY_FETCHED:
    case types.TEAM_SUMMARY_FETCH_ERROR:
    case types.AGENT_SUMMARY_FETCHED:
    case types.AGENT_SUMMARY_FETCH_ERROR:
    case types.CONVO_HISTORY_REPORT_FETCHED:
    case types.CONVO_HISTORY_REPORT_FETCH_ERROR:
    case types.CONVO_HISTORY_REPORT_CSV_FETCHED:
    case types.CONVO_HISTORY_REPORT_CSV_FETCH_ERROR:
    case types.LOCATION_REACTIVATION_ERROR:
    case types.LOCATION_REACTIVATED:
    case types.CUSTOMER_DEALER_MESSAGE_DELETED:
    case types.CUSTOMER_DEALER_MESSAGE_DELETE_ERROR:
    case types.CUSTOMER_DEALER_MESSAGE_SAVED:
    case types.CUSTOMER_DEALER_MESSAGE_SAVE_ERROR:
    case types.CUSTOMER_MANAGED_CHAT_POLICY_SAVED:
    case types.CUSTOMER_MANAGED_CHAT_POLICY_SAVE_ERROR:
    case types.SOLD_CUSTOMERS_SUMMARY_FETCHED:
    case types.SOLD_CUSTOMERS_SUMMARY_FETCH_ERROR:
    case types.SOLD_CUSTOMERS_DETAILS_FETCHED:
    case types.SOLD_CUSTOMERS_DETAILS_FETCH_ERROR:
    case types.SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCHED:
    case types.SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCH_ERROR:
    case types.SOLD_CUSTOMERS_LOCATION_DETAILS_FETCHED:
    case types.SOLD_CUSTOMERS_LOCATION_DETAILS_FETCH_ERROR:
    case types.TEAM_POLICY_SAVED:
    case types.TEAM_POLICY_SAVE_ERROR:
    case types.FB_MARKETPLACE_IMPORT_COMPLETE:
    case types.OEM_TEMPLATES_SAVED:
    case types.OEM_TEMPLATES_SAVE_ERROR:
    case types.LOCATION_GBM_STARTED:
    case types.LOCATION_GBM_START_ERROR:
    case types.LOCATION_GBM_REMOVED:
    case types.LOCATION_GBM_REMOVE_ERROR:
    case types.LOCATION_GBM_MESSAGE_TOGGLED:
    case types.LOCATION_GBM_MESSAGE_ERROR:
      return state.set('busy', false);
    case types.CHANGE_DATE_RANGE:
      return state.set('dateRange', Map(payload));
    case types.CRM_TEMPLATES_FETCHED:
      return state.mergeIn(['crmTemplates'], payload);
    default:
      return state;

      /* eslint-enable no-fallthrough */
  }
}
