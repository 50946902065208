import { Map, List, fromJS } from 'immutable';
import { keyIn } from 'businessLogic/util';
import { MessageResponseCodes, messageSendErrors } from 'redux/constants/messages';
import { SearchParams } from 'redux/constants/records';

export function hasConversation(state, channel) {
  return state.hasIn(['conversations', channel]);
}

export function addConversation(state, conversation) {
  // If we already have this conversation (most likely from current conversations polling), then only update certain fields
  if (hasConversation(state, conversation.get('channel'))) {
    // "pick" only the fields we care about merging
    const fieldsToMerge = conversation.filter(
      keyIn('lastMessageDate', 'startDate', 'type', 'userIds', 'allUserIds')
    );
    return state.mergeDeepIn(['conversations', conversation.get('channel')], fieldsToMerge);
  }

  // new conversation
  state = state.mergeIn(['conversations', conversation.get('channel')], conversation);
  return state.setIn(
    ['conversations', conversation.get('channel'), 'searchParams'],
    new SearchParams()
  );
}

export function getUnreadMessages(loggedInUserId, conversation) {
  return conversation
    .get('messages', Map())
    .filter(
      message =>
        message.get('userId') !== loggedInUserId &&
        message.get('category') !== 'presence' &&
        message.get('conversationChannel') === conversation.get('channel') &&
        message
          .get('readReceipts', List())
          .filter(readReceipt => readReceipt.get('userId') === loggedInUserId).size === 0
    );
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

/**
 * @param conversation
 */
export function getMinMessageId(conversation) {
  return conversation.get('messages', Map()).reduce((maxMessageId, message) => {
    if (maxMessageId === null) return message.get('id');
    return message.get('id') < maxMessageId ? message.get('id') : maxMessageId;
  }, null);
}

export const getErrorCode = error => {
  if (error && error.status) {
    switch (error.status) {
      case MessageResponseCodes.BadRequest:
        return messageSendErrors.BAD_REQUEST;
      case MessageResponseCodes.AuthError:
        return messageSendErrors.AUTH_ERROR;
      case MessageResponseCodes.NoMatchingConvo:
        return messageSendErrors.NO_MATCHING_CONVO;
      case MessageResponseCodes.SmsError:
        // 2 possible errors: need to check deeper for text match in errors array
        if (
          error.data &&
          error.data.errors &&
          error.data.errors[0].search('canceled') > -1
        ) {
          return messageSendErrors.SMS_REVOKED;
        }
        return messageSendErrors.SMS_NOT_CONFIRMED;
      case MessageResponseCodes.AlreadyEnded:
        return messageSendErrors.ALREADY_ENDED;
      case MessageResponseCodes.ValidationError:
        return messageSendErrors.VALIDATION_ERROR;
      case MessageResponseCodes.UnavailableForLegalReasons:
        return messageSendErrors.UNAVAILABLE_FOR_LEGAL_REASONS;
      case MessageResponseCodes.InternalServerError:
        return messageSendErrors.INTERNAL_SERVER_ERROR;
      default:
        return messageSendErrors.UNKNOWN_ERROR;
    }
  }

  return messageSendErrors.UNKNOWN_ERROR;
};

export const getOemSmsLimitError = errorMessage => {
  if (errorMessage === null) {
    return null;
  } else if (errorMessage.includes(messageSendErrors.NO_VISITOR_MESSAGE_PAST_72_HOURS)) {
    return messageSendErrors.NO_VISITOR_MESSAGE_PAST_72_HOURS;
  } else if (errorMessage.includes(messageSendErrors.AGENT_ATTEMPT_SEND_MORE_THAN_2_MESSAGES)) {
    return messageSendErrors.AGENT_ATTEMPT_SEND_MORE_THAN_2_MESSAGES;
  }
  return null;
};

export const conversationTypes = fromJS({
  cars_com: {
    key: 'cars_com',
    displayText: 'Cars.com',
    icon: 'https://s3.amazonaws.com/conversations-files/global/type_icons/cars.com.svg',
  },
  web: {
    key: 'web',
    displayText: 'Website',
    icon: 'https://s3.amazonaws.com/conversations-files/global/type_icons/web.svg',
  },
  sms: {
    key: 'sms',
    displayText: 'Text Message',
    icon: 'https://s3.amazonaws.com/conversations-files/global/type_icons/sms.svg',
  },
  google_my_business: {
    key: 'google_my_business',
    displayText: 'Google My Business',
    icon: 'https://s3.amazonaws.com/conversations-files/global/type_icons/google.svg',
  },
  facebook: {
    key: 'facebook',
    displayText: 'Facebook Messenger',
    icon:
        'https://s3.amazonaws.com/conversations-files/global/type_icons/facebook_messenger.svg',
  },
  facebook_marketplace: {
    key: 'facebook_marketplace',
    displayText: 'Facebook Marketplace',
    icon:
        'https://s3.amazonaws.com/conversations-files/global/type_icons/facebook_marketplace.svg',
  },
  google_business_messages: {
    key: 'google_business_messages',
    displayText: 'Google Business Messages',
    icon: 'https://s3.amazonaws.com/conversations-files/global/type_icons/google.svg',
  },
});
