import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteChildrenProps } from 'react-router';
import { loadFromCookie } from '../../businessLogic/cookieStorage';
import { ssoLogin } from 'redux/actions/usersActions';

export interface Props {
  ssoLogin: (token: string|null, id: number|null) => Promise<{ token: string; id: number }>;
}

interface State {
  token: string|null;
  id: number|null;
  loggingIn: boolean;
  loginError: boolean;
}

export function mapStateToProps(): any {
  return {};
}

export class SSOLogin extends React.Component<Props & RouteChildrenProps, State> {
  state = { loggingIn: false, loginError: false, token: null, id: null };

  componentDidMount(): void {
    const thisCookie = loadFromCookie();
    this.login(thisCookie.token, thisCookie.userId);
  }

  render() {
    return null;
  }

  login = async (token: string|null, id: number|null) => {
    if (this.state.loggingIn) return;

    this.setState({ loggingIn: true });

    try {
      await this.props.ssoLogin(token, id);
      this.props.history.push('/');
    } catch (e) {
      this.setState({ loginError: true, loggingIn: false });
      this.props.history.push('/auth0/unauthorized');
    }
  };
}

export default connect(mapStateToProps, { ssoLogin })(withRouter(SSOLogin as any) as any);

