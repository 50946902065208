import React from 'react';
import { MouseEvent } from 'react';
import dinosaur from 'images/dinosaur.gif';

interface Props {}
interface State {}

export default class Fallback extends React.Component<Props, State> {
  refresh(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    window.location.reload();
  }

  render() {
    return (
      <div className="error-fallback">
        <div>
          <img src={dinosaur} />
        </div>
        <div>
          <h3>Uh oh. Looks like something went wrong.</h3>
          <p>
            Our engineers now know what's going on and will get it fixed. If you just {' '}
            <a href="#" onClick={this.refresh}>
              refresh the page
            </a>, you can get back to work.
          </p>
        </div>
      </div>
    );
  }
}
