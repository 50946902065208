/**
 * Get all data from Cookies
 */
interface Cookie {
  userId: number | null;
  token: string | null;
}

export const loadFromCookie = (): Cookie => {

  const cookie = document?.cookie
    .split('; ')
    .filter(nmval => nmval.includes("=") && nmval.split("=")[1].length);

  let thisEnv = '';
  if (window.CONFIG && window.CONFIG.config) thisEnv = window.CONFIG.config.releaseStage;
  else if (process.env.APP_ENV) thisEnv = process.env.APP_ENV;

  if (thisEnv == 'prod') {
    thisEnv = 'production';
  }

  const userId = Number(cookie?.reverse()
    .find(
      row => row.startsWith(`${thisEnv}_conv_id=`)
    )?.split('=')[1]);

  const token = cookie?.reverse()
    .find(
      row => row.startsWith(`${thisEnv}_conv_token=`)
    )?.split('=')[1];

  if (userId && token) {
    return {
      userId,
      token,
    };
  }
  return {
    userId: null,
    token: null,
  };
};
