import { ThunkAction } from 'redux-thunk';
import Constants from '../../constants';
import { FetchConversationAgentTranscriptHistoriesResponse } from './types';

function fetchCustomerActiveCallCenterConversations({
  customerId,
}: {
  customerId: number;
}): ThunkAction<any, any, any, any> {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({
      type: Constants.CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_REQUESTED,
      payload: { customerId },
    });
    console.log(customerId);
    return APIFactory.getInstance(getState())
      .get(`/customer/${customerId}/all-active-conversations`)
      .then((response: FetchConversationAgentTranscriptHistoriesResponse) => {
        dispatch({
          type: Constants.CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_RESPONDED,
          payload: { customerId, response },
        });
      })
      .catch((errors: any) => {
        dispatch({
          type: Constants.CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_EXCEPTION,
          payload: { customerId, errors: Array.isArray(errors) ? errors : [errors] },
        });
      });
  };
}

export { fetchCustomerActiveCallCenterConversations };
