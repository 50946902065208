const keyName = 'Conversations';

export interface LocalStorageData {
  userId?: number;
  token?: string;
  selectedCustomerId?: number;
  [index: string]: any;
}

function isLocalStorageAvailable(): boolean {
  try {
    let storage = window.localStorage;
    let x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Get all data from localStorage
 */
export function loadFromStorage(): LocalStorageData {
  if (!isLocalStorageAvailable()) return {};

  try {
    const raw = window.localStorage.getItem(keyName);
    if (raw) {
      return JSON.parse(raw);
    }
  } catch (e) {
    // ignore
  }

  return {};
}

/**
 * Get a particular field from localStorage
 */
export function getFromStorage(field: string): any | void {
  if (!isLocalStorageAvailable()) return undefined;
  const existing = loadFromStorage();
  return existing[field];
}

export function addToStorage(data: any) {
  if (!isLocalStorageAvailable()) return;

  const existing = loadFromStorage();
  try {
    window.localStorage.setItem(keyName, JSON.stringify({ ...existing, ...data }));
  } catch (e) {
    // ignore
  }
}
