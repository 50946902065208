import * as types from 'redux/constants/actionTypes';
import assign from 'lodash.assign';
import { redirectToRoute, buildFormErrors } from 'businessLogic/util';
import { fetchCustomerLocations } from 'redux/actions/locationActions';
import { fetchCustomerTeams, fetchAllCustomerTeams } from 'redux/actions/teamActions';
import { fetchCustomerUsers } from 'redux/actions/usersActions';
import { fetchCustomerCRMs } from 'redux/actions/crmActions';
import { addErrorNotification } from 'redux/actions/notificationActions';
import { addToStorage, getFromStorage } from 'businessLogic/localStorage';


export function fetchUserCustomers() {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.USER_CUSTOMERS_FETCHING });

    const api = APIFactory.getInstance(getState());
    return api
      .get('/customers')
      .then(response => {
        dispatch({
          type: types.USER_CUSTOMERS_FETCHED,
          payload: { userCustomers: response.data.customers },
        });

        response.data.customers.forEach((customer) => {
          dispatch(fetchCustomerLocations(customer.id))
            .then(() => dispatch(fetchCustomerUsers(customer.id)))
            .then(() => dispatch(fetchAllCustomerTeams(customer.id)));
        });

        const customerId = response.data.customers[0]?.id;
        const prevSelectedCustomerId = getFromStorage('selectedCustomerId');
        if (prevSelectedCustomerId && prevSelectedCustomerId !== customerId) {
          dispatch(setSelectedCustomer(prevSelectedCustomerId));
        } else {
          dispatch(setSelectedCustomer(customerId));
        }
      })
      .catch(response => console.error(response));
  };
}

export function fetchAllCustomers() {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.ALL_CUSTOMERS_FETCHING });

    const api = APIFactory.getInstance(getState());
    return api
      .get('/admin/customer')
      .then(response => {
        dispatch({
          type: types.ALL_CUSTOMERS_FETCHED,
          payload: { customers: response.data.items },
        });
      })
      .catch(response => {
        dispatch({ type: types.ALL_CUSTOMERS_FETCH_ERROR, payload: { response } });
      });
  };
}

export function fetchCustomer(customerId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CUSTOMER_FETCHING });

    const api = APIFactory.getInstance(getState());
    return api
      .get(`/admin/customer/${customerId}`)
      .then(response => {
        dispatch({ type: types.CUSTOMER_FETCHED, payload: { customer: response.data } });
      })
      .catch(response => {
        dispatch({ type: types.CUSTOMER_FETCH_ERROR, payload: { response } });
      });
  };
}

export function setSelectedCustomer(customerId) {
  return dispatch => {
    dispatch({ type: types.SET_SELECTED_CUSTOMER, payload: { customerId } });

    return dispatch(fetchCustomerLocations(customerId)).then(() => {
      const teamFetch = dispatch(fetchCustomerTeams(customerId));
      const userFetch = dispatch(fetchCustomerUsers(customerId));
      const policyFetch = dispatch(fetchManagedChatPolicies(customerId));
      const crmFetch = dispatch(fetchCustomerCRMs(customerId));

      return Promise.all([teamFetch, userFetch, policyFetch, crmFetch]).then(() =>
        addToStorage({ selectedCustomerId: customerId })
      );
    });
  };
}

export function deactivateCustomer(customerId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CUSTOMER_DELETING });

    const api = APIFactory.getInstance(getState());
    return api
      .delete(`/admin/customer/${customerId}`)
      .then(response => {
        dispatch({ type: types.CUSTOMER_DELETED, payload: { customerId } });
      })
      .catch(response => {
        dispatch({ type: types.CUSTOMER_DELETE_ERROR, payload: { customerId, response } });
        dispatch(addErrorNotification('Error deactivating customer'));
      });
  };
}

export function updateCustomer({
  customerId,
  name,
  themeId,
  regionId,
  contactName,
  contactEmail,
  avatar,
  sendMidMonthReports,
  sendEndMonthReports,
  accountManagerEmail,
  automatedReportEmail,
}) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CUSTOMER_UPDATING });

    const api = APIFactory.getInstance(getState());

    const payload = {
      name,
      themeId,
      regionId,
      contactName,
      contactEmail,
      sendMidMonthReports,
      sendEndMonthReports,
      accountManagerEmail,
      automatedReportEmail,
    };
    const customerDataPromise = api
      .patch(`/admin/customer/${customerId}`, payload)
      .then(response =>
        dispatch({
          type: types.CUSTOMER_SAVED,
          payload: assign({}, payload, { id: customerId }),
        })
      )
      .catch(response => {
        throw buildFormErrors('Error saving customer information.', response);
      });

    let avatarPromise;
    if (avatar && avatar[0]) {
      avatarPromise = saveAvatar(api, dispatch, customerId, avatar).catch(response => {
        throw buildFormErrors('Error saving avatar.', response);
      });
    } else {
      avatarPromise = Promise.resolve();
    }

    return Promise.all([customerDataPromise, avatarPromise])
      .then(() => dispatch({ type: types.CUSTOMER_UPDATED }))
      .catch(error => {
        dispatch({ type: types.CUSTOMER_UPDATE_ERROR });
        return error;
      });
  };
}

export function moveLocationToCustomer(locationId, customerId) {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    dispatch({ type: types.LOCATION_UPDATING });
    return api
      .get(`admin/location/${locationId}/migrate/${customerId}`, { timeout: 60000 })
      .then(response => {
        dispatch({ type: types.LOCATION_UPDATED });

        if (response.data.response === 'success') {
          dispatch({
            type: types.CUSTOMER_LOCATION_MIGRATED,
            payload: { locationId, customerId },
          });

          if (response.data.customerRemoved === true) {
            dispatch(setSelectedCustomer(customerId));
          } else {
            dispatch({
              type: types.CUSTOMER_LOCATION_MIGRATED_AWAY,
              payload: { locationId },
            });
          }
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 504) {
          dispatch({ type: types.LOCATION_UPDATE_ERROR });
          dispatch(
            addErrorNotification(
              'Location move timed out. Refresh and check if the location has moved.'
            )
          );
        } else {
          let notification =
              'Error moving location, double check team and user assignments.';

          if (error.response && error.response.data) {
            if (
              Array.isArray(error.response.data.errors) &&
              error.response.data.errors.length > 0
            ) {
              notification = error.response.data.errors.join('\n');
            } else if (error.response.data.message) {
              notification = error.response.data.message;
            } else if (error.response.data.exception) {
              notification = error.response.data.exception;
            }
          } else if (error.message) {
            notification = error.message;
          }

          dispatch({ type: types.LOCATION_UPDATE_ERROR });
          dispatch(addErrorNotification(notification));
        }
      });
  };
}

export function moveUserToCustomer(userId, customerId) {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    dispatch({ type: types.USER_UPDATING });

    return api
      .get(`admin/user/${userId}/migrate/${customerId}`, { timeout: 60000 })
      .then(response => {
        dispatch({ type: types.USER_UPDATED });
        if (response.data.response === 'success') {
          dispatch({
            type: types.CUSTOMER_USER_MIGRATED,
            payload: { userId, customerId },
          });
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 504) {
          dispatch({ type: types.USER_UPDATE_ERROR });
          dispatch(
            addErrorNotification(
              'User move timed out. Refresh and check if the user has moved.'
            )
          );
        } else {
          let notification = 'Error moving user.';

          if (error.response && error.response.data) {
            if (
              Array.isArray(error.response.data.errors) &&
                error.response.data.errors.length > 0
            ) {
              notification = error.response.data.errors.join('\n');
            } else if (error.response.data.message) {
              notification = error.response.data.message;
            } else if (error.response.data.exception) {
              notification = error.response.data.exception;
            }
          } else if (error.message) {
            notification = error.message;
          }

          dispatch({ type: types.USER_UPDATE_ERROR });
          dispatch(addErrorNotification(notification));
        }
      });
  };
}

export function createCustomer({
  name,
  themeId,
  regionId,
  contactName,
  contactEmail,
  avatar,
  sendMidMonthReports,
  sendEndMonthReports,
  accountManagerEmail,
  automatedReportEmail,
}) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CUSTOMER_UPDATING });

    const payload = {
      name,
      themeId,
      regionId,
      contactName,
      contactEmail,
      sendMidMonthReports,
      sendEndMonthReports,
      accountManagerEmail,
      automatedReportEmail,
    };
    const api = APIFactory.getInstance(getState());

    let customerId;

    return api
      .post('/admin/customer', payload) // save customer
      .then(response => {
        customerId = response.data.id;
        dispatch({
          type: types.CUSTOMER_SAVED,
          payload: assign({}, payload, { id: customerId }),
        });
      })
      .then(() => {
        // save avatar if it exists
        if (avatar && avatar[0]) {
          return saveAvatar(api, dispatch, customerId, avatar);
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        dispatch({ type: types.CUSTOMER_UPDATED });
        redirectToRoute(`/admin/customer`);
      })
      .catch(response => {
        dispatch({ type: types.CUSTOMER_UPDATE_ERROR, payload: { response } });
        return buildFormErrors('Error creating customer.', response);
      });
  };
}

function saveAvatar(api, dispatch, customerId, avatar) {
  const data = new window.FormData();
  data.append('avatar', avatar[0]);
  return api.post(`/admin/customer/${customerId}/avatar`, data).then(response =>
    dispatch({
      type: types.CUSTOMER_AVATAR_SAVED,
      payload: { customerId, avatar: response.data.avatar },
    })
  );
}

export function removeAvatar(customerId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CUSTOMER_AVATAR_DELETING, payload: { customerId } });

    const api = APIFactory.getInstance(getState());
    return api
      .delete(`/admin/customer/${customerId}/avatar`)
      .then(response =>
        dispatch({ type: types.CUSTOMER_AVATAR_DELETED, payload: { customerId } })
      )
      .catch(response => {
        dispatch({
          type: types.CUSTOMER_AVATAR_DELETE_ERROR,
          payload: { customerId, response },
        });
        dispatch(addErrorNotification('Error deleting customer avatar.'));
      });
  };
}

export function fetchManagedChatPolicies(customerId) {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    return api.get(`/customer/${customerId}/managed-chat-policies`).then(response => {
      dispatch({
        type: types.CUSTOMER_MANAGED_CHAT_POLICIES_FETCHED,
        payload: { customerId, policies: response.data.policies },
      });
    });
  };
}

export function fetchCustomerUserStatus(customerId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CUSTOMER_USER_STATUS_FETCHING, payload: { customerId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`customer/${customerId}/agent/status`)
      .then(response =>
        dispatch({
          type: types.CUSTOMER_USER_STATUS_FETCHED,
          payload: { customerId, response: response.data },
        })
      )
      .catch(() =>
        dispatch({ type: types.CUSTOMER_USER_STATUS_FETCH_ERROR, payload: { customerId } })
      );
  };
}

export function fetchCustomerUserActiveChats(customerId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CUSTOMER_USER_ACTIVE_CHATS_FETCHING, payload: { customerId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`customer/${customerId}/active-conversations`)
      .then(response =>
        dispatch({
          type: types.CUSTOMER_USER_ACTIVE_CHATS_FETCHED,
          payload: { customerId, users: response.data.users },
        })
      )
      .catch(() =>
        dispatch({
          type: types.CUSTOMER_USER_ACTIVE_CHATS_FETCH_ERROR,
          payload: { customerId },
        })
      );
  };
}
