/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Map } from 'immutable';
import { get } from 'lodash';
import Constants from '../../constants';

const initialState: any = Map();

const cacheKey = (request: { dateStart: string; dateEnd: string; interactive: 'true' | null }) => {
  const { dateStart, dateEnd, interactive } = request;
  return JSON.stringify({ dateStart, dateEnd, interactive });
};

export default (state = initialState, { payload, type }: any) => {

  switch (type) {
    case Constants.REPORT_CALL_CENTER_CANCELLED:
      return state.setIn([`${cacheKey(payload.request)}`, 'loading'], false);

    case Constants.REPORT_CALL_CENTER_EXCEPTION:
      return state
        .setIn([`${cacheKey(payload.request)}`, 'errors'], payload.errors)
        .setIn([`${cacheKey(payload.request)}`, 'loading'], true);

    case Constants.REPORT_CALL_CENTER_REQUESTED:
      return state
        .setIn([`${cacheKey(payload.request)}`, 'data'], state.getIn([`${cacheKey(payload.request)}`, 'data'], null))
        .setIn([`${cacheKey(payload.request)}`, 'errors'], [])
        .setIn([`${cacheKey(payload.request)}`, 'loading'], true);

    case Constants.REPORT_CALL_CENTER_RESPONDED:
      return state
        .setIn([`${cacheKey(payload.request)}`, 'data'], get(payload, 'response.data'))
        .setIn([`${cacheKey(payload.request)}`, 'errors'], [])
        .setIn([`${cacheKey(payload.request)}`, 'loading'], false);

    default:
      return state;
  }
};
