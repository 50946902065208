import { Map, fromJS, List, Set } from 'immutable';
import * as types from 'redux/constants/actionTypes';
import { mapPolicies, mapDealerMessages } from 'businessLogic/util';
import { addCustomer, addLocationToCustomer } from 'businessLogic/customerHelper';
import { addOrUpdateLocation } from 'businessLogic/locationHelper';

export const DEFAULT_THEME_ID = 1;
export const DI_CUSTOMER_ID = 200;

const initialState = Map({
  allCustomersFetching: false,
  customerFetching: false,
  selectedCustomerId: null,
  initialFetchComplete: false,
  customers: Map(),
});

export default function config(state = initialState, { type, payload }) {
  switch (type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.CURRENT_CONVERSATIONS_FETCHED:
      return currentConversationsFetched(state, payload);
    case types.USER_OVERVIEW_FETCHED:
      return userOverviewFetched(state, payload);

    case types.SET_SELECTED_CUSTOMER:
      return state.set('selectedCustomerId', payload.customerId);

    // fetch all customers (super admin)
    case types.ALL_CUSTOMERS_FETCHING:
      return state.set('allCustomersFetching', true);
    case types.ALL_CUSTOMERS_FETCHED:
      return allCustomersFetched(state, payload);
    case types.ALL_CUSTOMERS_FETCH_ERROR:
      return state.set('allCustomersFetching', false);

    // fetch single customer (non super-admins)
    case types.CUSTOMER_FETCHING:
      return state.set('customerFetching', true);
    case types.CUSTOMER_FETCHED:
      return customerFetched(state, payload);
    case types.CUSTOMER_FETCH_ERROR:
      return state.set('customerFetching', false);

    // fetch policies
    case types.CUSTOMER_MANAGED_CHAT_POLICIES_FETCHED:
      return managedChatPoliciesFetched(state, payload);
    case types.CUSTOMER_MANAGED_CHAT_POLICY_DELETED:
      return managedChatPolicyDeleted(state, payload);
    case types.CUSTOMER_MANAGED_CHAT_POLICY_SAVED:
      return managedChatPolicySaved(state, payload);

    // fetch call center messqges
    case types.CUSTOMER_DEALER_MESSAGES_FETCHED:
      return dealerMessagesFetched(state, payload);
    case types.CUSTOMER_DEALER_MESSAGE_DELETED:
      return dealerMessageDeleted(state, payload);
    case types.CUSTOMER_DEALER_MESSAGE_SAVED:
      return dealerMessageSaved(state, payload);

    // fetch locations
    case types.CUSTOMER_LOCATIONS_FETCHED:
      return customerLocationsFetched(state, payload);

    // reports
    case types.CUSTOMER_DASHBOARD_FETCHING:
      return state.setIn(['customers', payload.customerId, 'dashboardFetching'], true);
    case types.CUSTOMER_DASHBOARD_FETCHED:
    case types.CUSTOMER_DASHBOARD_FETCH_ERROR:
      return state.setIn(['customers', payload.customerId, 'dashboardFetching'], false);
    case types.CUSTOMER_LOCATION_MIGRATED:
      return addOrUpdateLocation(state, {
        id: payload.locationId,
        customerId: payload.customerId,
      });
    case types.CUSTOMER_LOCATION_MIGRATED_AWAY:
      return locationDeleted(state, payload);
    // CRUD
    case types.CUSTOMER_DELETED:
      return state.deleteIn(['customers', payload.customerId]);
    case types.CUSTOMER_SAVED:
      return customerSaved(state, payload);
    case types.CUSTOMER_AVATAR_SAVED:
      return state.setIn(['customers', payload.customerId, 'avatar'], payload.avatar);
    case types.CUSTOMER_AVATAR_DELETED:
      return state.deleteIn(['customers', payload.customerId, 'avatar']);
    case types.LOCATION_DELETED:
      return locationDeleted(state, payload);
    case types.LOCATION_SAVED:
      return locationSaved(state, payload);
    default:
      return state;

    // user customers
    case types.USER_CUSTOMERS_FETCHING:
      return state.set('userCustomersFetching', true);
    case types.USER_CUSTOMERS_FETCHED:
      return userCustomersFetched(state, payload);
  }
}

function currentConversationsFetched(state, payload) {
  const conversations = payload.conversations.concat(payload.internalConversations);

  conversations.forEach(data => {
    const location = data.location;
    state = addCustomer(state, { id: location.customerId, locationIds: [data.location.id] });
  });

  return state;
}

function userOverviewFetched(state, payload) {
  payload.locations.forEach(location => {
    state = addCustomer(state, { id: location.customerId });
    state = addLocationToCustomer(state, location.customerId, location.id);
  });

  return state;
}

export function allCustomersFetched(state, { customers }) {
  return state.withMutations(state => {
    state.set('allCustomersFetching', false);
    state.set('initialFetchComplete', true);
    customers.forEach(customer => state.mergeIn(['customers', customer.id], Map(customer)));
  });
}

export function customerFetched(state, { customer }) {
  state = state.set('customerFetching', false);
  state = state.set('initialFetchComplete', true);
  return state.mergeIn(['customers', customer.id], Map(customer));
}

function customerLocationsFetched(state, { customerId, locations }) {
  return state.setIn(
    ['customers', customerId, 'locationIds'],
    Set(locations.map(location => location.id))
  );
}

function customerSaved(
  state,
  {
    id,
    name,
    themeId,
    regionId,
    contactName,
    contactEmail,
    sendMidMonthReports,
    sendEndMonthReports,
    accountManagerEmail,
  }
) {
  return state.mergeIn(
    ['customers', id],
    Map({
      id,
      name,
      themeId,
      regionId,
      contactName,
      contactEmail,
      sendMidMonthReports,
      sendEndMonthReports,
      accountManagerEmail,
    })
  );
}

/**
 * delete all references to this locationId from customer.locationIds
 */
function locationDeleted(state, { locationId }) {
  return state.withMutations(state => {
    state.set(
      'customers',
      state.get('customers').map(customer => {
        if (!customer.get('locationIds')) return customer;
        return customer.set('locationIds', customer.get('locationIds').delete(locationId));
      })
    );
  });
}

function locationSaved(state, { customerId, id: locationId }) {
  if (!state.hasIn(['customers', customerId])) return state;
  const currentLocations = state.getIn(['customers', customerId, 'locationIds'], Set());
  return state.setIn(['customers', customerId, 'locationIds'], currentLocations.add(locationId));
}

function managedChatPoliciesFetched(state, { customerId, policies }) {
  if (!state.hasIn(['customers', customerId])) return state;
  return state.setIn(['customers', customerId, 'managedChatPolicies'], mapPolicies(policies));
}

function managedChatPolicyDeleted(state, { customerId, policyId }) {
  if (!state.hasIn(['customers', customerId])) return state;

  const currentPolicies = state.getIn(['customers', customerId, 'managedChatPolicies'], List());
  const index = currentPolicies.findIndex(policy => policy.get('id') === policyId);

  if (index !== -1) {
    return state.setIn(
      ['customers', customerId, 'managedChatPolicies'],
      currentPolicies.delete(index)
    );
  } else {
    return state.setIn(['customers', customerId, 'managedChatPolicies'], currentPolicies);
  }
}

function managedChatPolicySaved(state, { customerId, policyData }) {
  if (!state.hasIn(['customers', customerId])) return state;

  const currentPolicies = state.getIn(['customers', customerId, 'managedChatPolicies'], List());
  const index = currentPolicies.findIndex(policy => policy.get('id') === policyData.id);

  if (index === -1) {
    return state.setIn(
      ['customers', customerId, 'managedChatPolicies'],
      currentPolicies.unshift(fromJS(policyData))
    );
  } else {
    const updatedPolicy = currentPolicies.set(
      index,
      currentPolicies.get(index).merge(fromJS(policyData))
    );
    return state.setIn(['customers', customerId, 'managedChatPolicies'], updatedPolicy);
  }
}

function dealerMessagesFetched(state, { customerId, messages }) {
  if (!state.hasIn(['customers', customerId])) return state;
  return state.setIn(['customers', customerId, 'dealerMessages'], mapDealerMessages(messages));
}

function dealerMessageDeleted(state, { customerId, messageId }) {
  if (!state.hasIn(['customers', customerId])) return state;

  const currentMessages = state.getIn(['customers', customerId, 'dealerMessages'], List());
  const index = currentMessages.findIndex(message => message.get('id') === messageId);

  if (index !== -1) {
    return state.setIn(
      ['customers', customerId, 'dealerMessages'],
      currentMessages.delete(index)
    );
  } else {
    return state.setIn(['customers', customerId, 'dealerMessages'], currentMessages);
  }
}

function dealerMessageSaved(state, { customerId, messageData }) {
  if (!state.hasIn(['customers', customerId])) return state;

  const currentMessages = state.getIn(['customers', customerId, 'dealerMessages'], List());
  const index = currentMessages.findIndex(message => message.get('id') === messageData.id);

  if (index === -1) {
    return state.setIn(
      ['customers', customerId, 'dealerMessages'],
      currentMessages.push(fromJS(messageData))
    );
  } else {
    return state.setIn(
      ['customers', customerId, 'dealerMessages'],
      currentMessages.set(index, fromJS(messageData))
    );
  }
}

function userCustomersFetched(state, { userCustomers }) {
  state.set('userCustomersFetching', false);
  return state.set('userCustomers', userCustomers);
}

function setSelectedUserCustomers(state, payload) {
  return state.set('selectedUserCustomersId', payload);
}
