export const locationOwnerNames = ['Dealer Inspire', 'Cars.com'];

export const locationTypeNames = [
  'Website',
  'Facebook Marketplace',
  'Online Shopper',
  'Conversations Starter Package',
  'Lotlinx',
];

export const directToFlowNames = [
  {name: '', value: ''},
  {name: 'Trade In', value: 'tradeIn'},
  {name: 'Offer', value: 'offer'},
];

export enum LocationOwner {
  DEALER_INSPIRE = 1,
  CARS_COM = 2,
}

export enum LocationType {
  WEBSITE = 1,
  FACEBOOK_MARKETPLACE = 2,
  ONLINE_SHOPPER = 3,
  CONVERSATIONS_STARTER_PACKAGE = 4,
  LOTLINX = 5,
}
