/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Dispatch, Action } from 'redux';
import * as actionTypes from 'redux/constants/actionTypes';
import { isSupported } from 'twilio-video';
import { getChromeExtensionConfig } from 'selectors/configSelectors';
import { hideExternalNewMessageNotifications } from 'redux/actions/externalNotificationActions';
const has = require('lodash.has');

declare global {
  interface Window {
    MyNamespace: any;
    chrome: any;
  }
}

interface ConfigDataObject {}
interface DispatchObject {}

interface ReplyObject {
  version: string;
}
interface StateObject {}

export function setConfigFromPage(data: ConfigDataObject) {
  return { type: actionTypes.SET_CONFIG_FROM_PAGE, payload: data };
}

export function getBrowserConfig() {
  return (dispatch: Dispatch<Action<DispatchObject>>, getState: () => StateObject) => {
    const isChromeBrowser = hasChromeBrowser();
    const isSupportedBrowser = hasSupportedBrowser();

    const webRtcSupport = isSupported;

    const payload = { isChromeBrowser, isSupportedBrowser, webRtcSupport };

    if (isChromeBrowser && has(window, 'chrome.runtime.sendMessage')) {
      const extensionId = getChromeExtensionConfig(getState()).get('id');
      const minVersion = getChromeExtensionConfig(getState()).get('minVersion');

      // ask the extension for its version and check compatibility with minVersion
      window.chrome.runtime.sendMessage(
        extensionId,
        { action: 'version', apiVersion: 2 },
        (reply: ReplyObject) => {
          if (reply && reply.version) {
            dispatch({
              type: actionTypes.BROWSER_CONFIG_FETCHED,
              payload: Object.assign(payload, {
                extensionInstalled: reply.version >= minVersion,
                extensionVersion: reply.version,
              }),
            });
          } else {
            dispatch({
              type: actionTypes.BROWSER_CONFIG_FETCHED,
              payload: Object.assign(payload, {
                extensionInstalled: false,
              }),
            });
          }
        }
      );
    } else {
      dispatch({ type: actionTypes.BROWSER_CONFIG_FETCHED, payload });
    }
  };
}

function hasSupportedBrowser() {
  if (!window.navigator || !window.navigator.userAgent) {
    return false;
  }
  // This is to prevent IE, version 11's UA is "Trident"
  return !window.navigator.userAgent.match(/(MSIE|Trident)/);
}

/**
 * http://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
 */
function hasChromeBrowser() {
  if (!window.navigator || !window.navigator.userAgent) {
    return false;
  }

  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = winNav.userAgent.indexOf('OPR') > -1;
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;

  if (
    isChromium !== null &&
        isChromium !== undefined &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
  ) {
    return true;
  } else {
    return false;
  }
}

export function onWindowFocusChange(isFocused: boolean) {
  // return (dispatch: Dispatch<DispatchObject>) => {
  // NOTE: Dispatch typedef does not allow us to send a function as a parameter below
  return (dispatch: any) => {
    if (isFocused) dispatch(hideExternalNewMessageNotifications());
    dispatch({ type: actionTypes.WINDOW_FOCUS_CHANGE, payload: { isFocused } });
  };
}

export function onConnectionChange(isOnline: boolean) {
  return (dispatch: Dispatch<Action<DispatchObject>>) => {
    dispatch({ type: actionTypes.CONNECTION_STATUS_CHANGE, payload: { isOnline } });
  };
}
