import Root from 'components/Root';
import { connect } from 'react-redux';
import { setConfigFromPage } from 'redux/actions/configActions';
import { setUserToken, ejectUser } from 'redux/actions/usersActions';
import { fetchFeatures } from 'redux/Feature';
import {MappedFeatures} from "redux/Feature/types";
import { getLoggedInUser } from 'selectors/userSelectors';

const mapStateToProps = (state: { features: MappedFeatures }): any => {
  let configData;

  const user = getLoggedInUser(state);
  const userEmail = user ? user.get('email') : undefined;

  if (window.CONFIG) {
    // production web app gets config from values injected at run-time by laravel (which reads from .env file)
    configData = window.CONFIG.config;
  } else {
    // development web app and electron bot get config from values injected by webpack (which reads from .env file) at build-time
    configData = {
      api: { baseUrl: process.env.API_BASE_URI },
      pusher: { key: process.env.PUSHER_KEY, cluster: process.env.PUSHER_CLUSTER },
      google: { key: process.env.GOOGLE_API_KEY },
      bugsnag: { key: process.env.BUGSNAG_JAVASCRIPT_API_KEY },
      split: { authorizationKey: process.env.SPLIT_KEY },
      releaseStage: process.env.APP_ENV,
      releaseVersion: process.env.RELEASE_VERSION,
    };
  }

  configData.auth0 = {
    enabled: state.features.get('auth0', {status: false}).status,
    loginUrl: new URL('/auth0/login', configData.api.baseUrl).toString(),
    logoutUrl: new URL('/auth0/logout', configData.api.baseUrl).toString(),
    action: document.location.hash.includes('#logged-out') ? 'return-logout' : null,
  };

  return { configData, userEmail };
};

const mapDispatchToProps = {
  setConfigFromPage,
  setUserToken,
  fetchFeatures,
  ejectUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root as any);
