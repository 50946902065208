import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { THEME_DEALER, THEME_CALL_CENTER, getFullName } from 'businessLogic/userHelper';
import { makeSortByStringKey } from 'businessLogic/util';
import { getSelectedCustomerId } from './customerSelectors';
import { loadFromCookie } from '../businessLogic/cookieStorage';
import { DYNAMIC_BDC_ID, CARCHAT24_ID, BETA_BDC_ID } from 'businessLogic/customerHelper';

export const getLoggedInUserId = state => {
  const idFromState = state.users ? state.users.getIn(['loggedInUser', 'id']) : undefined;
  if (!idFromState) {
    const thisCookie = loadFromCookie();
    return thisCookie.userId;
  }
  return idFromState;
};


// export const getLoggedInUserId = state =>
//   state.users ? state.users.getIn(['loggedInUser', 'id']) : undefined;
export const getLoggedInUserData = state =>
  state.users ? state.users.get('loggedInUser') : undefined;
export const getAllUsers = state => (state.users ? state.users.get('users') : Map());
export const isInitialUserOverviewFetched = state => state.users.get('overviewFetched');
export const areHotkeysFetched = state => state.users.get('hotkeysFetched');
export const isUserProfileFetched = state => state.users.get('profileFetched');

export const getLoggedInUser = createSelector(
  [getLoggedInUserId, getAllUsers],
  (activeUserId, users) => users.get(activeUserId)
);

export const getTheme = createSelector(
  [getSelectedCustomerId],
  customerId => [DYNAMIC_BDC_ID, CARCHAT24_ID, BETA_BDC_ID].includes(customerId) ? THEME_CALL_CENTER : THEME_DEALER
);

export const getLoggedInUserFullName = createSelector([getLoggedInUser], user => getFullName(user));

export const getActiveHotkey = state =>
  state.users ? state.users.getIn(['loggedInUser', 'activeHotkey']) : undefined;

export const isStartingAgentChat = state =>
  state.users.getIn(['loggedInUser', 'isStartingAgentChat']);

export const getSelectedCustomerUsers = createSelector(
  [getSelectedCustomerId, getAllUsers],
  (customerId, users) =>
    users
      .sort(makeSortByStringKey('lastName'))
      .sort(makeSortByStringKey('firstName'))
);

export const getAllCustomerUserStatus = state => state.users.get('customerUserStatus');

export const getCustomerUserStatus = createSelector(
  [getSelectedCustomerId, getAllCustomerUserStatus],
    (customerId, users) => users?.get(customerId)
);
