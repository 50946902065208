import { Map, Set } from "immutable";
import { makeGetBooleanSetting } from "./util";
import { LocationOwner } from "../redux/constants/locationConstants";

/**
 *
 * @param {string} prefix
 * @param {object} locationData.settings
 * @returns {object}
 */
export const getSettingsWithPrefix = (prefix, settings) => {
  const mappedSettings = {};
  for (const [key, value] of Object.entries(settings)) {
    if (key.startsWith(prefix)) {
      mappedSettings[key] = value;
    }
  }
  return mappedSettings;
};

/**
 * Add or update a location in the store
 * @param state
 * @param {object} locationData
 * @returns {object} state
 */
export function addOrUpdateLocation(state, locationData) {
  const locationId = locationData.id;
  if (!hasLocation(state, locationData.id)) {
    const baseLocation = Map({ teamIds: Set() });
    state = state.setIn(["locations", locationData.id], baseLocation);
  }

  let newInfo = Map({
    id: locationData.id,
    customerId: locationData.customerId,
    name: locationData.name,
    fullName: locationData.fullName,
    timezone: locationData.timezone,
    url: locationData.url,
    email: locationData.contactEmail,
    contactName: locationData.contactName,
    phone: locationData.phoneNumber,
    address: locationData.address,
    city: locationData.city,
    state: locationData.state,
    country: locationData.country,
    postcode: locationData.postcode,
    smsPhoneNumber: locationData.smsPhoneNumber,
    diDashboardKey: locationData.diDashboardKey,
    slug: locationData.slug,
    guid: locationData.guid,
    offlineMessage: locationData.offlineMessage,
    youtubeChannelId: locationData.youtubeChannelId,
    billable: locationData.billable,
    facebookPageId: locationData.facebookPageId,
    facebookPageAccessToken: locationData.facebookPageAccessToken,
    gaPropertyId: locationData.gaPropertyId,
    hasDms: locationData.hasDms,
    ccid: locationData.ccid,
    engineId: locationData.engineId,
    carsDealerId: locationData.carsDealerId,
    locationOwnerId:
      locationData.ownerId !== undefined
        ? locationData.ownerId
        : locationData.locationOwnerId,
    locationTypeId:
      locationData.typeId !== undefined
        ? locationData.typeId
        : locationData.locationTypeId,
    oemId: locationData.oemId,
    gbmEnabled: locationData.gbmEnabled,
    googlePlaceId: locationData.googlePlaceId,
    googlePlaceName: locationData.googlePlaceName,
    ein: locationData.ein,
    businessLegalName: locationData.businessLegalName,
    twilioProfile: locationData.twilioProfile,
    twilioProfileStatus: locationData.twilioProfileStatus,
    twilioBrand: locationData.twilioBrand,
    twilioBrandStatus: locationData.twilioBrandStatus,
    twilioCampaign: locationData.twilioCampaign,
    twilioCampaignStatus: locationData.twilioCampaignStatus,
    twilioLastSubmittedAt: locationData.twilioLastSubmittedAt,
    twilioMessagingServiceId: locationData.twilioMessagingServiceId,
    twilioStatus: locationData.twilioStatus,
    twilioFailureReason: locationData.twilioFailureReason,
  });

  // only overwrite teamIds if the payload includes teamIds (or teams), otherwise leave them alone
  if (locationData.teams) {
    newInfo = newInfo.set(
      "teamIds",
      Set(locationData.teams.map((team) => team.id))
    );
  } else if (locationData.teamIds) {
    newInfo = newInfo.set("teamIds", Set(locationData.teamIds));
  }

  // only overwrite avatar if the payload includes it, otherwise leave old values
  if (locationData.avatar) {
    newInfo = newInfo.merge(
      Map({ avatar: locationData.avatar, avatarType: locationData.avatarType })
    );
  }

  if (locationData.settings) {
    const settings = locationData.settings;
    const getBoolSetting = makeGetBooleanSetting(settings);
    newInfo = newInfo.merge(
      Map({
        bypassCustomizePanel: getBoolSetting("bypassCustomizePanel"),
        defaultFilterKeyword: settings.defaultFilterKeyword,
        enableManagedChat: getBoolSetting("enableManagedChat"),
        enableManagedOverage: getBoolSetting("enableManagedOverage"),
        managedAllotment: settings.managedAllotment || 0,
        unlimitedManaged: getBoolSetting("unlimitedManaged"),
        osEnabled: getBoolSetting("osEnabled"),
        maxWaitSeconds: settings.maxWaitSeconds,
        offlineAction: settings.offlineAction,
        requiredFields: settings.requiredFields,
        videoEnabled: getBoolSetting("videoEnabled", true),
        locale: settings.locale,
        sendAllLeads: getBoolSetting("sendAllLeads"),
        isVistaDash: getBoolSetting("isVistaDash"),
        isClarivoy: getBoolSetting("isClarivoy"),
        fbMessengerEnabled: getBoolSetting(
          "fbMessengerEnabled",
          locationData.ownerId !== LocationOwner.CARS_COM
        ),
        fbMarketplaceEnabled: getBoolSetting("fbMarketplaceEnabled", true),
        fbMarketplaceInventoryEnabled: getBoolSetting(
          "fbMarketplaceInventoryEnabled",
          false
        ),
        isTradePendingEnabled: getBoolSetting("isTradePendingEnabled", false),
        isGptEnabled: getBoolSetting("isGptEnabled", false),
        logUserStatus: getBoolSetting("logUserStatus"),
        directToFlow: settings.directToFlow,
        gbmEnabled: settings.gbmEnabled,
        googlePlaceId: settings.googlePlaceId,
        googlePlaceName: settings.googlePlaceName,
        oemDealerType: settings.oemDealerType,
        oemDealerCode: settings.oemDealerCode,
        oemLocationCode: settings.oemLocationCode,
        oemLocationOSSSubscriber: getBoolSetting("oemLocationOSSSubscriber"),
        inventoryDealerId: settings.inventoryDealerId,
        inventoryApiId: settings.inventoryApiId,
        cdkWebsiteId: settings.cdkWebsiteId,
        "embed.ctaType": settings["embed.ctaType"],
        "embed.ctaText": settings["embed.ctaText"],
        "embed.ctaPosition": settings["embed.ctaPosition"],
        "embed.ctaBackgroundColor": settings["embed.ctaBackgroundColor"],
        "embed.ctaForegroundColor": settings["embed.ctaForegroundColor"],
        "embed.ctaCustomImage": settings["embed.ctaCustomImage"],
        "embed.ctaTailBackgroundColor":
          settings["embed.ctaTailBackgroundColor"],
        "embed.ctaTailForegroundColor":
          settings["embed.ctaTailForegroundColor"],
        "embed.accentColor1": settings["embed.accentColor1"],
        "embed.accentColor2": settings["embed.accentColor2"],
        "embed.messageListType": settings["embed.messageListType"],
        "embed.useBot": getBoolSetting("embed.useBot", true),
        "embed.proactiveTimer": settings["embed.proactiveTimer"],
        "embed.ctaBottomMargin": settings["embed.ctaBottomMargin"],
        "embed.zIndex": settings["embed.zIndex"],
        "embed.surveyType": settings["embed.surveyType"],
        "gloveCompartment.hideStandardVehicle": getBoolSetting(
          "gloveCompartment.hideStandardVehicle"
        ),
        "gloveCompartment.hideCarsComVehicle": getBoolSetting(
          "gloveCompartment.hideCarsComVehicle"
        ),
        "gloveCompartment.hideIncentive": getBoolSetting(
          "gloveCompartment.hideIncentive"
        ),
        "gloveCompartment.hideYoutube": getBoolSetting(
          "gloveCompartment.hideYoutube"
        ),
        "gloveCompartment.hideUploadedVideo": getBoolSetting(
          "gloveCompartment.hideUploadedVideo"
        ),
        "gloveCompartment.hideCalculator": getBoolSetting(
          "gloveCompartment.hideCalculator"
        ),
        "gloveCompartment.hideAccolade": getBoolSetting(
          "gloveCompartment.hideAccolade"
        ),
        "gloveCompartment.hideBrochure": getBoolSetting(
          "gloveCompartment.hideBrochure"
        ),
        "misc.disabledOutboundMessaging": getBoolSetting(
          "misc.disabledOutboundMessaging"
        ),
        ...getSettingsWithPrefix("embedv4", settings),
      })
    );
  }
  return state.mergeIn(["locations", locationId], newInfo);
}

export function hasLocation(state, locationId) {
  return state.hasIn(["locations", locationId]);
}

const addressIsEmpty = (location) => {
  if (location.get("address")) return false;
  if (location.get("city")) return false;
  if (location.get("state")) return false;
  if (location.get("zipcode")) return false;

  return true;
};

/**
 * Get a string representation of a location's address for use with Google Maps
 * @param {Map} location
 * @returns {string|null}
 */
export const getMappableAddress = (location) => {
  if (!location) return null;
  if (addressIsEmpty(location)) return null;
  return `${location.get("address", "")}, ${location.get(
    "city",
    ""
  )}, ${location.get("state", "")} ${location.get("postcode", "")}`.trim();
};
