import { Map } from 'immutable';
import moment from 'moment';
import includes from 'lodash.includes';
import * as types from '../constants/actionTypes';
import { AWAY, DO_NOT_DISTURB } from '../reducers/statuses';

// key = channelName
const initialState = Map({
  isFetching: false,
  initialFetchCompleted: false,
  offers: Map(),
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.PUSHER_VISITOR_WAITING:
      return pusherVisitorWaiting(state, action.payload);
    case types.PUSHER_TRANSFER_WAITING:
      return pusherTransferWaiting(state, action.payload);
    case types.PUSHER_OFFER_REVOKED:
      return deleteOffer(state, action.payload.channelName);
    case types.ACCEPTING_OFFER:
      return state.setIn(['offers', action.payload.channel, 'isAccepting'], true);
    case types.ACCEPTED_OFFER:
      return deleteOffer(state, action.payload.channel);
    case types.ACCEPTING_OFFER_ERROR:
      return deleteOffer(state, action.payload);
    case types.OFFERS_FETCHED:
      return offersFetched(state, action.payload);
    case types.INITIAL_OFFER_FETCH_COMPLETED:
      return state.set('initialFetchCompleted', true);
    default:
      return state;
  }
}

function pusherVisitorWaiting(state, payload) {
  if (!payload.visitorChannel) return state;

  return state.setIn(
    ['offers', payload.visitorChannel],
    Map({
      pusherPayload: payload, // debug purposes only
      channel: payload.visitorChannel,
      type: 'visitor',
      conversationType: payload.conversationType,
      isVideo: !!payload.videochat,
      locationId: payload.location ? payload.location.id : null,
      teamId: payload.team ? payload.team.id : null,
      visitorId: payload.visitor ? payload.visitor.id : null,
      siteSessionStartedDate: moment(payload.sessionStarted),
      startDate: moment(payload.conversationStarted),
      offerDate: moment(),
      initialQuestion: payload.initialQuestion,
      isPriority: payload.isPriority,
    })
  );
}

function pusherTransferWaiting(state, payload) {
  return state.setIn(
    ['offers', payload.channel],
    Map({
      pusherPayload: payload, // debug purposes only
      channel: payload.channel,
      type: 'transfer',
      conversationType: payload.conversationType,
      isVideo: false,
      locationId: payload.location ? payload.location.id : null,
      visitorId: payload.visitor ? payload.visitor.id : null,
      siteSessionStartedDate: moment(payload.sessionStarted),
      startDate: moment(payload.dateStarted),
      offerDate: moment(),
      fromUserId: payload.fromUser ? payload.fromUser.id : null,
      initialQuestion: payload.initialQuestion,
    })
  );
}

function deleteOffer(state, channel) {
  return state.deleteIn(['offers', channel]);
}

function offersFetched(state, { offers, statusId }) {
  state = addNewOffers(state, offers, statusId);
  state = removeStaleOffers(state, offers);

  return state;
}

function addNewOffers(state, offers, statusId) {
  offers.forEach(offer => {
    if (statusId === DO_NOT_DISTURB) return;
    if (statusId === AWAY && offer.type === 'visitor') return;

    state = state.setIn(
      ['offers', offer.channel],
      Map({
        apiPayload: offer, // debug purposes only
        channel: offer.channel,
        type: offer.type,
        conversationType: offer.conversationType,
        isVideo: !!offer.videochat,
        locationId: offer.location ? offer.location.id : null,
        teamId: offer.team ? offer.team.id : null,
        visitorId: offer.visitor ? offer.visitor.id : null,
        siteSessionStartedDate: moment(offer.sessionStartedDate),
        startDate: moment(offer.conversationStartedDate),
        offerDate: moment(offer.offerCreatedDate),
        fromUserId: offer.fromUser ? offer.fromUser.id : null,
        initialQuestion: offer.initialQuestion,
        isPriority: offer.isPriority,
      })
    );
  });

  return state;
}

/**
 * Remove offers from store if they aren't in the API offers list
 * @param state
 * @param {array} offers
 * @returns {*}
 */
function removeStaleOffers(state, offers) {
  const validOfferChannels = offers.map(offer => offer.channel);
  state.get('offers').forEach((offer, channel) => {
    if (!includes(validOfferChannels, channel)) {
      state = state.deleteIn(['offers', channel]);
    }
  });

  return state;
}
