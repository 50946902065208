import * as types from 'redux/constants/actionTypes';
import { getSelectedCustomerId } from 'selectors/customerSelectors';
import { addErrorNotification } from 'redux/actions/notificationActions';

/**
 * @param {int} customerId
 * @param {moment} [startDate]
 * @param {moment} [endDate]
 */
export function fetchCustomerDashboardData(customerId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CUSTOMER_DASHBOARD_FETCHING, payload: { customerId } });

    let apiUrl;
    if (startDate) {
      apiUrl = `/report/customer/${customerId}?startDate=${startDate.format()}&endDate=${endDate.format()}`;
    } else {
      apiUrl = `/report/customer/${customerId}`;
    }

    const api = APIFactory.getInstance(getState());
    return api
      .get(apiUrl)
      .then(response => {
        dispatch({
          type: types.CUSTOMER_DASHBOARD_FETCHED,
          payload: { customerId, data: response.data },
        });
      })
      .catch(response => {
        dispatch({
          type: types.CUSTOMER_DASHBOARD_FETCH_ERROR,
          payload: { customerId, response },
        });
      });
  };
}

/**
 * @param {int} locationId
 * @param {moment} [startDate]
 * @param {moment} [endDate]
 */
export function fetchLocationDashboardData(locationId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_DASHBOARD_FETCHING, payload: { locationId } });

    let apiUrl;
    if (startDate) {
      apiUrl = `/report/location/${locationId}?startDate=${startDate.format()}&endDate=${endDate.format()}`;
    } else {
      apiUrl = `/report/location/${locationId}`;
    }

    const api = APIFactory.getInstance(getState());
    return api
      .get(apiUrl)
      .then(response => {
        dispatch({
          type: types.LOCATION_DASHBOARD_FETCHED,
          payload: { locationId, data: response.data },
        });
      })
      .catch(response => {
        dispatch({
          type: types.LOCATION_DASHBOARD_FETCH_ERROR,
          payload: { locationId, response },
        });
      });
  };
}

/**
 * @param {int} teamId
 * @param {moment} [startDate]
 * @param {moment} [endDate]
 */
export function fetchTeamDashboardData(teamId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_DASHBOARD_FETCHING, payload: { teamId } });

    let apiUrl;
    if (startDate) {
      apiUrl = `/report/team/${teamId}?startDate=${startDate.format()}&endDate=${endDate.format()}`;
    } else {
      apiUrl = `/report/team/${teamId}`;
    }

    const api = APIFactory.getInstance(getState());
    return api
      .get(apiUrl)
      .then(response => {
        dispatch({
          type: types.TEAM_DASHBOARD_FETCHED,
          payload: { teamId, data: response.data },
        });
      })
      .catch(response => {
        dispatch({ type: types.TEAM_DASHBOARD_FETCH_ERROR, payload: { teamId, response } });
      });
  };
}

/**
 * @param {int} customerId
 * @param {moment} startDate
 * @param {moment} endDate
 */
export function fetchCustomerSummaryData(customerId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.CUSTOMER_SUMMARY_FETCHING, payload: { customerId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/report/summary/${customerId}`, {
        params: { startDate: startDate.format(), endDate: endDate.format() },
        timeout: 60000,
      })
      .then(response => {
        dispatch({
          type: types.CUSTOMER_SUMMARY_FETCHED,
          payload: { customerId, data: response.data },
        });
      })
      .catch(response => {
        dispatch({
          type: types.CUSTOMER_SUMMARY_FETCH_ERROR,
          payload: { customerId, response },
        });
      });
  };
}

/**
 * @param {int} locationId
 * @param {moment} startDate
 * @param {moment} endDate
 */
export function fetchLocationSummaryData(locationId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_SUMMARY_FETCHING, payload: { locationId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/report/summary/location/${locationId}`, {
        params: { startDate: startDate.format(), endDate: endDate.format() },
        timeout: 60000,
      })
      .then(response => {
        dispatch({
          type: types.LOCATION_SUMMARY_FETCHED,
          payload: { locationId, data: response.data },
        });
      })
      .catch(response => {
        dispatch({
          type: types.LOCATION_SUMMARY_FETCH_ERROR,
          payload: { locationId, response },
        });
      });
  };
}

/**
 * @param {int} locationId
 * @param {moment} startDate
 * @param {moment} endDate
 */
export function fetchTeamSummaryData(teamId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_SUMMARY_FETCHING, payload: { teamId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/report/summary/team/${teamId}`, {
        params: { startDate: startDate.format(), endDate: endDate.format() },
        timeout: 60000,
      })
      .then(response => {
        dispatch({
          type: types.TEAM_SUMMARY_FETCHED,
          payload: { teamId, data: response.data },
        });
      })
      .catch(response => {
        dispatch({
          type: types.TEAM_SUMMARY_FETCH_ERROR,
          payload: { teamId, response },
        });
      });
  };
}

/**
 * @param {int} userId
 * @param {int} customerId
 * @param {moment} startDate
 * @param {moment} endDate
 */
export function fetchAgentSummaryData(userId, customerId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.AGENT_SUMMARY_FETCHING, payload: { userId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/report/agent/${userId}/${customerId}`, {
        params: { startDate: startDate.format(), endDate: endDate.format() },
        timeout: 60000,
      })
      .then(response => {
        dispatch({
          type: types.AGENT_SUMMARY_FETCHED,
          payload: { userId, data: response.data },
        });
      })
      .catch(response => {
        dispatch({ type: types.AGENT_SUMMARY_FETCH_ERROR, payload: { userId, response } });
      });
  };
}

/**
 * @param {moment} startDate
 * @param {moment} endDate
 * @param {int} locationId
 * @param {int} page
 */
export function fetchConversationHistoryReportData(
  startDate,
  endDate,
  locationId,
  agentUserId,
  page = 1
) {
  return (dispatch, getState, { APIFactory }) => {
    const customerId = getSelectedCustomerId(getState());
    dispatch({ type: types.CONVO_HISTORY_REPORT_FETCHING, payload: { customerId } });
    const api = APIFactory.getInstance(getState());
    const payload = {
      customerId,
      page,
      perPage: 50,
      startDate: startDate.format(),
      endDate: endDate.format(),
    };
    if (locationId) {
      payload.locationId = locationId;
    }
    if (agentUserId) {
      payload.agentUserId = agentUserId;
    }

    return api
      .post('/report/leads/run', payload, { timeout: 60000 })
      .then(response => {
        dispatch({
          type: types.CONVO_HISTORY_REPORT_FETCHED,
          payload: { customerId, startDate, endDate, page, data: response.data },
        });
      })
      .catch(response => {
        dispatch({
          type: types.CONVO_HISTORY_REPORT_FETCH_ERROR,
          payload: { customerId, response },
        });
      });
  };
}

/**
 * @param {moment} startDate
 * @param {moment} endDate
 * @param {int} locationId
 * @param {string} [query]
 * @param {int} [page=1]
 */
export function fetchCrmAuditLogReportData(startDate, endDate, locationId, query, page = 1) {
  return (dispatch, getState, { APIFactory }) => {
    const customerId = getSelectedCustomerId(getState());
    dispatch({ type: types.CRM_AUDIT_REQUESTED });
    const api = APIFactory.getInstance(getState());
    const payload = {
      customerId,
      page,
      query,
      perPage: 50,
      startDate: startDate.format(),
      endDate: endDate.format(),
    };
    if (locationId) {
      payload.locationId = locationId;
    }

    return api
      .post('/report/crm-audit-log', payload)
      .then(response => {
        dispatch({ type: types.CRM_AUDIT_COMPLETED });
        return response.data;
      })
      .catch(error => {
        dispatch({ type: types.CRM_AUDIT_FAILED });
        let errorMessage;
        if (error || !error.response) {
          errorMessage = 'Unknown Error';
        } else {
          errorMessage =
              error.response.data && error.response.data.errors
                ? error.response.data.errors.join('. ')
                : error.response.statusText;
        }
        dispatch(
          addErrorNotification(
            `There was an error fetching the CRM Audit Log. (${errorMessage})`
          )
        );
        return {};
      });
  };
}

/**
 * @param {moment} startDate
 * @param {moment} endDate
 * @param {int} locationId
 * @param {int} page
 */
export function downloadConversationHistoryReport(startDate, endDate, locationId) {
  return (dispatch, getState, { APIFactory }) => {
    const customerId = getSelectedCustomerId(getState());
    dispatch({ type: types.CONVO_HISTORY_REPORT_CSV_FETCHING, payload: { customerId } });
    const api = APIFactory.getInstance(getState());
    const payload = {
      customerId,
      startDate: startDate.format(),
      endDate: endDate.format(),
    };
    if (locationId) {
      payload.locationId = locationId;
    }

    return api
      .post('/report/leads/download', payload, { timeout: 60000 })
      .then(response => {
        dispatch({
          type: types.CONVO_HISTORY_REPORT_CSV_FETCHED,
          payload: { customerId, startDate, endDate, data: response.data },
        });

        const a = document.createElement('a');
        document.body.appendChild(a); // Required in Firefox
        a.target = '_self'; // Required in Firefox
        a.textContent = 'download';
        a.download = 'conversations.csv';
        a.href = response.data.url;
        a.click();
        a.remove(); // Removes node from the DOM
      })
      .catch(response => {
        dispatch({
          type: types.CONVO_HISTORY_REPORT_CSV_FETCH_ERROR,
          payload: { customerId, response },
        });
      });
  };
}

/**
 * @param {int} customerId
 * @param {moment} startDate
 * @param {moment} endDate
 */
export function fetchSoldCustomersSummary(customerId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.SOLD_CUSTOMERS_SUMMARY_FETCHING, payload: { customerId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/report/matchback/customer/${customerId}/summary`, {
        params: { startDate: startDate.format(), endDate: endDate.format() },
      })
      .then(response => {
        dispatch({
          type: types.SOLD_CUSTOMERS_SUMMARY_FETCHED,
          payload: { customerId, response },
        });
        if (response.status === 204) return {};
        return response.data;
      })
      .catch(response => {
        dispatch({
          type: types.SOLD_CUSTOMERS_SUMMARY_FETCH_ERROR,
          payload: { customerId, response },
        });
        dispatch(addErrorNotification('Error fetching customer summary'));
        return {};
      });
  };
}

/**
 * @param {int} customerId
 * @param {moment} startDate
 * @param {moment} endDate
 */
export function fetchSoldCustomersDetails(customerId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.SOLD_CUSTOMERS_DETAILS_FETCHING, payload: { customerId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/report/matchback/customer/${customerId}/detail`, {
        params: { startDate: startDate.format(), endDate: endDate.format() },
      })
      .then(response => {
        dispatch({
          type: types.SOLD_CUSTOMERS_DETAILS_FETCHED,
          payload: { customerId, response },
        });
        if (response.status === 204) return [];
        return response.data;
      })
      .catch(response => {
        dispatch({
          type: types.SOLD_CUSTOMERS_DETAILS_FETCH_ERROR,
          payload: { customerId, response },
        });
        dispatch(addErrorNotification('Error fetching customer details'));
        return [];
      });
  };
}

/**
 * @param {int} locationId
 * @param {moment} startDate
 * @param {moment} endDate
 */
export function fetchSoldCustomersLocationSummary(locationId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCHING, payload: { locationId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/report/matchback/location/${locationId}/summary`, {
        params: { startDate: startDate.format(), endDate: endDate.format() },
      })
      .then(response => {
        dispatch({
          type: types.SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCHED,
          payload: { locationId, response },
        });
        if (response.status === 204) return {};
        return response.data;
      })
      .catch(response => {
        dispatch({
          type: types.SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCH_ERROR,
          payload: { locationId, response },
        });
        dispatch(addErrorNotification('Error fetching location summary'));
        return {};
      });
  };
}

/**
 * @param {int} locationId
 * @param {moment} startDate
 * @param {moment} endDate
 */
export function fetchSoldCustomersLocationDetails(locationId, startDate, endDate) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.SOLD_CUSTOMERS_LOCATION_DETAILS_FETCHING, payload: { locationId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/report/matchback/location/${locationId}/detail`, {
        params: { startDate: startDate.format(), endDate: endDate.format() },
      })
      .then(response => {
        dispatch({
          type: types.SOLD_CUSTOMERS_LOCATION_DETAILS_FETCHED,
          payload: { locationId, response },
        });
        if (response.status === 204) return [];
        return response.data;
      })
      .catch(response => {
        dispatch({
          type: types.SOLD_CUSTOMERS_LOCATION_DETAILS_FETCH_ERROR,
          payload: { locationId, response },
        });
        dispatch(addErrorNotification('Error fetching location details'));
        return [];
      });
  };
}

export function sendDmsDataRequest(data) {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    return api.post('/admin/email', data);
  };
}

export function fetchCallCenterBillingReport() {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    const customerId = getSelectedCustomerId(getState());
    return api.get(`/callcenter/billing/customer/${customerId}`);
  };
}
