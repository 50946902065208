import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

export interface Props {
  show?: boolean;
}

export interface State {
  show: boolean;
}

class LoggedOutAlert extends Component<Props> {
  state: State;

  public static propTypes = {
    show: PropTypes.bool,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  render(): JSX.Element {
    if (!isUndefined(this.props.show)) {
      this.state.show = this.props.show;
    }

    if (this.state.show === false) {
      return <></>;
    }

    /* a comment */
    return (
      <div id="auth0-logged-out-alert" className="alert alert-loggedout">
        Signed out successfully.
      </div>
    );
  }
}


export default LoggedOutAlert;
