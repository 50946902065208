import { Map, Set } from 'immutable';
import moment from 'moment';
import {
  mapHotkey,
  mapHotkeys,
  mapStarter,
  mapStarters,
  mapLink,
  mapLinks,
  mapBanners,
  mapBanner,
  mapFeeds,
  mapFeed,
  mapContent,
  mapContentList,
  mapVideo,
  mapVideos,
  mapFeedMappings,
} from 'businessLogic/util';
import * as types from 'redux/constants/actionTypes';
import { addOrUpdateLocation, hasLocation } from 'businessLogic/locationHelper';

export const LOCATION_OWNER = {
  DI: 1,
  CARS: 2,
};

export const LOCATION_TYPE = {
  WEBSITE: 1,
  FACEBOOK_MARKETPLACE: 2,
  ONLINE_SHOPPER: 3,
  STARTER_PACKAGE: 4,
  LOTLINX: 5,
};

const initialState = Map({
  fetching: new Set(),
  userStatusFetching: new Set(),
  selectedLocationId: null,
  locations: Map(),
});

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.OFFERS_FETCHED:
      return offersFetched(state, payload);
    case types.CURRENT_CONVERSATIONS_FETCHED:
      return currentConversationsFetched(state, payload);
    case types.CONVERSATION_FETCHED:
      return conversationFetched(state, payload);
    case types.USER_OVERVIEW_FETCHED:
      return userOverviewFetched(state, payload);
    case types.PUSHER_VISITOR_WAITING:
      return pusherVisitorWaiting(state, payload);
    case types.PUSHER_TRANSFER_WAITING:
      return pusherTransferWaiting(state, payload);
    case types.LOCATION_FETCHING:
      return locationFetching(state, payload);
    case types.LOCATION_FETCHED:
      return locationFetched(state, payload);
    case types.LOCATION_FETCH_ERROR:
      return locationFetchError(state, payload);
    case types.LOCATION_USER_STATUS_FETCHING:
      return locationUserStatusFetching(state, payload);
    case types.LOCATION_USER_STATUS_FETCHED:
      return locationUserStatusFetched(state, payload);
    case types.LOCATION_USER_STATUS_FETCH_ERROR:
      return locationUserStatusFetchError(state, payload);
    case types.BANNER_DELETED:
      return state.deleteIn(['locations', payload.locationId, 'banners', payload.bannerId]);
    case types.BANNER_SAVED:
      return state.mergeIn(
        ['locations', payload.locationId, 'banners', payload.id],
        mapBanner(payload)
      );
    case types.FEED_DELETED:
      return state.deleteIn(['locations', payload.locationId, 'feeds', payload.feedId]);
    case types.FEED_ADDED:
      return state.mergeIn(
        ['locations', payload.locationId, 'feeds', payload.id],
        mapFeed(payload)
      );
    case types.FEED_UPDATED:
      return state.mergeIn(
        ['locations', payload.locationId, 'feeds', payload.id],
        mapFeed(payload)
      );
    case types.HOTKEY_DELETED:
      return state.deleteIn(['locations', payload.locationId, 'hotkeys', payload.hotkeyId]);
    case types.HOTKEY_SAVED:
      return state.mergeIn(
        ['locations', payload.locationId, 'hotkeys', payload.id],
        mapHotkey(payload)
      );
    case types.STARTER_DELETED:
      return state.deleteIn(['locations', payload.locationId, 'starters', payload.starterId]);
    case types.STARTER_SAVED:
      return state.mergeIn(
        ['locations', payload.locationId, 'starters', payload.id],
        mapStarter(payload)
      );
    case types.LINK_DELETED:
      return state.deleteIn(['locations', payload.locationId, 'links', payload.linkId]);
    case types.LINK_SAVED:
      return state.mergeIn(
        ['locations', payload.locationId, 'links', payload.id],
        mapLink(payload)
      );
    case types.GENERIC_CONTENT_DELETED:
      return state.deleteIn(
        ['locations', payload.locationId, 'content', payload.contentId],
      );
    case types.VIDEO_DELETED:
      return state.deleteIn(['locations', payload.locationId, 'videos', payload.videoId]);
    case types.VIDEO_SAVED:
      return state.mergeIn(
        ['locations', payload.locationId, 'videos', payload.id],
        mapVideo(payload)
      );

    // location CRUD
    case types.LOCATION_DELETED:
      return state.deleteIn(['locations', payload.locationId]);
    case types.LOCATION_SAVED:
      return locationSaved(state, payload);
    case types.LOCATION_SETTINGS_SAVED:
      return locationSettingsSaved(state, payload);
    case types.LOCATION_AVATAR_SAVED:
      return state.mergeIn(
        ['locations', payload.locationId],
        Map({ avatar: payload.avatar, avatarType: payload.avatarType })
      );
    case types.LOCATION_AVATAR_DELETED:
      return state.mergeIn(
        ['locations', payload.locationId],
        Map({ avatar: payload.customerAvatar, avatarType: 'customer' })
      );
    case types.SET_SELECTED_LOCATION:
      return state.setIn(['selectedLocationId'], payload);

    // team CRUD
    case types.TEAM_DELETED:
      return teamDeleted(state, payload);
    case types.TEAM_SAVED:
      return teamSaved(state, payload);

    // fetch locations
    case types.CUSTOMER_LOCATIONS_FETCHED:
      return locationsFetched(state, payload);
    case types.LOCATION_HOTKEYS_FETCHED:
      return locationHotkeysFetched(state, payload);
    case types.HOTKEY_FETCHED:
      return hotkeyFetched(state, payload);
    case types.LOCATION_DEALER_MESSAGES_FETCHED:
      return dealerMessagesFetched(state, payload);

    case types.LOCATION_FEEDS_FETCHED:
      return locationFeedsFetched(state, payload);
    case types.FEED_FETCHED:
      return feedFetched(state, payload);
    case types.FEED_MAPPING_FETCHED:
      return feedMappingFetched(state, payload);
    case types.FEED_MAPPING_SAVED:
      return feedMappingSaved(state, payload);

    case types.LOCATION_BANNERS_FETCHED:
      return locationBannersFetched(state, payload);

    // fetch locations STARTER
    case types.LOCATION_STARTERS_FETCHED:
      return locationStartersFetched(state, payload);
    case types.STARTER_FETCHED:
      return starterFetched(state, payload);

    // fetch locations Links
    case types.LOCATION_LINKS_FETCHED:
      return locationLinksFetched(state, payload);
    case types.LINK_FETCHED:
      return linkFetched(state, payload);

    case types.LOCATION_GENERIC_CONTENT_FETCHED:
      return locationGenericContentFetched(state, payload);
    case types.GENERIC_CONTENT_FETCHED:
      return genericContentFetched(state, payload);

    // fetch locations Videos
    case types.LOCATION_VIDEOS_FETCHED:
      return locationVideosFetched(state, payload);
    case types.VIDEO_FETCHED:
      return videoFetched(state, payload);

    // fetch teams
    case types.LOCATION_TEAMS_FETCHED:
      return locationTeamsFetched(state, payload);

    // reports
    case types.LOCATION_DASHBOARD_FETCHING:
      return state.setIn(['locations', payload.locationId, 'dashboardFetching'], true);
    case types.LOCATION_DASHBOARD_FETCHED:
    case types.LOCATION_DASHBOARD_FETCH_ERROR:
      return state.setIn(['locations', payload.locationId, 'dashboardFetching'], false);
    default:
      return state;
  }
}

function offersFetched(state, { offers }) {
  offers.forEach(offerData => {
    state = addOrUpdateLocation(state, offerData.location);
  });
  return state;
}

function currentConversationsFetched(state, payload) {
  const conversations = payload.conversations.concat(payload.internalConversations);

  conversations.forEach(data => {
    const location = data.location;
    state = addOrUpdateLocation(state, location);
  });

  return state;
}

function conversationFetched(state, payload) {
  return addOrUpdateLocation(state, payload.data.location);
}

function userOverviewFetched(state, payload) {
  payload.locations.forEach(location => {
    state = addOrUpdateLocation(state, location);
  });

  return state;
}

function pusherVisitorWaiting(state, { location }) {
  if (!location) return state;
  return addOrUpdateLocation(state, location);
}

function pusherTransferWaiting(state, payload) {
  const location = payload.location;
  if (!location) return state;
  return addOrUpdateLocation(state, location);
}

function locationFetching(state, locationId) {
  return state.updateIn(['fetching'], fetching => fetching.add(locationId));
}

function locationFetched(state, { locationId, response }) {
  state = addOrUpdateLocation(state, response);
  state = state.mergeIn(['locations', locationId], Map({ lastFetched: moment() }));
  return state.updateIn(['fetching'], fetching => fetching.delete(locationId));
}

function locationFetchError(state, { locationId }) {
  return state.updateIn(['fetching'], fetching => fetching.delete(locationId));
}

function locationUserStatusFetching(state, locationId) {
  return state.updateIn(['userStatusFetching'], fetching => fetching.add(locationId));
}

function locationUserStatusFetched(state, { locationId, response: { location: { statusId } } }) {
  state = state.updateIn(['userStatusFetching'], fetching => fetching.delete(locationId));
  return state.setIn(['locations', locationId, 'statusId'], statusId);
}

function locationUserStatusFetchError(state, { locationId }) {
  return state.updateIn(['userStatusFetching'], fetching => fetching.delete(locationId));
}

function locationsFetched(state, { locations }) {
  locations.forEach(location => {
    state = addOrUpdateLocation(state, location);
  });

  return state;
}

function locationTeamsFetched(state, { locationId, teams }) {
  return state.setIn(['locations', locationId, 'teamIds'], Set(teams.map(team => team.id)));
}

function locationFeedsFetched(state, { locationId, feeds }) {
  if (!hasLocation(state, locationId)) return state;
  return state.setIn(['locations', locationId, 'feeds'], mapFeeds(feeds));
}

function feedFetched(state, data) {
  return state.setIn(['locations', data.locationId, 'feeds', data.id], mapFeed(data));
}

function feedMappingFetched(state, { locationId, feedId, mapping }) {
  if (!hasLocation(state, locationId)) return state;
  return state.setIn(
    ['locations', locationId, 'feeds', parseInt(feedId), 'mappings'],
    mapFeedMappings(mapping)
  );
}

function feedMappingSaved(state, { locationId, feedId, mapping }) {
  if (!hasLocation(state, locationId)) return state;
  return state.setIn(
    ['locations', locationId, 'feeds', parseInt(feedId), 'mappings'],
    mapFeedMappings(mapping)
  );
}

function locationBannersFetched(state, { locationId, banners }) {
  if (!hasLocation(state, locationId)) return state;
  return state.setIn(['locations', locationId, 'banners'], mapBanners(banners));
}

function locationHotkeysFetched(state, { locationId, hotkeys }) {
  if (!hasLocation(state, locationId)) return state;

  return state.setIn(['locations', locationId, 'hotkeys'], mapHotkeys(hotkeys));
}

function hotkeyFetched(state, data) {
  return state.setIn(['locations', data.locationId, 'hotkeys', data.id], mapHotkey(data));
}

function locationStartersFetched(state, { locationId, starters }) {
  if (!hasLocation(state, locationId)) return state;

  return state.setIn(['locations', locationId, 'starters'], mapStarters(starters));
}

function starterFetched(state, data) {
  return state.setIn(['locations', data.locationId, 'starters', data.id], mapStarter(data));
}

function locationLinksFetched(state, { locationId, links }) {
  if (!hasLocation(state, locationId)) return state;

  return state.setIn(['locations', locationId, 'links'], mapLinks(links));
}

function linkFetched(state, data) {
  return state.setIn(['locations', data.locationId, 'links', data.id], mapLink(data));
}

function locationGenericContentFetched(state, { locationId, content }) {
  if (!hasLocation(state, locationId)) return state;
  return state.setIn(['locations', locationId, 'content'], mapContentList(content));
}

function genericContentFetched(state, data) {
  return state.setIn(['locations', data.locationId, 'content', data.id], mapContent(data));
}

function locationVideosFetched(state, { locationId, videos }) {
  if (!hasLocation(state, locationId)) return state;

  return state.setIn(['locations', locationId, 'videos'], mapVideos(videos));
}

function videoFetched(state, data) {
  return state.setIn(['locations', data.locationId, 'videos', data.id], mapVideo(data));
}

function dealerMessagesFetched(state, { locationId, messages }) {
  if (!hasLocation(state, locationId)) return state;

  return state.withMutations(state => {
    let dealerMessages = Map();
    messages.forEach(message => {
      dealerMessages = dealerMessages.set(
        message.id,
        Map({
          id: message.id,
          expiresAt: message.expiresAt,
          subject: message.subject,
          body: message.messageBody,
          date: moment(message.createdAt),
        })
      );
    });
    state.setIn(['locations', locationId, 'dealerMessages'], dealerMessages);
  });
}

function locationSaved(state, payload) {
  // massage payload to match Location serializer format so we can reuse addLocation method
  payload.email = payload.contactEmail;
  payload.phone = payload.phoneNumber;

  return addOrUpdateLocation(state, payload);
}

function locationSettingsSaved(state, payload) {
  // remove locationId from the payload, then save everything else
  const settings = { ...payload };
  delete settings.locationId;
  return state.mergeIn(['locations', payload.locationId], Map(settings));
}

/**
 * delete all references to this teamId from location.teamIds
 */
function teamDeleted(state, { teamId }) {
  const updatedLocations = state.get('locations').map(entry => {
    if (!Map.isMap(entry)) return entry;
    return entry.set('teamIds', entry.get('teamIds', Set()).delete(teamId));
  });
  return state.set('locations', updatedLocations);
}

function teamSaved(state, { id, locationId }) {
  // remove teamId from locations, if it exists
  state = teamDeleted(state, { teamId: id });
  // add teamId to proper location
  return state.updateIn(['locations', locationId, 'teamIds'], teamIds => teamIds.add(id));
}
