import { getBugsnag } from '../../../../src/app/services';

const bugsnag = getBugsnag();

export default function createMiddleware() {
  return _store => next => action => {
    // temporarily log payload for SEND_FORM_UPDATE_TEXT to try to figure out what's causing max call stack issues
    const babelEnv = (typeof process !== 'undefined' && process.env && process.env.BABEL_ENV) || 'undefined';
    if (babelEnv !== 'mocha') {
      if (action.type === 'SEND_FORM_UPDATE_TEXT') {
        bugsnag.leaveBreadcrumb(
          `redux type: ${action.type}, redux payload: ${JSON.stringify(action.payload)}`
        );
      } else {
        bugsnag.leaveBreadcrumb(`redux: ${action.type}`);
      }
    }

    return next(action);
  };
}
