import { Map } from 'immutable';
import assign from 'lodash.assign';
import * as types from '../constants/actionTypes';

// key = visitorId
const initialState = Map();

export default function visitors(state = initialState, { type, payload }) {
  switch (type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.PUSHER_VISITOR_WAITING:
      return pusherVisitorWaiting(state, payload);
    case types.VISITOR_PROFILE_UPDATED_REMOTELY:
      return visitorUpdatedRemotely(state, payload);
    case types.PUSHER_TRANSFER_WAITING:
      return pusherTransferWaiting(state, payload);
    case types.OFFERS_FETCHED:
      return offersFetched(state, payload);
    case types.CURRENT_CONVERSATIONS_FETCHED:
      return currentConversationsFetched(state, payload);
    case types.CONVERSATION_FETCHED:
      return conversationFetched(state, payload);
    case types.VISITOR_SAVING:
      return visitorSaving(state, payload);
    case types.VISITOR_SAVED:
      return visitorSaved(state, payload);
    case types.VISITOR_SAVE_ERROR:
      return visitorSaveError(state, payload);
    case types.UPDATE_VISITOR_FIELD:
      return state.setIn([payload.visitorId, payload.field], payload.value);
    case types.UPDATE_VISITOR_FIELD_ERROR:
      return state.setIn([payload.visitorId, payload.field], payload.oldValue);
    case types.LOCATION_VISITOR_BLACKLIST_PENDING:
      return visitorBlacklisting(state, payload);
    case types.LOCATION_VISITOR_UNBLACKLIST_PENDING:
      return visitorUnblacklisting(state, payload);
    case types.LOCATION_VISITOR_BLACKLISTED:
      return visitorBlacklisted(state, payload);
    case types.LOCATION_VISITOR_UNBLACKLISTED:
      return visitorUnblacklisted(state, payload);
    default:
      return state;
  }
}

function pusherVisitorWaiting(state, { visitor }) {
  if (!visitor) return state;
  return state.set(visitor.id, Map(visitor));
}

function visitorUpdatedRemotely(state, visitorData) {
  if (!state.has(visitorData.id)) return state;
  return state.mergeIn([visitorData.id], visitorData);
}

function pusherTransferWaiting(state, payload) {
  const visitor = payload.visitor;
  if (!visitor) return state;
  return state.set(visitor.id, Map(visitor));
}

function currentConversationsFetched(state, payload) {
  payload.conversations.forEach(data => {
    if (data.visitor) {
      state = addVisitor(state, Map(data.visitor));
    }
  });
  return state;
}

function conversationFetched(state, { data: { visitor } }) {
  if (!visitor) return state;
  return addVisitor(state, Map(visitor));
}

function offersFetched(state, { offers }) {
  offers.forEach(offer => {
    if (offer.visitor) {
      state = addVisitor(state, Map(offer.visitor));
    }
  });

  return state;
}

function visitorSaving(state, visitorData) {
  return state.mergeIn(
    [visitorData.id],
    Map(assign(visitorData, { saving: true, saveError: false }))
  );
}

function visitorSaveError(state, { visitorData }) {
  state = state.setIn([visitorData.id, 'saving'], false);
  return state.setIn([visitorData.id, 'saveError'], true);
}

function addVisitor(state, visitor) {
  return state.mergeIn([visitor.get('id')], visitor);
}

function visitorSaved(state, { visitorData }) {
  state = state.setIn([visitorData.id, 'saving'], false);
  return state.setIn([visitorData.id, 'saveError'], false);
}

function visitorBlacklisting(state, visitorData) {
  return state.setIn([visitorData.visitorId, 'blacklisting'], true);
}

function visitorUnblacklisting(state, visitorData) {
  return state.setIn([visitorData.visitorId, 'unblacklisting'], true);
}

function visitorBlacklisted(state, visitorData) {
  state
    .get(visitorData.visitorId)
    .get('blacklistedLocations')
    .push(visitorData.locationId);
  return state.setIn([visitorData.visitorId, 'blacklisting'], false);
}

function visitorUnblacklisted(state, visitorData) {
  state
    .get(visitorData.visitorId)
    .get('blacklistedLocations')
    .splice(
      state
        .get(visitorData.visitorId)
        .get('blacklistedLocations')
        .indexOf(visitorData.locationId),
      1
    );
  return state.setIn([visitorData.visitorId, 'unblacklisting'], false);
}
