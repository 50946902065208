import Immutable, { Set } from 'immutable';
import { Team } from 'redux/constants/records';
import moment from 'moment';

export function addTeam(state, team) {
  if (hasTeam(state, team.id)) {
    const newData = {};
    newData.id = team.id;

    if (team.name) newData.name = team.name;
    if (team.contactName) newData.contactName = team.contactName;
    if (team.contactEmail) newData.contactEmail = team.contactEmail;
    if (team.offlineMessage) newData.offlineMessage = team.offlineMessage;
    if (team.managedChatPolicyId !== undefined)
      newData.managedChatPolicyId = team.managedChatPolicyId;
    if (team.callCenterTeamId !== undefined) newData.callCenterTeamId = team.callCenterTeamId;
    if (team.locationId !== undefined) newData.locationId = team.locationId;
    if (team.workingHours) newData.workingHours = Immutable.fromJS(team.workingHours);
    if (team.phoneNumber) newData.phoneNumber = team.phoneNumber;
    if (team.closedDates) newData.closedDates = mapClosedDates(team.closedDates);
    if (team.userIds) newData.userIds = Set(team.userIds);
    if (team.hasDefaultCrm) newData.hasDefaultCrm = team.hasDefaultCrm;
    if (team.isInternal) newData.isInternal = team.isInternal;
    if (team.isPriority) newData.isPriority = team.isPriority;
    if (team.isHidden) newData.isHidden = team.isHidden;

    return state.mergeIn([team.id], newData);
  }

  return state.set(
    team.id,
    new Team({
      id: team.id,
      name: team.name,
      userIds: team.userIds ? Set(team.userIds) : Set(),
      contactName: team.contactName,
      contactEmail: team.contactEmail,
      offlineMessage: team.offlineMessage,
      managedChatPolicyId: team.managedChatPolicyId,
      callCenterTeamId: team.callCenterTeamId,
      locationId: team.locationId,
      workingHours: Immutable.fromJS(team.workingHours || []),
      phoneNumber: team.phoneNumber,
      closedDates: mapClosedDates(team.closedDates || []),
      hasDefaultCrm: team.hasDefaultCrm ? team.hasDefaultCrm : false,
      isInternal: team.isInternal,
      isPriority: team.isPriority,
      isHidden: team.isHidden,
    })
  );
}

function mapClosedDates(closedDates) {
  if (!closedDates) return Set();

  return Set(
    closedDates
      .map(closedDate => closedDate.date) // extract date
      .map(date => moment(date)) // convert to moment object
      .filter(momentDate => momentDate.isSameOrAfter(moment())) // remove dates in the past
  );
}

export function hasTeam(state, teamId) {
  return state.has(teamId);
}

export function addUserToTeam(state, teamId, userId) {
  if (!state.hasIn([teamId, 'userIds'])) {
    state = state.setIn([teamId, 'userIds'], Set());
  }
  return state.updateIn([teamId, 'userIds'], ids => ids.add(userId));
}

export function deleteUserFromTeam(state, teamId, userId) {
  if (!state.hasIn([teamId, 'userIds'])) {
    state = state.setIn([teamId, 'userIds'], Set());
  }
  return state.updateIn([teamId, 'userIds'], ids => ids.delete(userId));
}

export function getTeam(state, teamId) {
  return state.teams.get(teamId);
}

export function clearTeams(state) {
    return state.clear();
}
