import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const InvalidToken: React.SFC = () => (
    <div className={classnames('invalid-token', 'centered-in-viewport')}>
        <h1>
            <i className="ion-alert-circled" /> Your session has expired
        </h1>
        <p>
            Please <Link to="/logout">click here to login again.</Link>
        </p>
    </div>
);

export default InvalidToken;
