export default {
  CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_CANCELLED: 'CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_CANCELLED',
  CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_EXCEPTION: 'CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_EXCEPTION',
  CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_REQUESTED: 'CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_REQUESTED',
  CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_RESPONDED: 'CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_RESPONDED',
  CONVERSATION_AGENT_MESSAGE_TRANSLATE_CANCELLED: 'CONVERSATION_AGENT_MESSAGE_TRANSLATE_CANCELLED',
  CONVERSATION_AGENT_MESSAGE_TRANSLATE_EXCEPTION: 'CONVERSATION_AGENT_MESSAGE_TRANSLATE_EXCEPTION',
  CONVERSATION_AGENT_MESSAGE_TRANSLATE_REQUESTED: 'CONVERSATION_AGENT_MESSAGE_TRANSLATE_REQUESTED',
  CONVERSATION_AGENT_MESSAGE_TRANSLATE_RESPONDED: 'CONVERSATION_AGENT_MESSAGE_TRANSLATE_RESPONDED',
  CONVERSATION_AGENT_TAKEOVER_CANCELLED: 'CONVERSATION_AGENT_TAKEOVER_CANCELLED',
  CONVERSATION_AGENT_TAKEOVER_EXCEPTION: 'CONVERSATION_AGENT_TAKEOVER_EXCEPTION',
  CONVERSATION_AGENT_TAKEOVER_REINSTATE: 'CONVERSATION_AGENT_TAKEOVER_REINSTATE',
  CONVERSATION_AGENT_TAKEOVER_REQUESTED: 'CONVERSATION_AGENT_TAKEOVER_REQUESTED',
  CONVERSATION_AGENT_TAKEOVER_RESPONDED: 'CONVERSATION_AGENT_TAKEOVER_RESPONDED',
  CONVERSATION_AGENT_TRANSCRIPT_CANCELLED: 'CONVERSATION_AGENT_TRANSCRIPT_CANCELLED',
  CONVERSATION_AGENT_TRANSCRIPT_EXCEPTION: 'CONVERSATION_AGENT_TRANSCRIPT_EXCEPTION',
  CONVERSATION_AGENT_TRANSCRIPT_REQUESTED: 'CONVERSATION_AGENT_TRANSCRIPT_REQUESTED',
  CONVERSATION_AGENT_TRANSCRIPT_RESPONDED: 'CONVERSATION_AGENT_TRANSCRIPT_RESPONDED',
  CONVERSATION_TRANSLATE_LANGUAGE_SOURCE_UPDATE: 'CONVERSATION_TRANSLATE_LANGUAGE_SOURCE_UPDATE',
  CONVERSATION_TRANSLATE_PLUGIN_ENABLED_TOGGLE: 'CONVERSATION_TRANSLATE_PLUGIN_ENABLED_TOGGLE',
  CONVERSATION_TRANSLATE_PLUGIN_VISIBLE_TOGGLE: 'CONVERSATION_TRANSLATE_PLUGIN_VISIBLE_TOGGLE',
  CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_CANCELLED: 'CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_CANCELLED',
  CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_EXCEPTION: 'CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_EXCEPTION',
  CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_REQUESTED: 'CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_REQUESTED',
  CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_RESPONDED: 'CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_RESPONDED',
  REPORT_CALL_CENTER_CANCELLED: 'REPORT_CALL_CENTER_CANCELLED',
  REPORT_CALL_CENTER_EXCEPTION: 'REPORT_CALL_CENTER_EXCEPTION',
  REPORT_CALL_CENTER_REQUESTED: 'REPORT_CALL_CENTER_REQUESTED',
  REPORT_CALL_CENTER_RESPONDED: 'REPORT_CALL_CENTER_RESPONDED',
  VIDEO_ROOM_CONNECT: 'VIDEO_ROOM_CONNECT',
  VIDEO_ROOM_CONNECTED: 'VIDEO_ROOM_CONNECTED',
  VIDEO_ROOM_DISCONNECT: 'VIDEO_ROOM_DISCONNECT',
  VIDEO_ROOM_TOGGLE: 'VIDEO_ROOM_TOGGLE',
  LOCATION_SETTINGS_REQUESTED: 'LOCATION_SETTINGS_REQUESTED',
  LOCATION_SETTINGS_RESPONDED: 'LOCATION_SETTINGS_RESPONDED',
  LOCATION_SETTINGS_EXCEPTION: 'LOCATION_SETTINGS_EXCEPTION',
  OEMS_REQUESTED: 'OEMS_REQUESTED',
  OEMS_RESPONDED: 'OEMS_RESPONDED',
  OEMS_EXCEPTION: 'OEMS_EXCEPTION',
  OEMS_SET_TEMPLATE: 'OEMS_SET_TEMPLATE',
};
