import Immutable, { Map } from 'immutable';
import moment from 'moment';
import * as types from 'redux/constants/actionTypes';

const initialState = Map({
  customer: Map({
    dashboard: Map(),
    summary: Map(),
    history: Map({
      startDate: null,
      endDate: null,
      customerId: null,
      numPages: null,
      conversations: Map(),
    }),
    activeConversations: Map({
      fetching: false,
      users: Map({}),
    }),
  }),
  location: Map({
    dashboard: Map(),
  }),
  team: Map({
    dashboard: Map(),
  }),
  agent: Map({
    summary: Map(),
  }),
});

export default function config(state = initialState, { type, payload }) {
  switch (type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.CUSTOMER_DASHBOARD_FETCHED:
      return customerDashboardFetched(state, payload);
    case types.LOCATION_DASHBOARD_FETCHED:
      return locationDashboardFetched(state, payload);
    case types.TEAM_DASHBOARD_FETCHED:
      return teamDashboardFetched(state, payload);
    case types.CUSTOMER_SUMMARY_FETCHED:
      return customerSummaryFetched(state, payload);
    case types.LOCATION_SUMMARY_FETCHED:
      return locationSummaryFetched(state, payload);
    case types.TEAM_SUMMARY_FETCHED:
      return teamSummaryFetched(state, payload);
    case types.AGENT_SUMMARY_FETCHED:
      return agentSummaryFetched(state, payload);
    case types.CONVO_HISTORY_REPORT_FETCHED:
      return convoHistoryReportFetched(state, payload);
    case types.CRM_SENT:
      return crmSent(state, payload);
    case types.CUSTOMER_USER_ACTIVE_CHATS_FETCHING:
      return state.setIn(['customer', 'activeConversations', 'fetching'], true);
    case types.CUSTOMER_USER_ACTIVE_CHATS_FETCH_ERROR:
      return state.setIn(['customer', 'activeConversations', 'fetching'], false);
    case types.CUSTOMER_USER_ACTIVE_CHATS_FETCHED:
      state = state.setIn(['customer', 'activeConversations', 'fetching'], false);
      return state.setIn(
        ['customer', 'activeConversations', 'users'],
        payload.users.reduce(
          (map, user) => map.set(user.userId, user.numActiveConversations),
          Map()
        )
      );
    default:
      return state;
  }
}

export function customerDashboardFetched(state, { customerId, data }) {
  state = state.setIn(['customer', 'dashboard', 'id'], customerId);
  return state.setIn(['customer', 'dashboard', 'data'], Immutable.fromJS(data));
}

export function locationDashboardFetched(state, { locationId, data }) {
  state = state.setIn(['location', 'dashboard', 'id'], locationId);
  return state.setIn(['location', 'dashboard', 'data'], Immutable.fromJS(data));
}

export function teamDashboardFetched(state, { teamId, data }) {
  state = state.setIn(['team', 'dashboard', 'id'], teamId);
  return state.setIn(['team', 'dashboard', 'data'], Immutable.fromJS(data));
}

export function customerSummaryFetched(state, { customerId, data }) {
  state = state.setIn(['customer', 'summary', 'id'], customerId);
  return state.setIn(['customer', 'summary', 'data'], Immutable.fromJS(data));
}

export function locationSummaryFetched(state, { locationId, data }) {
  state = state.setIn(['location', 'summary', 'id'], locationId);
  return state.setIn(['location', 'summary', 'data'], Immutable.fromJS(data));
}

export function teamSummaryFetched(state, { teamId, data }) {
  state = state.setIn(['team', 'summary', 'id'], teamId);
  return state.setIn(['team', 'summary', 'data'], Immutable.fromJS(data));
}

export function agentSummaryFetched(state, { userId, data }) {
  state = state.setIn(['agent', 'summary', 'id'], userId);
  return state.setIn(['agent', 'summary', 'data'], Immutable.fromJS(data));
}

export function convoHistoryReportFetched(
  state,
  { customerId, startDate, endDate, data: { currentPage, numberPages, items, summary } }
) {
  if (!isNewSearch(state, customerId, startDate, endDate)) {
    // delete existing data
    state = state.setIn(['customer', 'history', 'conversations'], Map());
  }

  state = state.mergeIn(
    ['customer', 'history'],
    Map({
      startDate: startDate.format(),
      endDate: endDate.format(),
      customerId,
      numPages: numberPages,
      summary: Map(summary),
    })
  );

  return state.setIn(
    ['customer', 'history', 'conversations', String(currentPage)],
    mapConversations(items)
  );
}

/**
 * Do these search parameters constitute a new search?
 * @param state
 * @param customerId
 * @param startDate
 * @param endDate
 * @returns {boolean}
 */
function isNewSearch(state, customerId, startDate, endDate) {
  if (state.getIn(['customer', 'history', 'startDate']) !== startDate.format()) {
    return false;
  }
  if (state.getIn(['customer', 'history', 'endDate']) !== endDate.format()) {
    return false;
  }
  if (state.getIn(['customer', 'history', 'customerId']) !== customerId) {
    return false;
  }
  return true;
}

/**
 * convert array of convos into Map indexed by channel
 * @param convos
 */
function mapConversations(convos) {
  return convos.reduce((map, convo) => map.set(convo.Channel, mapConvo(convo)), Map());
}

function mapConvo(convo) {
  let convoMap = Map(convo);

  convoMap = convoMap.set('Date', moment(convo.Date, 'YYYY-MM-DD HH:mm:ss'));
  convoMap = convoMap.set('Sent To CRM', convo['Sent To CRM'].toLowerCase() === 'yes');
  convoMap = convoMap.set('Test', convo.Test.toLowerCase() === 'yes');

  return convoMap;
}

function crmSent(state, { channel }) {
  state.getIn(['customer', 'history', 'conversations']).forEach((leads, page) => {
    if (leads.has(channel)) {
      state = state.setIn(
        ['customer', 'history', 'conversations', page, channel, 'Sent To CRM'],
        true
      );
    }
  });
  return state;
}
