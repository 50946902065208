import axios from 'axios';
import { loadFromCookie } from '../businessLogic/cookieStorage';
/**
 * create an axios instance with defaults set
 * @returns {axios}
 */
export function getInstance(store, version = '2.0.3') {

  const cookie = loadFromCookie();

  return axios.create({
    baseURL: store.config.getIn(['api', 'baseUrl']),
    timeout: 60000,
    headers: {
      'X-Auth-Token': cookie.token,
      'X-Api-Version': version,
    },
  });
}

export { getInstance as default };
