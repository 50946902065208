import App from 'components/App';
import { connect } from 'react-redux';
import {
  getBrowserConfig,
  onWindowFocusChange,
  onConnectionChange,
} from 'redux/actions/configActions';
import { fetchProfile, fetchUserOverview, validateToken, fetchUserAccountsByAuth0Id } from 'redux/actions/usersActions';
import { fetchUserCustomers } from 'redux/actions/customerActions';
import { connectToPusher, joinControlChannel } from 'redux/actions/pusherActions';
import { getLoggedInUser, getLoggedInUserId } from 'selectors/userSelectors';
import { isBrowserSupported } from 'selectors/configSelectors';

import { RootState } from 'typedefs/redux';

export const mapStateToProps = (state: RootState) => {
  const user = getLoggedInUser(state);
  return {
    userId: getLoggedInUserId(state),
    userEmail: user ? user.get('email') : '',
    userFirstName: user ? user.get('firstName') : '',
    userLastName: user ? user.get('lastName') : '',
    tokenIsValid: state.users.getIn(['loggedInUser', 'tokenIsValid']),
    isSuperAdmin: user ? user.get('isSuperAdmin') : false,
    isBrowserSupported: isBrowserSupported(state),
  };
};

export const mapDispatchToProps = {
  getBrowserConfig,
  validateToken,
  fetchProfile,
  fetchUserOverview,
  fetchUserAccountsByAuth0Id,
  fetchUserCustomers,
  onWindowFocusChange,
  onConnectionChange,
  connectToPusher,
  joinControlChannel,
};

export default connect(mapStateToProps, mapDispatchToProps)(App as any);
