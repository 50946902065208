/**
 * Record type definitions
 */
import { Record, List, Set, Map } from 'immutable';
import { OFFLINE } from 'redux/reducers/statuses';
import { defaultFilter } from 'redux/reducers/search';

// team
export const Team = new Record({
  id: undefined,
  name: undefined,
  contactName: undefined,
  contactEmail: undefined,
  offlineMessage: undefined,
  managedChatPolicyId: undefined,
  callCenterTeamId: undefined,
  userIds: Set(),
  workingHours: List(),
  phoneNumber: undefined,
  closedDates: Set(),
  statusId: OFFLINE,
  dashboardFetching: false,
  users: Set(), // dynamically added
  location: Map(), // dynamically added
  locationId: undefined,
  crms: Map(),
  hasDefaultCrm: false,
  isInternal: false,
  isPriority: false,
  isHidden: false,
});

// conversation live video settings
export const VideoSettings = new Record({
  videoPanelActive: false,
  localVideoActive: false,
  videoInitiatedBy: 'agent',
  videoConversationRequested: false,
  videoAcceptedByVisitor: false,
  remoteVideoConnected: false,
  remoteVideoStreaming: false,
  remoteVideoClosed: false,
  videoStartDate: undefined,
});

// CRM
export const CRM = new Record({
  id: undefined,
  customerId: undefined,
  teamId: undefined,
  name: undefined,
  type: undefined,
  email: undefined,
  messageAdfContent: undefined,
  visitAdfContent: undefined,
  conversationAdfContent: undefined,
  callAdfContent: undefined,
  isDefault: false,
  dealerId: undefined,
});

// Search params
export const VehicleSearchParams = new Record({
  year: '',
  make: '',
  model: '',
  color: '',
  vin: '',
  stock: '',
});
export const SearchParams = new Record({
  filter: defaultFilter, // todo
  omnibox: '',
  vehicle: new VehicleSearchParams(),
});

export default {};
