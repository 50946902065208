import { Map } from 'immutable';
import { get } from 'lodash';
import Constants from '../../constants';

const initialState: any = Map();

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case Constants.CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_CANCELLED:
      return state.setIn([`${payload.customerId}`, 'loading'], false);

    case Constants.CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_EXCEPTION:
      return state
        .setIn([`${payload.customerId}`, 'errors'], payload.errors)
        .setIn([`${payload.customerId}`, 'loading'], true);

    case Constants.CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_REQUESTED:
      return state
        .setIn(
          [`${payload.customerId}`, 'data'],
          state.getIn([`${payload.customerId}`, 'data']) || []
        )
        .setIn([`${payload.customerId}`, 'errors'], [])
        .setIn([`${payload.customerId}`, 'loading'], true);

    case Constants.CUSTOMER_ACTIVE_CALL_CENTER_CONVERSATIONS_RESPONDED:
      return state
        .setIn(
          [`${payload.customerId}`, 'data'],
          get(payload, 'response.data.conversations', [])
        )
        .setIn([`${payload.customerId}`, 'errors'], [])
        .setIn([`${payload.customerId}`, 'loading'], false);

    default:
      return state;
  }
};
