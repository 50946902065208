
import 'styles/login.scss';
import React from 'react';
import conversationsLogo from 'images/logo.svg';
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router';


const Auth0Unauthorized = (props: RouteChildrenProps): JSX.Element => {
  return (
    <div className="auth0-logout-container">
      <img src={conversationsLogo} alt="Conversations Logo" />
      <div className='logout-details'>
        <h2>Oh No!</h2>
        <p>It seems you are not authorized to use Conversations&#8482;. Please send an email to <a href="mailto:conversationssupport@dealerinspire.com">conversationssupport@dealerinspire.com</a> to request access.</p>
        <button className='logout-btn' onClick={(): void => {
          props.history.push('/logout');
        }}>Log Out</button>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect(undefined)(withRouter(Auth0Unauthorized) as any);
