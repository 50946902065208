import React from 'react';

const UnsupportedBrowser: React.SFC = () => (
  <div className="unsupported-browser">
    <h2>
      <i className="ion-android-warning" /> The web browser you are using is not supported by
      Conversations.
    </h2>
    <div>
        Because Conversations uses state-of-the-art technology that is not supported by many
        older browsers, we require the use of a modern browser like{' '}
      <a href="https://www.google.com/intl/en/chrome/browser/desktop/index.html">
        Google Chrome.
      </a>
    </div>
  </div>
);

export default UnsupportedBrowser;
