import moment from 'moment';
import * as types from 'redux/constants/actionTypes';
import {
  getLoggedInUserId,
  getLoggedInUserData,
} from 'selectors/userSelectors';
import { addNotification } from './notificationActions';
import { activateConversation, sendMessage } from './conversationsActions';

// todo test
export function acceptOffer(channel) {
  return (dispatch, getState, { APIFactory }) => {
    const offer = getState().offers.getIn(['offers', channel]);

    if (!offer) return Promise.resolve();
    if (offer.get('isAccepting') === true) return Promise.resolve();

    dispatch({ type: types.ACCEPTING_OFFER, payload: { channel } });

    const instance = APIFactory.getInstance(getState());
    return instance
      .post('/conversation/offer/accept', { channelName: channel })
      .then(response => {
        dispatch(acceptedOffer(channel, offer, getLoggedInUserId(getState())));
        dispatch(activateConversation(channel));
      })
      .catch(error => {
        dispatch(acceptOfferError(channel));
        dispatch(addNotification(buildOfferErrorNotificationData(error.response)));
      });
  };
}

// todo test
export function declineOffer(channel) {
  return (dispatch, getState, { APIFactory }) => {
    const offer = getState().offers.getIn(['offers', channel]);

    if (!offer) return Promise.resolve();

    const instance = APIFactory.getInstance(getState());
    return instance
      .post('/conversation/offer/decline', { channelName: channel })
      .catch(response => {
        // ignore errors
      });
  };
}

function acceptedOffer(channel, offer, userId) {
  return { type: types.ACCEPTED_OFFER, payload: { channel, offer, userId } };
}

function acceptOfferError(channel) {
  return { type: types.ACCEPTING_OFFER_ERROR, payload: channel };
}

function buildOfferErrorNotificationData(response) {
  let message;
  let title;

  if (!response) {
    title = 'Error';
    message = 'There was an error accepting this offer.';
  } else if (response.status === 410) {
    const acceptedBy = response.data.acceptingAgent;
    const agentName = `${acceptedBy.firstName} ${acceptedBy.lastName}`;
    title = 'Too slow!';
    message = `${agentName} beat you to this conversation! We bet you'll get the next one.`;
  } else {
    let errors = '';
    if (response.data?.errors) {
      errors = response.data.errors.join('. ');
    }
    title = 'Error';
    message = `There was an error accepting this offer. ${errors}`;
  }
  return {
    level: 'error',
    title,
    message,
  };
}

export function fetchOffers() {
  return (dispatch, getState, { APIFactory }) => {
    if (getState().offers.get('isFetching')) {
      return Promise.resolve();
    }

    dispatch({ type: types.OFFERS_FETCHING });

    const instance = APIFactory.getInstance(getState());
    return instance
      .get('/conversation/open/offers')
      .then(response => {
        const currentStatusId = getLoggedInUserData(getState()).get('statusId');
        dispatch({
          type: types.OFFERS_FETCHED,
          payload: { offers: response.data.offers, statusId: currentStatusId },
        });
      })
      .catch(() => {
        dispatch({ type: types.OFFERS_FETCH_ERROR });
      });
  };
}

export function setInitialFetchCompleted() {
  return { type: types.INITIAL_OFFER_FETCH_COMPLETED };
}

/**
 * Call API to mark this offer as "shown".  No actions are needed on success/failure, so no actions are dispatched
 * @param channel
 * @returns {function()}
 */
export function markAsShown(channel) {
  return (dispatch, getState, { APIFactory }) => {
    const instance = APIFactory.getInstance(getState());
    return instance.post('/conversation/offer/shown', { channelName: channel }).catch(() => {
      // ignore errors
    });
  };
}
