import { Set } from 'immutable';
import without from 'lodash.without';
import difference from 'lodash.difference';
import moment from 'moment';
import * as types from 'redux/constants/actionTypes';
import {
  redirectToRoute,
  buildFormErrors,
  mapDayNameToDayNumber,
  mapDayNumberToDayName,
  zeroPad,
} from 'businessLogic/util';
import {
  getSelectedCustomerLocations,
  getSelectedCustomerTeams,
  getAllLocations,
} from 'selectors/locationSelectors';
import { getAllTeams } from 'selectors/teamSelectors';
import { addErrorNotification } from '../actions/notificationActions';
import { FORM_ERROR } from 'final-form';

export function fetchLocationTeams(locationId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_TEAMS_FETCHING, payload: { locationId } });

    const api = APIFactory.getInstance(getState());
    return api
      .get(`/location/${locationId}/teams`)
      .then(response => {
        dispatch({
          type: types.LOCATION_TEAMS_FETCHED,
          payload: { locationId, teams: response.data.teams },
        });
      })
      .catch(() => {
        dispatch({ type: types.LOCATION_TEAMS_FETCH_ERROR, payload: { locationId } });
      });
  };
}

export function fetchCustomerTeams(customerId) {
  return (dispatch, getState) => {
    dispatch({ type: types.CUSTOMER_TEAMS_FETCHING, payload: { customerId } });

    const locations = getSelectedCustomerLocations(getState());

    const promises = [];
    locations.forEach(location => {
      promises.push(dispatch(fetchLocationTeams(location.get('id'))));
    });
    return Promise.all(promises).then(() => {
      dispatch({ type: types.CUSTOMER_TEAMS_FETCHED, payload: { customerId } });
    });
  };
}

export function fetchAllCustomerTeams(customerId) {
  return (dispatch, getState) => {
    dispatch({ type: types.CUSTOMER_TEAMS_FETCHING, payload: { customerId } });

    const locations = getAllLocations(getState());

    const promises = [];
    locations.forEach(location => {
      if (location.get('customerId') !== customerId) return;
      promises.push(dispatch(fetchLocationTeams(location.get('id'))));
    });
    return Promise.all(promises).then(() => {
      dispatch({ type: types.CUSTOMER_TEAMS_FETCHED, payload: { customerId } });
    });
  };
}

export function fetchTeamUserStatus(teamId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_USER_STATUS_FETCHING, payload: { teamId } });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`team/${teamId}/agent/status`)
      .then(response =>
        dispatch({
          type: types.TEAM_USER_STATUS_FETCHED,
          payload: { teamId, response: response.data },
        })
      )
      .catch(() =>
        dispatch({ type: types.TEAM_USER_STATUS_FETCH_ERROR, payload: { teamId } })
      );
  };
}

export function fetchSelectedCustomerTeamsStatus() {
  return (dispatch, getState) => {
    const teams = getSelectedCustomerTeams(getState());
    if (teams && teams.size > 0) {
      const promises = teams
        .filter(team => typeof team.get('id') === 'number')
        .map(team => dispatch(fetchTeamUserStatus(team.get('id'))));
      return Promise.all(promises.toArray());
    }
    return Promise.resolve([]);
  };
}



export function deactivateTeam(teamId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_DELETING, payload: { teamId } });

    const api = APIFactory.getInstance(getState());
    return api
      .delete(`/admin/team/${teamId}`)
      .then(() => {
        dispatch({ type: types.TEAM_DELETED, payload: { teamId } });
      })
      .catch(() => {
        dispatch({ type: types.TEAM_DELETE_ERROR, payload: { teamId } });
        dispatch(addErrorNotification('Error deactivating team'));
      });
  };
}

export function deleteUserFromTeam(api, dispatch, teamId, userId) {
  return api
    .delete('/admin/user/team', { data: { userId, teamId } })
    .then(() => dispatch({ type: types.USER_DELETED_FROM_TEAM, payload: { teamId, userId } }));
}

export function addUserToTeam(api, dispatch, teamId, userId) {
  return api
    .post('/admin/user/team', { userId, teamId })
    .then(() => dispatch({ type: types.USER_ADDED_TO_TEAM, payload: { teamId, userId } }));
}

/**
 * @param {int} teamId
 * @param {array} userIds
 */
export function saveUsers(teamId, userIds) {
  return (dispatch, getState, { APIFactory }) => {
    const existingUserIds = getAllTeams(getState())
      .getIn([teamId, 'userIds'], Set())
      .toArray();
    const toBeDeleted = without(existingUserIds, ...userIds);
    const toBeAdded = difference(userIds, existingUserIds);

    const api = APIFactory.getInstance(getState());
    const deleteUserPromises = toBeDeleted.map(userId =>
      deleteUserFromTeam(api, dispatch, teamId, userId)
    );
    const addUserPromises = toBeAdded.map(userId =>
      addUserToTeam(api, dispatch, teamId, userId)
    );

    dispatch({ type: types.TEAM_USERS_UPDATING, payload: { teamId } });
    return Promise.all([...deleteUserPromises, ...addUserPromises])
      .then(() => dispatch({ type: types.TEAM_USERS_UPDATED, payload: { teamId } }))
      .catch(() => {
        dispatch({ type: types.TEAM_USERS_UPDATE_ERROR, payload: { teamId } });
        dispatch(addErrorNotification('Error updating team users'));
      });
  };
}

function buildCreateTeamPayload(formData, state) {
  const {
    name,
    locationId,
    offlineMessage,
    contactName,
    contactEmail,
    managedChatPolicyId,
    callCenterTeamId,
    isInternal,
    isPriority,
    isHidden,
  } = formData;

  const payload = {
    name,
    locationId: parseInt(locationId),
    offlineMessage: offlineMessage || null,
    contactName,
    contactEmail,
    isInternal,
    isPriority,
    isHidden,
  };

  // only send policy ID if the selected location already has managed chat enabled
  const location = getAllLocations(state).get(parseInt(locationId));

  if (
    location &&
    location.get('enableManagedChat') === true &&
    managedChatPolicyId &&
    callCenterTeamId
  ) {
    payload.callCenterTeamId = parseInt(callCenterTeamId, 10);
    payload.managedChatPolicyId = parseInt(managedChatPolicyId, 10);
  } else {
    payload.callCenterTeamId = null;
    payload.managedChatPolicyId = null;
  }

  return payload;
}

export function createTeam(formData) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_SAVING });
    const payload = buildCreateTeamPayload(formData, getState());
    const api = APIFactory.getInstance(getState());
    return api
      .post('/admin/team', payload)
      .then(response => {
        const teamId = response.data.id;
        dispatch({
          type: types.TEAM_SAVED,
          payload: Object.assign({}, payload, { id: teamId }),
        });
        setTimeout(() => redirectToRoute(`/admin/team`), 3000);
        return undefined;
      })
      .catch(() => {
        dispatch({ type: types.TEAM_SAVE_ERROR });
        return {[FORM_ERROR]: 'Error creating team.'};
      });
  };
}

export function updateTeam(formData) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_SAVING });
    const teamId = formData.teamId;
    const payload = buildCreateTeamPayload(formData, getState());
    const api = APIFactory.getInstance(getState());
    return api
      .patch(`/admin/team/${teamId}`, payload)
      .then(() => {
        dispatch({
          type: types.TEAM_SAVED,
          payload: Object.assign({ id: teamId }, payload),
        });
        return undefined;
      })
      .catch(() => {
        dispatch({ type: types.TEAM_SAVE_ERROR });
        return {[FORM_ERROR]: 'Error saving teams.'};
      });
  };
}

export function updateTeamPolicy({ teamId, managedChatPolicyId, callCenterTeamId }) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_POLICY_SAVING });

    const payload = { managedChatPolicyId, callCenterTeamId };

    const api = APIFactory.getInstance(getState());
    return api
      .patch(`/admin/team/${teamId}`, payload)
      .then(() => {
        dispatch({
          type: types.TEAM_POLICY_SAVED,
          payload: Object.assign({}, { teamId }, payload),
        });
      })
      .catch(() => {
        dispatch({ type: types.TEAM_POLICY_SAVE_ERROR, payload: { teamId } });
        dispatch(addErrorNotification('Error saving team policy.'));
      });
  };
}

export function deleteHour(teamId, hourId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_HOUR_DELETING, payload: { teamId, hourId } });

    const api = APIFactory.getInstance(getState());
    return api
      .delete(`/admin/team/${teamId}/hours/${hourId}`)
      .then(() => {
        dispatch({ type: types.TEAM_HOUR_DELETED, payload: { teamId, hourId } });
      })
      .catch(() => {
        dispatch({ type: types.TEAM_HOUR_DELETE_ERROR, payload: { teamId, hourId } });
      });
  };
}

function createHourPayload(formData) {
  const startHour =
    formData.startPeriod === 'AM'
      ? Number(formData.startHour)
      : Number(formData.startHour) + 12;
  const endHour =
      formData.endPeriod === 'AM' ? Number(formData.endHour) : Number(formData.endHour) + 12;
  return {
    days: formData.days.map(mapDayNameToDayNumber).sort(),
    startTime: `${zeroPad(startHour)}:${zeroPad(formData.startMinute)}:00`,
    endTime: `${zeroPad(endHour)}:${zeroPad(formData.endMinute)}:00`,
  };
}

export function saveHour(teamId, hourId, formData) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_HOUR_ADDING });

    const payload = createHourPayload(formData);
    const api = APIFactory.getInstance(getState());

    // delete existing hours so we can replace it
    if (hourId) {
      dispatch(deleteHour(teamId, hourId));
    }

    // save new hour
    return api
      .post(`/admin/team/${teamId}/hours`, payload)
      .then(response => {
        const hourData = {
          id: response.data.hourId,
          isActive: true,
          days: payload.days.map(mapDayNumberToDayName),
          timeStart: payload.startTime,
          timeEnd: payload.endTime,
        };
        dispatch({ type: types.TEAM_HOUR_ADDED, payload: { teamId, hourData } });
      })
      .catch(() => {
        dispatch({ type: types.TEAM_HOUR_ADD_ERROR, payload: { teamId, hourId } });
        dispatch(addErrorNotification('Error updating team hours.'));
      });
  };
}

/**
 * @param {number} teamId
 * @param {moment} date
 */
export function deleteClosedDate(teamId, date) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_CLOSED_DATE_DELETING, payload: { teamId, date } });

    const api = APIFactory.getInstance(getState());
    return api
      .delete(`/admin/team/${teamId}/closed_dates`, {
        data: { dates: [date.format('YYYY-MM-DD')] },
      })
      .then(() => {
        dispatch({ type: types.TEAM_CLOSED_DATE_DELETED, payload: { teamId, date } });
      })
      .catch(() => {
        dispatch({ type: types.TEAM_CLOSED_DATE_DELETE_ERROR, payload: { teamId, date } });
        dispatch(addErrorNotification('Error deleting closed date.'));
      });
  };
}

/**
 * @param {number} teamId
 * @param {moment[]} dates
 */
export function addClosedDates(teamId, dates) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.TEAM_CLOSED_DATE_ADDING, payload: { teamId, dates } });
    const api = APIFactory.getInstance(getState());
    return api
      .post(`/admin/team/${teamId}/closed_dates`, {
        dates: dates.map(date => moment(date).format('YYYY-MM-DD')),
      })
      .then(() => {
        dispatch({ type: types.TEAM_CLOSED_DATES_ADDED, payload: { teamId, dates } });
      })
      .catch(() => {
        dispatch({ type: types.TEAM_CLOSED_DATE_ADD_ERROR, payload: { teamId, dates } });
        dispatch(addErrorNotification('Error added closed dates.'));
      });
  };
}

export function fetchCallCenterTeams() {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    return api
      .get('/admin/call-center-teams')
      .then(response => {
        dispatch({
          type: types.CALL_CENTER_TEAMS_FETCHED,
          payload: { teams: response.data },
        });
      })
      .catch(() => {
        dispatch(addErrorNotification('Error fetching call center teams.'));
      });
  };
}
