/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Map } from 'immutable';
import { get } from 'lodash';
import Constants from '../constants';

const initialState: any = Map({
  data: [],
  errors: [],
  loading: false,
});

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case Constants.OEMS_EXCEPTION:
      return state
        .setIn(['errors'], payload.errors)
        .setIn(['loading'], true);

    case Constants.OEMS_REQUESTED:
      return state
        .setIn(['data'], state.getIn(['data'], null))
        .setIn(['errors'], [])
        .setIn(['loading'], true);

    case Constants.OEMS_RESPONDED:
      return state
        .setIn(['data'], get(payload, 'response.data'))
        .setIn(['errors'], [])
        .setIn(['loading'], false);

    default:
      return state;
  }
};
