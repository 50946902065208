/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Map, Set, List, fromJS } from 'immutable';
import moment from 'moment';
import get from 'lodash.get';
import includes from 'lodash.includes';
import isObject from 'lodash.isobject';
import Constants from '../constants';
import * as types from '../constants/actionTypes';
import { VideoSettings, VehicleSearchParams } from '../constants/records';
import { addConversation } from '../../businessLogic/conversationHelper';

const initialState = Map({
  conversations: Map(),
  activeConversation: null,
  isFetchingCurrentConversations: false,
  currentConversationsFetched: false,
  initialTranscriptsFetched: false, // when the page is loaded, all transcripts for open conversations are fetched
  shouldShowNewConversationPanel: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.CURRENT_CONVERSATIONS_FETCHING:
      return state.set('isFetchingCurrentConversations', true);
    case types.CURRENT_CONVERSATIONS_FETCHED:
      return currentConversationsFetched(state, action.payload);
    case types.CURRENT_CONVERSATIONS_FETCH_ERROR:
      return currentConversationsFetchError(state, action.payload);
    case types.CONVERSATION_FETCHED:
      return conversationFetched(state, action.payload);
    case types.CONVERSATION_ACTIVATE:
      return activateConversation(state, action.payload);
    case types.CONVERSATION_SET_USER_IDS:
      return setConversationUserIds(state, action.payload);
    case types.ACCEPTED_OFFER:
      return acceptedOffer(state, action.payload);
    case types.TRANSCRIPT_FETCHING:
      return transcriptFetching(state, action.payload);
      // todo test (normalizeMessageData)
    case types.TRANSCRIPT_FETCHED:
      return transcriptFetched(state, action.payload);
    case types.TRANSCRIPT_FETCH_ERROR:
      return transcriptFetchError(state, action.payload);
    case types.ALL_TRANSCRIPTS_FETCHED:
      return state.set('initialTranscriptsFetched', true);
      // todo test (normalizeMessageData)
    case types.PUSHER_NEW_MESSAGE:
      return pusherNewMessage(state, action.payload);
    case types.MESSAGE_SENDING:
      return messageSending(state, action.payload);
    case types.MESSAGE_SENT:
      return messageSent(state, action.payload);
    case types.MESSAGE_SEND_ERROR:
      return messageSendError(state, action.payload);
    case types.TOGGLE_HOTKEY_PANEL:
      return toggleHotkeyPanel(state, action.payload);
    case types.TOGGLE_EXPLODE_TO_TEXT:
      return toggleExplodeToText(state, action.payload);
      // creating convo
    case types.TOGGLE_NEW_CONVERSATION_PANEL:
      return state.set('shouldShowNewConversationPanel', action.payload);

      // ending convo
    case types.ENDING_CONVERSATION:
      return endingConversation(state, action.payload);
    case types.ENDED_CONVERSATION:
      return endedConversation(state, action.payload);
    case types.ENDING_CONVERSATION_ERROR:
      return state.setIn(['conversations', action.payload, 'ending'], false);

      // leaving convo
    case types.LEAVING_CONVERSATION:
      return leavingConversation(state, action.payload);
    case types.LEFT_CONVERSATION:
      return closeConversation(state, action.payload);
    case types.LEAVING_CONVERSATION_ERROR:
      return leavingConversationError(state, action.payload);
    case types.CLOSE_CONVERSATION:
      return closeConversation(state, action.payload);
    case types.VISITOR_CHANNEL_PRESENCE:
      return visitorChannelPresence(state, action.payload);
    case types.PUSHER_NEW_READ_RECEIPTS:
      return pusherNewReadReceipts(state, action.payload);
    case types.MARKED_MESSAGES_READ:
      return markedMessagesRead(state, action.payload);

      // typing status
    case types.STATUS_COMPOSING:
      if (!state.hasIn(['conversations', action.payload])) return state;
      return state.setIn(['conversations', action.payload, 'isComposing'], true);
    case types.STATUS_PAUSED:
      if (!state.hasIn(['conversations', action.payload])) return state;
      return state.setIn(['conversations', action.payload, 'isComposing'], false);
    case types.SEND_FORM_UPDATE_TEXT:
      if (!state.hasIn(['conversations', action.payload.channel])) return state;
      return state.setIn(
        ['conversations', action.payload.channel, 'agentLastTypedAt'],
        moment()
      );
    case types.SEND_FORM_ADD_GARAGE_LINK:
      if (!state.hasIn(['conversations', action.payload.channel])) return state;
      return state.setIn(
        ['conversations', action.payload.channel, 'agentLastTypedAt'],
        moment()
      );
    case types.SEND_FORM_REMOVE_GARAGE_LINK:
      if (!state.hasIn(['conversations', action.payload.channel])) return state;
      return state.setIn(
        ['conversations', action.payload.channel, 'agentLastTypedAt'],
        moment()
      );
      // transfers
    case types.SHOW_TRANSFER_PANEL:
      return state.setIn(['conversations', action.payload, 'transferPanelEnabled'], true);
    case types.HIDE_TRANSFER_PANEL:
      return state.setIn(['conversations', action.payload, 'transferPanelEnabled'], false);
    case types.TOGGLE_TRANSFER_PANEL:
      return state.setIn(
        ['conversations', action.payload, 'transferPanelEnabled'],
        !state.getIn(['conversations', action.payload, 'transferPanelEnabled'])
      );
    case types.REQUESTING_TRANSFER:
      return state.setIn(['conversations', action.payload, 'isRequestingTransfer'], true);
    case types.REQUESTED_TRANSFER:
    case types.REQUESTED_TRANSFER_ERROR:
      return state.setIn(['conversations', action.payload, 'isRequestingTransfer'], false);
    case types.AGENT_ACCEPTED:
      return agentAccepted(state, action.payload);
    case types.AGENT_LEFT:
      return agentLeft(state, action.payload);

      // crm sending
    case types.CRM_SENT:
      if (!state.hasIn(['conversations', action.payload.channel])) return state;
      return state.setIn(['conversations', action.payload.channel, 'sentToCrm'], true);

    case types.AGENT_CHAT_STARTED:
      return agentChatStarted(state, action.payload);

      // glove compartment
    case types.SET_GC_SEARCH_FILTER:
      return state.setIn(
        ['conversations', action.payload.channel, 'searchParams', 'filter'],
        action.payload.filter
      );
    case types.SET_GC_SEARCH_PHRASE:
      return state.setIn(
        ['conversations', action.payload.channel, 'searchParams', 'omnibox'],
        action.payload.searchPhrase
      );
    case types.SET_GC_VEHICLE_DETAILS_SEARCH_PARAMS:
      return state.setIn(
        ['conversations', action.payload.channel, 'searchParams', 'vehicle'],
        new VehicleSearchParams(action.payload.vehicleDetails)
      );
    case types.GC_SEARCH_PENDING:
      return handleSearchPending(state, action.payload);
    case types.CALCULATOR_PENDING:
      return state.setIn(['conversations', action.payload.channel, 'searchPending'], true);
    case types.GC_SEARCH_SUCCESS:
    case types.GC_SEARCH_ERROR:
    case types.CALCULATOR_SUCCESS:
    case types.CALCULATOR_ERROR:
      return handleSearchFinished(state, action.payload);
    case types.CONVERSATION_SET_CRM_NOTES:
      return state.setIn(
        ['conversations', action.payload.channel, 'crmNotes'],
        action.payload.notes
      );
    case types.CONVERSATION_SET_CRM_SEND_TYPE:
      return state.setIn(
        ['conversations', action.payload.channel, 'crmSendType'],
        action.payload.type
      );
    case types.RESET_SET_SENT_TO_CRM:
      return state.setIn(['conversations', action.payload.channel, 'sentToCrm'], false);
    case types.CONVERSATION_SCORES_FETCHED:
      return state.mergeIn(
        ['conversations', action.payload.channel, 'scores'],
        fromJS({ [action.payload.userId]: action.payload.scores })
      );

    case types.CONVERSATION_EVENT_TAKEN_OVER:
      return eventTakenOver(state, action.payload);

    case types.REMOVE_TEMP_MESSAGE:
      return removeTempMessage(state, action.payload.channel, action.payload.tempId);

      // call center messages for conversation history
    case types.CONVERSATION_DEALER_MESSAGES_FETCHING:
      return conversationDealerMessagesFetching(state, action.payload);
    case types.CONVERSATION_DEALER_MESSAGES_FETCHED:
      return conversationDealerMessagesFetched(state, action.payload);
    case types.CONVERSATION_DEALER_MESSAGES_FETCH_ERROR:
      return conversationDealerMessagesFetchError(state, action.payload);

    case Constants.CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_CANCELLED:
      return conversationAgentMessageDominantLanguagesCancelled(state, action.payload);

    case Constants.CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_EXCEPTION:
      return conversationAgentMessageDominantLanguagesException(state, action.payload);

    case Constants.CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_REQUESTED:
      return conversationAgentMessageDominantLanguagesRequested(state, action.payload);

    case Constants.CONVERSATION_AGENT_MESSAGE_DOMINANT_LANGUAGES_RESPONDED:
      return conversationAgentMessageDominantLanguagesResponded(state, action.payload);

    case Constants.CONVERSATION_AGENT_MESSAGE_TRANSLATE_CANCELLED:
      return conversationAgentMessageTranslateCancelled(state, action.payload);

    case Constants.CONVERSATION_AGENT_MESSAGE_TRANSLATE_EXCEPTION:
      return conversationAgentMessageTranslateException(state, action.payload);

    case Constants.CONVERSATION_AGENT_MESSAGE_TRANSLATE_REQUESTED:
      return conversationAgentMessageTranslateRequested(state, action.payload);

    case Constants.CONVERSATION_AGENT_MESSAGE_TRANSLATE_RESPONDED:
      return conversationAgentMessageTranslateResponded(state, action.payload);

    case Constants.CONVERSATION_TRANSLATE_LANGUAGE_SOURCE_UPDATE:
      return conversationTranslateLanguageSourceUpdate(state, action.payload);

    case Constants.CONVERSATION_TRANSLATE_PLUGIN_ENABLED_TOGGLE:
      return conversationTranslatePluginEnabledToggle(state, action.payload);

    case Constants.CONVERSATION_TRANSLATE_PLUGIN_VISIBLE_TOGGLE:
      return conversationTranslatePluginVisibleToggle(state, action.payload);

    default:
      return state;
  }
}

function conversationAgentMessageDominantLanguagesCancelled(
  state, { batchKey, conversationChannel, messages }
) {
  return state.setIn(['conversations', conversationChannel, 'translationLanguageDetections', batchKey], {
    data: state.getIn(['conversations', conversationChannel, 'translationLanguageDetections', batchKey, 'data'], null),
    errors: [],
    loading: false,
    messageIds: messages.map(([id]) => id),
  });
}

function conversationAgentMessageDominantLanguagesException(
  state, { batchKey, conversationChannel, errors, messages }
) {
  return state.setIn(['conversations', conversationChannel, 'translationLanguageDetections', batchKey], {
    data: state.getIn(['conversations', conversationChannel, 'translationLanguageDetections', batchKey, 'data'], null),
    errors,
    loading: false,
    messageIds: messages.map(([id]) => id),
  });
}

function conversationAgentMessageDominantLanguagesRequested(
  state, { batchKey, conversationChannel, messages }
) {
  const data = [moment(), JSON.stringify({ batchKey, conversationChannel, messages })];

  const logs = state.getIn(['conversations', conversationChannel, 'translationPluginRequestLogsLanguageDetections'], []);
  const logsUpdate = [data, ...logs].slice(0, 10);

  state = state.setIn(['conversations', conversationChannel, 'translationPluginRequestLogsLanguageDetections'], logsUpdate);

  state = state.setIn(['conversations', conversationChannel, 'translationLanguageDetections', batchKey], {
    data: state.getIn(['conversations', conversationChannel, 'translationLanguageDetections', batchKey, 'data'], null),
    errors: [],
    loading: true,
    messageIds: messages.map(([id]) => id),
  });

  // add reverse lookup
  messages.forEach(([messageId]) => {
    state = state.setIn(['conversations', conversationChannel, 'messages', messageId, 'languageDetectionBatchKey'], batchKey);
  });

  return state;
}

function conversationAgentMessageDominantLanguagesResponded(
  state, { batchKey, conversationChannel, messages, response }
) {
  return state.setIn(['conversations', conversationChannel, 'translationLanguageDetections', batchKey], {
    data: get(response, 'data.languages', []),
    errors: [],
    loading: false,
    messageIds: messages.map(([id]) => id),
  });
}

function conversationAgentMessageTranslateCancelled(
  state, { conversationChannel, languageSource, languageTarget, messages }
) {
  messages.forEach(([messageId]) => {
    state = state
      .setIn(['conversations', conversationChannel, 'messages', messageId, 'translations', languageSource, languageTarget, 'loading'], false);
  });
  return state;
}

function conversationAgentMessageTranslateRequested(
  state, { conversationChannel, languageSource, languageTarget, messages }
) {
  const data = [moment(), JSON.stringify({ conversationChannel, languageSource, languageTarget, messages })];

  const logs = state.getIn(['conversations', conversationChannel, 'translationPluginRequestLogsLanguageTranslations'], []);
  const logsUpdate = [data, ...logs].slice(0, 10);

  state = state.setIn(['conversations', conversationChannel, 'translationPluginRequestLogsLanguageTranslations'], logsUpdate);

  messages.forEach(([messageId]) => {
    state = state
      .setIn(['conversations', conversationChannel, 'messages', messageId, 'translations', languageSource, languageTarget, 'errors'], [])
      .setIn(['conversations', conversationChannel, 'messages', messageId, 'translations', languageSource, languageTarget, 'loading'], true);
  });

  return state;
}

function conversationAgentMessageTranslateException(
  state, { conversationChannel, errors, languageSource, languageTarget, messages }
) {
  messages.forEach(([messageId]) => {
    state = state
      .setIn(['conversations', conversationChannel, 'messages', messageId, 'translations', languageSource, languageTarget, 'errors'], errors)
      .setIn(['conversations', conversationChannel, 'messages', messageId, 'translations', languageSource, languageTarget, 'loading'], false);
  });
  return state;
}

function conversationAgentMessageTranslateResponded(
  state, { conversationChannel, languageSource, languageTarget, messages, response }
) {
  const data = get(response, 'data.translatedMessages', []);
  messages.forEach(([messageId], key) => {
    state = state
      .setIn(['conversations', conversationChannel, 'messages', messageId, 'translations', languageSource, languageTarget, 'data'], data[key])
      .setIn(['conversations', conversationChannel, 'messages', messageId, 'translations', languageSource, languageTarget, 'loading'], false);
  });
  return state;
}

function conversationTranslateLanguageSourceUpdate(state, { conversationChannel, languageCode }) {
  return state.setIn(['conversations', conversationChannel, 'translationLanguageSource'], languageCode);
}

function conversationTranslatePluginEnabledToggle(state, conversationChannel) {
  const update = !state.getIn(['conversations', conversationChannel, 'translationPluginEnabled'], false);
  return state.setIn(['conversations', conversationChannel, 'translationPluginEnabled'], update);
}

function conversationTranslatePluginVisibleToggle(state, conversationChannel) {
  const update = !state.getIn(['conversations', conversationChannel, 'translationPluginVisible'], false);
  return state.setIn(['conversations', conversationChannel, 'translationPluginVisible'], update);
}

function eventTakenOver(state, payload) {
  const channelName = payload.channel || false;

  if (!channelName) {
    return state;
  }

  return state.setIn(['conversations', channelName, 'wasTakenOverFromCallCenter'], true);
}

function currentConversationsFetched(state, payload) {
  const conversations = payload.conversations.concat(payload.internalConversations);

  state = addNewConversations(state, conversations);
  state = removeStaleInternalConversations(state, conversations);

  state = state.set('currentConversationsFetched', true);
  return state.set('isFetchingCurrentConversations', false);
}

function addNewConversations(state, conversations) {
  conversations.forEach(data => {
    state = addConversation(state, Map(normalizeConversationSerializerData(data)));
  });

  return state;
}

/**
 * Remove internal convos from store if they aren't in the API current conversations list
 */
function removeStaleInternalConversations(state, payloadConvos) {
  const validConversationChannels = payloadConvos.map(convo => convo.channel);
  const existingInternalConvos = state
    .get('conversations')
    .filter(convo => convo.get('isInternal'));

  existingInternalConvos.forEach((convo, channel) => {
    if (
      !includes(validConversationChannels, channel) &&
            channel !== state.get('activeConversation')
    ) {
      state = state.deleteIn(['conversations', channel]);
    }
  });

  return state;
}

function conversationFetched(state, { data }) {
  return addConversation(state, Map(normalizeConversationSerializerData(data)));
}

function normalizeConversationSerializerData(data) {
  return {
    channel: data.channel,
    type: data.type,
    locationId: data.location.id,
    teamId: data.teamId,
    queueWaitTime: data.queueWaitTime,
    isInternal: data.isInternal,
    isVideo: data.isVideo,
    isEnded: data.isEnded,
    visitorId: data.visitor ? data.visitor.id : null,
    userIds: Set(data.involvedAgents.map(agent => agent.id)),
    allUserIds: Set(data.allAgents.map(agent => agent.id)),
    startDate: moment(data.startDate),
    endDate: data.endDate ? moment(data.endDate) : null,
    acceptedDate: moment(data.acceptedDate),
    dateOnSite: data.dateOnSite ? moment(data.dateOnSite) : null,
    lastMessageDate: data.lastMessageDate ? moment(data.lastMessageDate) : null,
    messages: Map(),
    video: new VideoSettings(),
    openAIState: data.openAIState,
  };
}

function activateConversation(state, channel) {
  state = state.set('activeConversation', channel);
  state = state.setIn(['conversations', channel, 'hotkeyPanelActive'], false);
  state = state.setIn(['conversations', channel, 'visitorIsPresent'], true);
  state = state.setIn(['conversations', channel, 'visitorPresentAt'], moment());
  return state.setIn(['conversations', channel, 'transferPanelEnabled'], false);
}

function currentConversationsFetchError(state, payload) {
  return state.set('isFetchingCurrentConversations', false);
}

function acceptedOffer(state, { offer, userId }) {
  return addConversation(state, Map(normalizeOfferData(offer, userId)));
}

/**
 * convert offer map to data used to create a conversation
 * @param {Map} offer
 * @param {int} userId
 * @returns {object}
 */
function normalizeOfferData(offer, userId) {
  const data = {
    channel: offer.get('channel'),
    type: offer.get('conversationType'),
    locationId: offer.get('locationId'),
    teamId: offer.get('teamId'),
    queueWaitTime: moment().diff(offer.get('startDate'), 'seconds'),
    isVideo: offer.get('isVideo'),
    isEnded: false,
    visitorId: offer.get('visitorId'),
    startDate: offer.get('startDate'),
    acceptedDate: moment(),
    dateOnSite: offer.get('siteSessionStartedDate')
      ? moment(offer.get('siteSessionStartedDate'))
      : null,
    messages: Map(),
  };

  let userIds = Set([userId]);
  if (offer.get('fromUserId')) {
    userIds = userIds.add(offer.get('fromUserId'));
  }
  data.userIds = userIds;
  data.allUserIds = userIds;

  return data;
}

function transcriptFetching(state, { channel, isPreviousPage }) {
  if (!state.hasIn(['conversations', channel])) return state;

  if (isPreviousPage) {
    state = state.setIn(['conversations', channel, 'previousTranscriptFetching'], true);
  }
  return state.setIn(['conversations', channel, 'transcriptFetching'], true);
}

function transcriptFetched(state, { channel, response: { transcript }, limit, isPreviousPage }) {
  if (!state.hasIn(['conversations', channel])) return state;

  return state.withMutations(state => {
    transcript.forEach(message => {
      addMessage(state, channel, message);
    });
    if (isPreviousPage) {
      state.setIn(['conversations', channel, 'previousTranscriptFetching'], false);
    }
    state.setIn(['conversations', channel, 'transcriptFetching'], false);
    state.setIn(['conversations', channel, 'transcriptFetched'], true);
    if (transcript.length < limit) {
      state.setIn(['conversations', channel, 'hasOldestMessage'], true);
    }
  });
}

function transcriptFetchError(state, { channel, isPreviousPage }) {
  if (!state.hasIn(['conversations', channel])) return state;

  if (isPreviousPage) {
    state = state.setIn(['conversations', channel, 'previousTranscriptFetching'], false);
  }
  return state.setIn(['conversations', channel, 'transcriptFetching'], false);
}

function addMessage(
  state,
  channel,
  data,
  confirmed = true,
  translationPlugin = null,
  translation = null
) {
  if (!state.hasIn(['conversations', channel])) return state;

  const translationEnabled =
        translationPlugin &&
        translationPlugin.enabled &&
        translationPlugin.languageSource &&
        translationPlugin.languageTarget &&
        translation &&
        translation.source &&
        translation.target;

  const newMessage = normalizeMessageData(
    data,
    confirmed,
    translationEnabled ? {
      languageSource: translationPlugin.languageSource,
      languageTarget: translationPlugin.languageTarget,
      source: translation.source,
      target: translation.target,
    } : undefined,
  );

  state = state.mergeIn(['conversations', channel, 'messages', data.messageId], Map(newMessage));

  // add read receipts
  if (data.readReceipts) {
    const readReceipts = data.readReceipts.map(readReceipt =>
      Map({
        userId: readReceipt.userId,
        visitorId: readReceipt.visitorId,
        timestamp: moment(readReceipt.timestamp),
      })
    );
    state = state.setIn(
      ['conversations', channel, 'messages', data.messageId, 'readReceipts'],
      List(readReceipts)
    );
  }

  return state;
}

function pusherNewReadReceipts(state, { channel, payload }) {
  if (!state.hasIn(['conversations', channel])) return state;

  const userId = payload.personType === 'agent' ? payload.personId : null;
  const visitorId = payload.personType === 'visitor' ? payload.personId : null;
  const timestamp = moment();

  payload.messageIds.forEach(messageId => {
    if (state.hasIn(['conversations', channel, 'messages', messageId])) {
      state = state.updateIn(
        ['conversations', channel, 'messages', messageId, 'readReceipts'],
        readReceipts => readReceipts.push(Map({ userId, visitorId, timestamp }))
      );
    }
  });

  return state;
}

/**
 * API transcript data and pusher new message data is not the same.  This method normalizes it.
 */
function normalizeMessageData(data, confirmed = true, translation = undefined) {
  const message = {
    id: data.messageId,
    body: data.body || data.messageText,
    userId: data.agentId || (data.fromType === 'agent' ? data.fromId : null),
    category: data.category,
    timestamp: data.timestamp ? moment(data.timestamp) : moment(),
    type: data.type,
    conversationChannel: data.conversationChannel || data.channel,
    conversationId: data.conversationId,
    conversationType: data.conversationType,
    visitorId: data.visitorId || (data.fromType === 'visitor' ? data.fromId : null),
    confirmed,
    readReceipts: List(),
    attachments: normalizeAttachments(data.attachments),
    garageLink: data.garageLink
  };

  if (translation) {
    message.body = translation.target;

    const translations = Map();

    message.translations = translations.setIn([translation.languageSource, translation.languageTarget], Map({
      data: translation.source,
      errors: [],
      loading: false,
    }));
  }

  return message;
}

/**
 * Normalize attachments based on type. Attachments from Pusher are a json-encoded string.  Attachments from transcript fetch are javascript objects.
 * @param attachments
 * @returns List
 */
export function normalizeAttachments(attachments) {
  try {
    if (!attachments) attachments = [];
    if (!isObject(attachments)) {
      attachments = JSON.parse(attachments);
    }
    return List(attachments);
  } catch (e) {
    return List();
  }
}

function pusherNewMessage(state, data) {
  if (!state.hasIn(['conversations', data.channel])) return state;
  state = addMessage(state, data.channel, data);
  return state.setIn(['conversations', data.channel, 'isComposing'], false);
}

function messageSending(state, { channel, data, translationPlugin, message }) {
  return addMessage(state, channel, data, false, translationPlugin, message);
}

function messageSent(state, { channel, tempId, messageId }) {
  let original = state.getIn(['conversations', channel, 'messages', tempId]);
  original = original.set('id', messageId);
  original = original.set('confirmed', true);
  state = state.setIn(['conversations', channel, 'messages', messageId], original);
  state = state.deleteIn(['conversations', channel, 'messages', tempId]);
  state = state.setIn(['conversations', channel, 'waitingOnMe'], false);
  if (original.get('category') !== 'presence') {
    state = state.setIn(['conversations', channel, 'lastMessageDate'], moment());
  }
  return state;
}

function messageSendError(state, { channel, tempId, errorCode = null }) {
  if (!state.hasIn(['conversations', channel])) return state;
  return state.setIn(['conversations', channel, 'messages', tempId, 'errorCode'], errorCode);
}

function endingConversation(state, channel) {
  if (!state.hasIn(['conversations', channel])) return state;

  state = state.setIn(['conversations', channel, 'ending'], true);
  return state.setIn(['conversations', channel, 'confirmingEnding'], false);
}

function endedConversation(state, channel) {
  if (!state.hasIn(['conversations', channel])) return state;

  return state.withMutations(state => {
    state.setIn(['conversations', channel, 'endDate'], moment());
    state.setIn(['conversations', channel, 'isEnded'], true);
    state.setIn(['conversations', channel, 'ending'], false);
    state.setIn(['conversations', channel, 'confirmingEnding'], false);
  });
}

function closeConversation(state, channel) {
  if (!state.hasIn(['conversations', channel])) return state;

  state = state.set('activeConversation', null);
  return state.deleteIn(['conversations', channel]);
}

function visitorChannelPresence(state, { channel, hasVisitor }) {
  if (!state.hasIn(['conversations', channel])) return state;
  if (hasVisitor) {
    state = state.setIn(['conversations', channel, 'visitorPresentAt'], moment());
    state = state.setIn(['conversations', channel, 'visitorIsPresent'], true);
  } else {
    // don't set visitorIsPresent to false until visitor has been away for at least 20 seconds
    const lastSeen = state.getIn(['conversations', channel, 'visitorPresentAt']);
    if (lastSeen && lastSeen.isBefore(moment().subtract(20, 'seconds'))) {
      state = state.setIn(['conversations', channel, 'visitorIsPresent'], false);
    }
  }
  return state;
}

function markedMessagesRead(state, { channel, messageIds, loggedInUserId }) {
  if (!state.hasIn(['conversations', channel])) return state;

  messageIds.forEach(messageId => {
    state = state.updateIn(
      ['conversations', channel, 'messages', messageId, 'readReceipts'],
      readReceipts =>
        readReceipts.push(
          Map({
            userId: loggedInUserId,
            timestamp: moment(),
          })
        )
    );
  });

  return state;
}

function agentAccepted(state, { channel, user }) {
  if (!state.hasIn(['conversations', channel])) return state;

  return state.updateIn(['conversations', channel, 'userIds'], userIds =>
    userIds.add(user.userId)
  );
}

function agentLeft(state, { channel, user }) {
  if (!state.hasIn(['conversations', channel])) return state;

  if (state.getIn(['conversations', channel, 'isInternal'])) return state;

  return state.updateIn(['conversations', channel, 'userIds'], userIds =>
    userIds.delete(user.id)
  );
}

function agentChatStarted(state, { userId, channel, locationId, loggedInUserId }) {
  return addConversation(
    state,
    Map({
      channel,
      isEnded: false,
      locationId,
      isInternal: true,
      userIds: Set([userId, loggedInUserId]),
      allUserIds: Set([userId, loggedInUserId]),
      messages: Map(),
      startDate: moment(),
      acceptedDate: moment(),
    })
  );
}

function leavingConversation(state, channel) {
  if (!state.hasIn(['conversations', channel])) return state;

  state = state.setIn(['conversations', channel, 'leaving'], true);
  return state.setIn(['conversations', channel, 'confirmingLeaving'], false);
}

function leavingConversationError(state, channel) {
  if (!state.hasIn(['conversations', channel])) return state;

  return state.setIn(['conversations', channel, 'leaving'], false);
}

function handleSearchFinished(state, { channel }) {
  if (!state.hasIn(['conversations', channel])) return state;

  state = state.setIn(['conversations', channel, 'searchPending'], false);
  return state.setIn(['conversations', channel, 'searchMorePending'], false);
}

function handleSearchPending(state, { channel, page }) {
  if (!state.hasIn(['conversations', channel])) return state;

  if (page === '1') {
    return state.setIn(['conversations', channel, 'searchPending'], true);
  } else {
    return state.setIn(['conversations', channel, 'searchMorePending'], true);
  }
}

function toggleHotkeyPanel(state, { channel }) {
  if (!state.hasIn(['conversations', channel])) return state;

  const curValue = state.getIn(['conversations', channel, 'hotkeyPanelActive'], false);
  return state.setIn(['conversations', channel, 'hotkeyPanelActive'], !curValue);
}

function toggleExplodeToText(state, { channel }) {
  if (!state.hasIn(['conversations', channel])) return state;

  const curValue = state.getIn(['conversations', channel, 'explodeToTextActive'], false);
  return state.setIn(['conversations', channel, 'explodeToTextActive'], !curValue);
}

function setConversationUserIds(state, { channelName, userIds }) {
  const data = Set(Array.isArray(userIds) ? userIds : [userIds]);

  const allUserIds = state.getIn(['conversations', channelName, 'allUserIds'], []).merge(data);

  return state
    .setIn(['conversations', channelName, 'allUserIds'], allUserIds)
    .setIn(['conversations', channelName, 'userIds'], data);
}

// call center messages for conversation history
function conversationDealerMessagesFetching(state, { channel }) {
  return state
    .setIn(['conversations', channel, 'dealerMessages', 'fetching'], true);
}

function conversationDealerMessagesFetched(state, { channel, dealerMessages }) {
  return state
    .setIn(['conversations', channel, 'dealerMessages', 'loading'], false)
    .setIn(['conversations', channel, 'dealerMessages', 'data'], dealerMessages);
}

function conversationDealerMessagesFetchError(state, { channel, error }) {
  return state
    .setIn(['conversations', channel, 'dealerMessages', 'loading'], false)
    .updateIn(['conversations', channel, 'dealerMessages', 'errors'], errors => errors.concat(error));
}

function removeTempMessage(state, channel, tempId) {
  return state.deleteIn(['conversations', channel, 'messages', tempId]);
}
