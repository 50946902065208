import { createSelector } from 'reselect';

export const getInternalNotificationChannels = state =>
  state.channels.getIn(['notification', 'internal']);
export const getExternalNotificationChannels = state =>
  state.channels.getIn(['notification', 'external']);
export const getSubscribedChannels = state => state.channels.get('subscribed');

export const getAllNotificationChannels = createSelector(
  [getInternalNotificationChannels, getExternalNotificationChannels],
  (internal, external) => internal.concat(external)
);
