import Immutable, { Map, List } from 'immutable';
import * as types from '../constants/actionTypes';

// keyed by channel name with the structure listed in base
const initialState = Map();

const base = Map({
  text: '',
  attachments: List(),
});

export default function sendForm(state = initialState, { type, payload }) {
  switch (type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.CONVERSATION_ACTIVATE:
      return conversationActivated(state, payload);
    case types.SEND_FORM_ADD_ATTACHMENT:
      return state.updateIn([payload.channel, 'attachments'], attachments =>
        attachments.push(Immutable.fromJS(payload.attachment))
      );
    case types.SEND_FORM_REMOVE_ATTACHMENT:
      return removeAttachment(state, payload);
    case types.MESSAGE_SENDING:
      return state.setIn([payload.channel], base);
    case types.SEND_FORM_UPDATE_TEXT:
      return state.setIn([payload.channel, 'text'], payload.text);
    case types.SEND_FORM_ADD_GARAGE_LINK:
      return state
        .setIn([payload.channel, 'garageLink'], payload.garageLink)
        .setIn([payload.channel, 'uuid'], payload.uuid);
    case types.SEND_FORM_REMOVE_GARAGE_LINK:
      return state
        .setIn([payload.channel, 'garageLink'], payload.garageLink)
        .setIn([payload.channel, 'uuid'], payload.uuid);
    default:
      return state;
  }
}

/**
 * create base structure for this conversation if it doesn't already exist, otherwise do nothing
 * @param state
 * @param channel
 * @returns {*}
 */
function conversationActivated(state, channel) {
  if (state.has(channel)) return state;
  return state.set(channel, base);
}

function removeAttachment(state, { channel, id }) {
  return state.updateIn([channel, 'attachments'], attachments =>
    attachments.filter(attachment => !(id === attachment.get('id')))
  );
}
