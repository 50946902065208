import { Map } from 'immutable';

export function getFullName(visitor) {
  if (visitor === undefined) return 'Unknown Visitor';

  if (!Map.isMap(visitor)) {
    visitor = Map(visitor);
  }
  if (!visitor.get('firstName') && !visitor.get('lastName')) {
    return 'Unknown Visitor';
  }
  return `${visitor.get('firstName', '') || ''} ${visitor.get('lastName', '') || ''}`.trim();
}

export default {};
