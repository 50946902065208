import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { en } from '../../i18n/locales';

const resources = {
  'en': { translation: en },
  // 'es': { translation: es },
  // 'fr': { translation: fr },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    // https://github.com/i18next/i18next-browser-languageDetector#detector-options
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
    fallbackLng: 'en',
    interpolation: {
      // react already safes from xss
      escapeValue: false,
    },
    keySeparator: false,
    resources,
  });
