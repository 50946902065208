import { List, Map } from 'immutable';
import * as types from '../constants/actionTypes';

const initialState = Map({
  gptReviewsFetching: false,
  gptReviewsUpdating: false,
  gptReviews: List(),
  numberPages: 0
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GPT_REVIEWS_FETCHING:
      return state.set('gptReviewsFetching', true);
    case types.GPT_REVIEWS_FETCHED:
      return gptReviewsFetched(state, action.payload);
    case types.GPT_REVIEWS_FETCH_ERROR:
      return state.set('gptReviewsFetching', false);
    case types.GPT_REVIEW_UPDATING:
      return state.set('gptReviewsUpdating', true);
    case types.GPT_REVIEW_UPDATED:
      return updateGptReviewedValue(state, action.payload);
    case types.GPT_REVIEW_UPDATE_ERROR:
      return state.set('gptReviewsUpdating', false);
    case types.GPT_PROMPT_VERSIONS_FETCHED:
      return gptPromptVersionsFetched(state, action.payload);
    case types.GPT_PROMPT_VERSIONS_FETCHING:
      return state.set('gptPromptVersionsFetching', true);
    case types.GPT_PROMPT_VERSIONS_FETCH_ERROR:
      return state.set('gptPromptVersionsFetching', false);
    default:
      return state;
  }
}

function gptReviewsFetched(state, payload) {
  return state
    .setIn(['gptReviews'], payload.gptReviews)
    .set('numberPages', payload.numberPages)
    .set('gptReviewsFetching', false);
}

function updateGptReviewedValue(state, payload) {
  const { id, reviewed } = payload;
  const gptReviews = state.get('gptReviews');
  const updatedGptReviews = gptReviews.map(gptReview => {
    if (gptReview.id === id) {
      gptReview.reviewed = reviewed;
    }
    return gptReview;
  });
  return state.set('gptReviews', updatedGptReviews).set('gptReviewsUpdating', false);
}

function gptPromptVersionsFetched(state, payload) {
  return state
    .setIn(['gptPromptVersions'], payload)
    .set('gptPromptVersionsFetching', false);
}
