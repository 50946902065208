import {
  getAllConversations,
  receivedMessagesSelector,
  getActiveConversation,
} from 'selectors/conversationSelectors';
import {
  isChromeExtensionInstalled,
  getChromeExtensionConfig,
  isElectron,
} from 'selectors/configSelectors';
import { getTheme } from 'selectors/userSelectors';
import { getAllOffers } from 'selectors/offerSelectors';
import { getFullName as getFullVisitorName } from 'businessLogic/visitorHelper';
import { getFullName } from 'businessLogic/userHelper';
import { acceptOffer, declineOffer } from './offerActions';
import { activateConversation } from './conversationsActions';

/**
 * Display Chrome extension, electron or desktop notification for new message
 */
export function displayExternalNewMessageNotification(channel, isReminder = false) {
  return (dispatch, getState) => {
    const state = getState();
    const convos = getAllConversations(state);
    const convo = convos.get(channel);
    const getReceivedMessages = receivedMessagesSelector(state);
    const message = getReceivedMessages(channel).last();
    const themeId = getTheme(state);
    if (!convo || !message) return Promise.resolve();

    if (isChromeExtensionInstalled(state)) {
      const extensionConfig = getChromeExtensionConfig(state);
      dispatch(
        displayExtensionNewMessage(convo, message, extensionConfig, themeId, isReminder)
      );
    } else if (isElectron(state)) {
      dispatch(displayElectronNewMessage(convo, message, themeId, isReminder));
    } else {
      // todo: HTML5 Desktop notifications
    }

    return Promise.resolve();
  };
}

export function hideExternalNewMessageNotifications() {
  return (dispatch, getState) => {
    const state = getState();

    if (isChromeExtensionInstalled(state)) {
      dispatch(hideMessagesChromeExtension());
    } else if (isElectron(state)) {
      hideMessagesElectron();
    } else {
      // todo
    }
  };
}

/**
 * Display Chrome extension, electron or desktop notification for this offer
 */
export function displayExternalOfferNotification(channel) {
  return (dispatch, getState) => {
    const state = getState();
    const offer = getAllOffers(state).get(channel);
    const themeId = getTheme(state);
    if (offer) {
      // make sure offer is still valid, since this method could be called from setTimeout
      if (isChromeExtensionInstalled(state)) {
        const extensionConfig = getChromeExtensionConfig(state);

        dispatch(displayChromeExtensionOfferNotification(offer, extensionConfig, themeId));
      } else if (isElectron(state)) {
        dispatch(displayElectronOffer(offer, themeId));
      } else {
          // todo: HTML5 Desktop notifications
      }
    }

    return Promise.resolve();
  };
}

export function hideExternalOfferNotification(channel) {
  return (dispatch, getState) => {
    const state = getState();
    if (isChromeExtensionInstalled(state)) {
      dispatch(hideChromeOffer(channel));
    } else if (isElectron(state)) {
      hideElecronOffer(channel);
    } else {
      // todo
    }
  };
}

function displayElectronNewMessage(convo, message, themeId, isReminder) {
  const { ipcRenderer } = window.require('electron');

  return (dispatch, getState) => {
    ipcRenderer.send('new-message', buildMessageData(convo, message, themeId, isReminder));
    ipcRenderer.once('message-view', (event, data) => {
      // activate the conversation if it's not already activated
      const activeConvo = getActiveConversation(getState());
      if (!activeConvo || activeConvo.get('channel') !== data.meta.channel) {
        dispatch(activateConversation(data.meta.channel));
      }
    });
  };
}

function displayExtensionNewMessage(convo, message, config, themeId, isReminder) {
  return (dispatch, getState) => {
    global.chrome.runtime.sendMessage(
      config.get('id'),
      buildMessageData(convo, message, themeId, isReminder),
      {},
      handleExtensionResponse.bind(null, dispatch, getState, convo)
    );
  };
}

function handleExtensionResponse(dispatch, getState, convo, response) {
  let action;
  let activeConvo;
  if (response) action = response.action;

  switch (action) {
    case 'viewMessage':
      // activate the conversation if it's not already activated
      activeConvo = getActiveConversation(getState());
      if (!activeConvo || activeConvo.get('channel') !== convo.get('channel')) {
        dispatch(activateConversation(convo.get('channel')));
      }
      break;
    case 'ignore':
      // do nothing
      break;
    case undefined:
      // extension was closed by user or our app
      break;
    default:
      throw new Error(`Unknown response from Chrome extension ${action}`);
  }
}

const getScreenDimensions = () => {
  const { screen } = window;
  const { width, height} = screen;
  return { width, height };
};

function buildMessageData(convo, message, themeId, isReminder) {
  const fromName = message.get('user')
    ? getFullName(message.get('user'))
    : getFullVisitorName(message.get('visitor'));
  const screen = getScreenDimensions();
  return {
    action: 'newMessage',
    apiVersion: 2,
    app: 'call-center',
    themeId,
    meta: {
      channel: convo.get('channel'),
      title: isReminder
        ? `${fromName} is waiting for your reply`
        : `New message from ${fromName}`,
      message: message.get('body'),
      lastMessageDate: message.get('timestamp').format(),
      locationName: convo.getIn(['location', 'name']),
      locationAvatar: convo.getIn(['location', 'avatar']),
    },
    screen,
  };
}

function hideMessagesChromeExtension() {
  return (dispatch, getState) => {
    const extensionConfig = getChromeExtensionConfig(getState());
    global.chrome.runtime.sendMessage(extensionConfig.get('id'), {
      action: 'cancelMessages',
      apiVersion: 2,
    });
  };
}

function hideMessagesElectron() {
  const { ipcRenderer } = window.require('electron');
  ipcRenderer.send('message-notification-hide');
}

function displayChromeExtensionOfferNotification(offer, config, themeId) {
  return dispatch => {
    global.chrome.runtime.sendMessage(
      config.get('id'),
      buildOfferData(offer, themeId),
      {},
      handleExtensionOfferResponse.bind(null, dispatch, offer)
    );
  };
}

function displayElectronOffer(offer, themeId) {
  const { ipcRenderer } = window.require('electron');

  return dispatch => {
    ipcRenderer.send('new-offer', buildOfferData(offer, themeId));
    ipcRenderer.once('offer-accept', (event, data) => {
      dispatch(acceptOffer(data.meta.channel));
    });
    ipcRenderer.once('offer-snooze', (event, data) => {
      dispatch(declineOffer(data.meta.channel));
      setTimeout(() => dispatch(displayExternalOfferNotification(data.meta.channel)), 15000);
    });
  };
}

/**
 * Build the payload that will be sent to the external notifier
 */
function buildOfferData(offer, themeId) {
  return {
    action: 'showOffer',
    apiVersion: 2,
    app: 'call-center',
    themeId,
    isPriority: offer.get('isPriority'),
    meta: { channel: offer.get('channel') },
    heading: getNotificationTitle(offer),
    message: getNotificationMessage(offer),
    buttons: [
      {
        text: 'Snooze',
        css: 'btn btn-link',
        returnValue: 'decline',
      },
      {
        text: 'Accept',
        css: 'btn btn-success btn-large',
        returnValue: 'accept',
      },
    ],
    sound: true,
    screen: getScreenDimensions(),
  };
}

function getNotificationTitle(offer) {
  let title = `Conversation Invitation from ${offer.getIn(['location', 'name'])}`;
  if (offer.get('team')) {
    title = `${title} (${offer.getIn(['team', 'name'])})`;
    if (offer.getIn(['team', 'isPriority'])) {
      title = `Priority ${title}`;
    }
  }

  return title;
}

function getNotificationMessage(offer) {
  if (offer.get('type') === 'transfer') {
    return `Transfer request from ${getFullName(offer.get('fromUser'))}`;
  }

  if (!offer.get('visitor') || !offer.get('initialQuestion')) return '';
  return `${getFullVisitorName(offer.get('visitor'))} said "${offer.get('initialQuestion')}"`;
}

function handleExtensionOfferResponse(dispatch, offer, response) {
  let action;
  if (response) action = response.action;

  switch (action) {
    case 'accept':
      dispatch(acceptOffer(offer.get('channel')));
      break;
    case 'decline':
      dispatch(declineOffer(offer.get('channel')));
      setTimeout(
        () => dispatch(displayExternalOfferNotification(offer.get('channel'))),
        15000
      );
      break;
    case undefined:
      // extension was closed by user or our app
      setTimeout(
        () => dispatch(displayExternalOfferNotification(offer.get('channel'))),
        15000
      );
      break;
    default:
      throw new Error(`Unknown response from Chrome extension ${action}`);
  }
}

function hideChromeOffer(channel) {
  return (dispatch, getState) => {
    const extensionConfig = getChromeExtensionConfig(getState());
    global.chrome.runtime.sendMessage(extensionConfig.get('id'), {
      action: 'cancel',
      apiVersion: 2,
      meta: { channel },
    });
  };
}

function hideElecronOffer() {
  const { ipcRenderer } = window.require('electron');
  ipcRenderer.send('offer-notification-hide');
}