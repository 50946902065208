/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
import * as types from '../constants/actionTypes';
import { isPusherConnecting } from '../../selectors/pusherSelectors';
import { getLoggedInUserId } from '../../selectors/userSelectors';
import {
  getAllNotificationChannels,
  getExternalNotificationChannels,
  getInternalNotificationChannels,
  getSubscribedChannels,
} from '../../selectors/channelSelectors';
import { AWAY, AVAILABLE, DO_NOT_DISTURB } from '../reducers/statuses';
import { endedConversation } from '../actions/conversationsActions';

const CONTROL_CHANNEL = 'di-conversations-web-control';

export function connectToPusher() {
  return (dispatch, getState, { PusherManager, APIFactory }) => {
    if (isPusherConnecting(getState()) === true) {
      return;
    }
    dispatch(connectingToPusher());
    PusherManager.setStore(getState())
      .setAPIFactory(APIFactory)
      .connect()
      .then(() => dispatch(connectedToPusher()));
  };
}

export function disconnectFromPusher() {
  return (dispatch, getState, { PusherManager }) => {
    PusherManager.disconnect();
  };
}

function connectedToPusher() {
  return {
    type: types.CONNECTED_TO_PUSHER,
  };
}

function connectingToPusher() {
  return {
    type: types.CONNECTING_TO_PUSHER,
  };
}

/**
 * Join the appropriate notification channels based on status
 * @param {int} statusId
 */
export function joinByStatus(statusId) {
  return dispatch => {
    switch (statusId) {
      case AVAILABLE:
        dispatch(joinAllNotificationChannels());
        break;
      case AWAY:
        dispatch(joinInternalNotificationChannels());
        break;
      case DO_NOT_DISTURB:
        dispatch(leaveAllNotificationChannels());
        break;
      default:
        throw Error(`Unknown statusId ${statusId}`);
    }
  };
}

function joinNotificationChannels(channels, state, dispatch, PusherManager) {
  const currentlySubscribed = getSubscribedChannels(state);
  channels.forEach(channel => {
    if (channel && !currentlySubscribed.has(channel)) {
      const subscription = PusherManager.client.subscribe(channel);
      getNotificationChannelBindings().forEach(({ event, callback }) =>
        subscription.bind(event, callback.bind(null, { state, dispatch }))
      );
      dispatch(joinedNotificationChannel(channel));
    }
  });
  dispatch(joinedAllNotificationChannels());
}

function joinedAllNotificationChannels() {
  return { type: types.JOINED_ALL_NOTIFICATION_CHANNELS };
}

function leaveNotificationChannels(channels, state, dispatch, PusherManager) {
  const currentlySubscribed = getSubscribedChannels(state);
  channels.forEach(channel => {
    if (currentlySubscribed.has(channel)) {
      PusherManager.client.unsubscribe(channel);
      dispatch(leftNotificationChannel(channel));
    }
  });
}

export function joinAllNotificationChannels() {
  return (dispatch, getState, { PusherManager }) => {
    joinNotificationChannels(
      getAllNotificationChannels(getState()),
      getState(),
      dispatch,
      PusherManager
    );
  };
}

export function joinInternalNotificationChannels() {
  return (dispatch, getState, { PusherManager }) => {
    joinNotificationChannels(
      getInternalNotificationChannels(getState()),
      getState(),
      dispatch,
      PusherManager
    );
    leaveNotificationChannels(
      getExternalNotificationChannels(getState()),
      getState(),
      dispatch,
      PusherManager
    );
  };
}

export function leaveAllNotificationChannels() {
  return (dispatch, getState, { PusherManager }) => {
    leaveNotificationChannels(
      getAllNotificationChannels(getState()),
      getState(),
      dispatch,
      PusherManager
    );
  };
}

/**
 * Get pusher events to respond to and their respective callbacks
 */
function getNotificationChannelBindings() {
  return [
    { event: 'visitor-waiting', callback: pusherVisitorWaiting },
    { event: 'transfer-waiting', callback: pusherTransferWaiting },
    { event: 'team-transfer-waiting', callback: pusherTeamTransferWaiting },
    { event: 'offer-revoked', callback: pusherOfferRevoked },
    { event: 'visitor-profile-change', callback: pusherVisitorProfileUpdated },
  ];
}

/**
 * @private
 */
function pusherVisitorWaiting({ dispatch }, payload) {
  return dispatch({ type: types.PUSHER_VISITOR_WAITING, payload });
}

/**
 * @private
 */
function pusherTransferWaiting({ state, dispatch }, payload) {
  const loggedInUserId = getLoggedInUserId(state);
  if (payload.toUser.id === loggedInUserId) {
    return dispatch({ type: types.PUSHER_TRANSFER_WAITING, payload });
  }
  return undefined;
}

/**
 * @private
 */
function pusherTeamTransferWaiting({ state, dispatch }, payload) {
  const loggedInUserId = getLoggedInUserId(state);
  if (payload.fromUser.id !== loggedInUserId) {
    return dispatch({ type: types.PUSHER_TRANSFER_WAITING, payload });
  }
  return undefined;
}

/**
 * @private
 */
function pusherOfferRevoked({ dispatch }, payload) {
  return dispatch({ type: types.PUSHER_OFFER_REVOKED, payload });
}

function joinedNotificationChannel(channel) {
  return { type: types.JOINED_NOTIFICATION_CHANNEL, payload: channel };
}

function leftNotificationChannel(channel) {
  return { type: types.LEFT_NOTIFICATION_CHANNEL, payload: channel };
}

/**
 * Subscribe to this channel and attach event bindings
 * @param {string} channel
 * @param {bool} sendPresence
 */
export function joinConversationChannel(channel) {
  return (dispatch, getState, extras) => {
    const { PusherManager } = extras;

    const currentlySubscribed = getSubscribedChannels(getState());
    if (channel && !currentlySubscribed.has(channel)) {
      const subscription = PusherManager.client.subscribe(channel);

      getConversationChannelBindings().forEach(({ event, callback }) =>
        subscription.bind(
          event,
          callback.bind(null, { dispatch, getState, channel, extras })
        )
      );
      PusherManager.addSubscription(channel, subscription);

      dispatch(joinedConversationChannel(channel));
    }
  };
}

function joinedConversationChannel(channel) {
  return { type: types.JOINED_CONVERSATION_CHANNEL, payload: channel };
}

/**
 * Get pusher events to respond to and their respective callbacks
 */
function getConversationChannelBindings() {
  return [
    { event: 'new-message', callback: pusherNewMessage },
    { event: 'messages-read', callback: pusherMessagesRead },
    { event: 'conversation-ended', callback: pusherConversationEnded },
    { event: 'client-chat-status-notification', callback: pusherChatStatusNotification },
    { event: 'agent-accepted', callback: pusherAgentAccepted },
    { event: 'agent-left', callback: pusherAgentLeft },
    { event: 'embed-twilio-video-failure', callback: () => {
      // eslint-disable-next-line no-debugger
      debugger;
      }
    }
  ];
}

function pusherNewMessage({ dispatch, getState }, payload) {
  if (payload.type === 'takeover') {
    dispatch({ type: types.CONVERSATION_EVENT_TAKEN_OVER, payload });
  }

  // ignore messages sent by the logged in user from the same device, because they will already be included
  const fromMe = payload.fromType === 'agent' && payload.fromId === getLoggedInUserId(getState());
  if (!fromMe || (payload.device !== 'web-client' && payload.device !== 'call-center-web')) {
    // call-center-web is legacy and can be removed after existing tokens expire
    return dispatch({ type: types.PUSHER_NEW_MESSAGE, payload });
  }
  return undefined;
}

function pusherMessagesRead({ dispatch, channel }, payload) {
  return dispatch({ type: types.PUSHER_NEW_READ_RECEIPTS, payload: { channel, payload } });
}

function pusherConversationEnded(
  { dispatch, getState, channel, extras: { PusherManager } },
  payload
) {
  dispatch(leaveConversationChannel(channel));
  dispatch(endedConversation(channel));
}

function pusherChatStatusNotification({ dispatch, channel }, payload) {
  if (payload.status === 'composing') {
    dispatch(isComposing(channel, payload));
  } else {
    dispatch(isPaused(channel, payload));
  }
}

function pusherAgentAccepted({ dispatch, channel }, payload) {
  dispatch({ type: types.AGENT_ACCEPTED, payload: { channel, user: payload } });
}

function pusherAgentLeft({ dispatch, channel }, payload) {
  dispatch({ type: types.AGENT_LEFT, payload: { channel, user: payload } });
}

function isComposing(channel) {
  return { type: types.STATUS_COMPOSING, payload: channel };
}

function isPaused(channel) {
  return { type: types.STATUS_PAUSED, payload: channel };
}

export function leaveConversationChannel(channel) {
  return (dispatch, getState, { PusherManager }) => {
    const currentlySubscribed = getSubscribedChannels(getState());
    if (currentlySubscribed.has(channel)) {
      PusherManager.client.unsubscribe(channel);
      dispatch(leftConversationChannel(channel));
    }
  };
}

function leftConversationChannel(channel) {
  return { type: types.LEFT_CONVERSATION_CHANNEL, payload: channel };
}

export function getVisitorPresence(channel) {
  return (dispatch, getState, { PusherManager }) => {
    const subscription = PusherManager.getSubscription(channel);
    const hasVisitor = channelHasVisitor(subscription);
    dispatch({ type: types.VISITOR_CHANNEL_PRESENCE, payload: { channel, hasVisitor } });
  };
}

function channelHasVisitor(subscription) {
  let hasVisitor = false;
  subscription.members.each(member => {
    if (member.info && member.info.type === 'visitor') {
      hasVisitor = true;
    }
  });

  return hasVisitor;
}

export function joinControlChannel(channel = CONTROL_CHANNEL) {
  return (dispatch, getState, extras) => {
    const { PusherManager } = extras;

    const currentlySubscribed = getSubscribedChannels(getState());
    if (channel && !currentlySubscribed.has(channel)) {
      const subscription = PusherManager.client.subscribe(channel);

      getControlChannelBindings().forEach(({ event, callback }) =>
        subscription.bind(event, callback.bind(null, { dispatch, getState, extras }))
      );
      PusherManager.addSubscription(channel, subscription);

      dispatch({ type: types.JOINED_CONTROL_CHANNEL, payload: channel });
    }
  };
}

function getControlChannelBindings() {
  return [
    { event: 'force-refresh', callback: pusherControlForceRefresh },
  ];
}

function pusherControlForceRefresh({ dispatch }) {
  return dispatch({ type: types.FORCE_REFRESH });
}

function pusherVisitorProfileUpdated({ dispatch }, payload) {
  dispatch({ type: types.VISITOR_PROFILE_UPDATED_REMOTELY, payload });
}

export function leaveAllChannels() {
  return (dispatch, getState, extras) => {
    const { PusherManager } = extras;
    const channels = getSubscribedChannels(getState()).filter(
      channel => channel !== CONTROL_CHANNEL
    );
    channels.forEach(channel => {
      PusherManager.client.unsubscribe(channel);
      dispatch(leftNotificationChannel(channel));
    });
  };
}

