import 'styles/login.scss';

import React from 'react';
import { ChangeEvent, FormEvent } from 'react';
import { connect } from 'react-redux';
import { requestPasswordReset } from 'redux/actions/usersActions';
import { Link } from 'react-router-dom';
import Spinner from '../Shared/Spinner/Spinner';
import logo from 'images/logo.svg';
export interface Props {
  requestPasswordReset: (username: string) => Promise<any>;
}

interface State {
  username: string;
  resetSuccess: boolean;
  resetError: boolean;
  submitting: boolean;
}

export class ForgotPassword extends React.Component<Props, State> {
  state = { username: '', resetSuccess: false, resetError: false, submitting: false };

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: e.target.value });
  };

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { username, submitting } = this.state;
    if (submitting) return;

    try {
      this.setState({ submitting: true });
      await this.props.requestPasswordReset(username);
      this.setState({ resetSuccess: true, resetError: false });
    } catch (e) {
      this.setState({ resetSuccess: false, resetError: true });
    } finally {
      this.setState({ submitting: false });
    }
  };

  renderSuccess() {
    return (
      <div className="successful-reset">
        <div>
          <span className="check-mark" dangerouslySetInnerHTML={{ __html: '&check;' }} />
        </div>
        <div>
            We have e-mailed your password reset link. Please check your email and follow
            the instructions to reset your password. <Link to="/login">Click here</Link> to
            return to the login page.
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        <h1>Reset Your Password</h1>
        {this.state.resetError && (
          <div className="auth-errors">We can't find a user with that username.</div>
        )}
        <form role="form" onSubmit={this.handleSubmit}>
          <div>
            <input
              name="username"
              placeholder="Username"
              required={true}
              onChange={this.handleChange}
              value={this.state.username}
            />
          </div>
          <div>
            <button type="submit" disabled={this.state.submitting}>
              {this.state.submitting && <Spinner />}
              Reset Password
            </button>
          </div>
          <div>
            <small>
              A message will be sent to your email address containing a link to reset
              your password.
            </small>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="reset-wrapper login">
        <div className="reset-overlay-wrapper">
          <div>
            <div className="logo-wrapper">
              <img src={logo} alt="conversations logo" />
            </div>
            {this.state.resetSuccess ? this.renderSuccess() : this.renderForm()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(undefined, { requestPasswordReset })(ForgotPassword as any);
