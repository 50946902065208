import { ThunkAction } from 'redux-thunk';
import Constants from '../../constants';

function clearConversationAgentTakeover(channelName: string) {
  return (dispatch: any) => {
    dispatch({
      type: Constants.CONVERSATION_AGENT_TAKEOVER_REINSTATE,
      payload: { channelName },
    });
  };
}

function fetchConversationAgentTakeover(channelName: string): ThunkAction<any, any, any, any> {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({
      type: Constants.CONVERSATION_AGENT_TAKEOVER_REQUESTED,
      payload: { channelName },
    });
    return APIFactory.getInstance(getState())
      .post(`/conversation/agent/takeover`, { channelName })
      .then((response: any) => {
        dispatch({
          type: Constants.CONVERSATION_AGENT_TAKEOVER_RESPONDED,
          payload: { channelName, response },
        });
      })
      .catch((errors: any) => {
        dispatch({
          type: Constants.CONVERSATION_AGENT_TAKEOVER_EXCEPTION,
          payload: { channelName, errors: Array.isArray(errors) ? errors : [errors] },
        });
      });
  };
}

export {
  clearConversationAgentTakeover,
  fetchConversationAgentTakeover,
};
