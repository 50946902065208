import Immutable, { Map } from 'immutable';
import * as types from '../constants/actionTypes';

export const defaultFilter = 'vehicles';
export const defaultVehicleThumbnail =
    'https://s3.amazonaws.com/conversations-files/global/default-vehicle.png';

/**
 * state shape after results are received
 *  state {
 *      channel: 'foo'
 *      results: {
 *          vehicle {
 *              meta: {...}
 *              pages: {
 *                  "1": [....]
 *                  "2": [....]
 *              }
 *          },
 *          brochures {
 *              meta: {...}
 *              pages: {
 *                  "1": [....]
 *                  "2": [....]
 *              }
 *          }
 *      }
 *      calculatorResults: {...}
 * }
 */
const initialState = Map({
  channel: null,
  results: Map(),
});

export default function offers(state = initialState, { type, payload }) {
  switch (type) {
    case types.LOGGED_OUT:
      return initialState;
    case types.GC_SEARCH_SUCCESS:
      return gcSearchSuccess(state, payload);
    case types.CALCULATOR_SUCCESS:
      return calculatorSuccess(state, payload);
    case types.CONVERSATION_ACTIVATE:
      state = state.delete('calculatorResults');
      return state.delete('results');
    default:
      return state;
  }
}

function gcSearchSuccess(state, { channel, searchParams, response }) {
  const filter = searchParams.get('filter');

  return state.withMutations(state => {
    if (!searchParams.equals(state.getIn(['results', filter, 'meta', 'searchParams']))) {
      // different search for this filter, so clear previous results for this filter
      state.deleteIn(['results', filter]);
    }
    state.set('channel', channel);

    const meta = buildMeta(searchParams, response);
    state.setIn(['results', filter, 'meta'], Map(meta));
    state.setIn(
      ['results', filter, 'pages', String(meta.currentPage)],
      Immutable.fromJS(response.items || response.data)
    );
  });
}

/**
 * Different endpoints return different pagination formats.  Normalize that here.
 * @param searchPhrase
 * @param response
 * @returns {{searchPhrase: *, currentPage: number, total: number, numberPages: number}}
 */
function buildMeta(searchParams, response) {
  const currentPage = response.currentPage || 1;
  let total = 0;

  if (typeof response.total !== 'undefined') {
    total = response.total;
  } else if (response.data) {
    total = response.data.length;
  }

  return {
    searchParams,
    currentPage,
    total,
    numberPages: response.numberPages || 1,
  };
}

function calculatorSuccess(state, { channel, response }) {
  state = state.set('channel', channel);
  return state.set('calculatorResults', Immutable.fromJS(response));
}
