import { createSelector } from 'reselect';
import { List } from 'immutable';
import { makeSortByStringKey, makeSortByMomentKey } from 'businessLogic/util';

export const getCustomers = state => state.customers.get('customers');
export const getSelectedCustomerId = state => state.customers.get('selectedCustomerId');

export const getAllCustomers = createSelector([getCustomers], customers =>
  customers.sort(makeSortByStringKey('name'))
);

export const getSelectedCustomer = createSelector(
  [getAllCustomers, getSelectedCustomerId],
  (customers, selectedCustomerId) => customers.get(selectedCustomerId)
);

export const getSelectedCustomerDealerMessages = createSelector([getSelectedCustomer], customer =>
  customer.get('dealerMessages', List()).sort(makeSortByMomentKey('createdAt'))
);

export const getSelectedCustomerManagedChatPolicies = createSelector(
  [getSelectedCustomer],
  customer => customer.get('managedChatPolicies', List()).sort(makeSortByStringKey('name'))
);

export const areInitialCustomersFetched = state =>
  state.customers.get('initialFetchComplete', false);

export const getUserCustomers = state => state.customers.get('userCustomers');
