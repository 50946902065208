import { Map, Set } from 'immutable';
import * as types from '../constants/actionTypes';

const initialState = Map({
  regions: Set(),
  regionsFetching: false,
});

export default function channels(state = initialState, { type, payload }) {
  switch (type) {
    case types.REGIONS_FETCHING:
      return state.set('regionsFetching', true);
    case types.REGIONS_FETCH_ERROR:
      return state.set('regionsFetching', false);
    case types.REGIONS_FETCHED:
      return regionsFetched(state, payload);
    default:
      return state;
  }
}

function regionsFetched(state, { regions }) {
  state = state.set('regions', mapRegions(regions));
  return state.set('regionsFetching', false);
}

function mapRegions(regions) {
  return Set(regions.map(region => mapRegion(region)));
}

function mapRegion(region) {
  return Map({
    id: region.id,
    name: region.name,
  });
}
