import React from 'react';

const NoMatch = () => (
  <div className="no-match">
    <div className="alert alert-warning" role="alert">
      No matching route found
    </div>
  </div>
);

export default NoMatch;
