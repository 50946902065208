import * as types from 'redux/constants/actionTypes';

export interface Notification {
  type: string;
  payload: object;
}

/**
 * @param options object of react-notification-system options
 * https://www.npmjs.com/package/react-notification-system#creating-a-notification
 */
export function addNotification(options: object = {}): Notification {
  return {
    type: types.ADD_NOTIFICATION,
    payload: options,
  };
}

export function addErrorNotification(
  message: string = 'An error occurred',
  title: string = 'Uh oh!',
  level: string = 'error'
): Notification {
  return {
    type: types.ADD_NOTIFICATION,
    payload: { title, level, message },
  };
}

export function addSuccessNotification(
  message: string = 'Success',
  title: string = 'Success!',
  level: string = 'success'
) {
  return addNotification({ title, level, message });
}
