import { createSelector } from 'reselect';
import { Map, Seq, Set } from 'immutable';
import memoize from 'lodash.memoize';
import { getAllLocations } from 'selectors/locationSelectors';
import { makeSortByMomentKey, makeSortByStringKey, makeSortByNumericKey } from 'businessLogic/util';
import { getAllUsers, getLoggedInUserId, getActiveHotkey } from './userSelectors';
import {
  DEFAULT_AVATAR,
  DEFAULT_BOT_AVATAR,
  getAvatar,
  getInitials,
  getFullName,
} from '../businessLogic/userHelper';
import { getUnreadMessages, conversationTypes } from '../businessLogic/conversationHelper';

const getActiveConversationChannel = state => state.conversations.get('activeConversation');

const allConversations = state => state.conversations.get('conversations');

/**
 * @param state
 * @returns {Boolean}
 */
export const isCurrentConversationsFetched = state =>
  state.conversations.get('currentConversationsFetched', false);

/**
 * @param state
 * @returns {Boolean}
 */
export const areInitialTranscriptsFetched = state =>
  state.conversations.get('initialTranscriptsFetched', false);

const addCrmsToConvo = (locations, teams, convo) => {
  const teamIds = convo.getIn(['location', 'teamIds'], Set()).toMap();
  const crms = teamIds
    .flatMap(teamId => teams.getIn([teamId, 'crms']))
    .sort(makeSortByStringKey('name'));
  return convo.set('crms', crms);
};

/**
 * Get all conversations with hydrated location, visitor, crms and users, sorted by type, then date
 *      internal: always last, sorted by last message date
 *      visitor: always first, sorted by accepted date
 *
 * @param state
 * @returns {OrderedMap}
 */
export const getAllConversations = createSelector(
  [
    allConversations,
    memoize(state => (state.locations ? getAllLocations(state) : Map())),
    memoize(state => state.teams),
    memoize(state => state.visitors),
    getAllUsers,
  ],
  (conversations, locations, teams, visitors, users) =>
    conversations
      .map(addLocationToConvo.bind(null, locations))
      .map(addTeamToConvo.bind(null, teams))
      .map(addCrmsToConvo.bind(null, locations, teams))
      .map(addVisitorToConvo.bind(null, visitors))
      .map(addUsersToConvo.bind(null, users))
      .map(addDisplayType)
      .sort(makeSortByMomentKey('acceptedDate'))
      .sort(sortConversationsByType)
);

function sortConversationsByType(a, b) {
  if (a.get('isInternal') && !b.get('isInternal')) {
    return 1;
  } else if (!a.get('isInternal') && b.get('isInternal')) {
    return -1;
  } else if (
    a.get('isInternal') &&
    b.get('isInternal') &&
    a.get('lastMessageDate') &&
    b.get('lastMessageDate')
  ) {
    if (a.get('lastMessageDate').isBefore(b.get('lastMessageDate'))) {
      return 1;
    } else if (a.get('lastMessageDate').isAfter(b.get('lastMessageDate'))) {
      return -1;
    }
  }

  return 0;
}

function addLocationToConvo(locations, convo) {
  if (!locations) return convo;
  return convo.set('location', locations.get(convo.get('locationId')));
}

function addTeamToConvo(teams, convo) {
  if (!teams) return convo;
  return convo.set('team', teams.get(convo.get('teamId')));
}

function addVisitorToConvo(visitors, convo) {
  if (!visitors) return convo;
  return convo.set('visitor', visitors.get(convo.get('visitorId')));
}

function addUsersToConvo(users, convo) {
  if (!users) return convo;
  convo = convo.set('users', convo.get('userIds', Set()).map(userId => users.get(userId)));
  return convo.set('allUsers', convo.get('allUserIds', Set()).map(userId => users.get(userId)));
}

/**
 * Determine how to display this conversation type.  In most cases, the conversation type will
 * suffice.  In others, we have to look into more detail and override the conversation type (ex: cars.com).
 */
function addDisplayType(convo) {
  if (!convo) return convo;

  let displayType;
  switch (convo.get('type')) {
    case 'web':
      if (convo.getIn(['location', 'carsDealerId'])) {
        displayType = conversationTypes.get('cars_com');
      } else {
        displayType = conversationTypes.get('web');
      }
      break;
    default:
      displayType = conversationTypes.get(convo.get('type'));
      break;
  }

  return convo.merge(Map({ displayType }));
}

/**
 * Determine how to display this conversation type.  In most cases, the conversation type will
 * suffice.  In others, we have to look into more detail and override the conversation type (ex: cars.com).
 */
function addMessageDisplayType(convo, message) {
  if (!message || !convo) return message;

  let displayType;
  switch (message.get('conversationType')) {
    case 'web':
      if (convo.getIn(['location', 'carsDealerId'])) {
        displayType = conversationTypes.get('cars_com');
      } else {
        displayType = conversationTypes.get('web');
      }
      break;
    default:
      displayType = conversationTypes.get(message.get('conversationType'));
      break;
  }

  return message.merge(Map({ displayType }));
}

/**
 * @param state
 * @returns {OrderedMap}
 */
export const getUnendedConversations = createSelector([getAllConversations], allConvos =>
  allConvos.filter(convo => !convo.get('isEnded')).filter(convo => !convo.get('isInternal'))
);

/**
 * @param state
 * @returns {Boolean}
 */
export const hasActiveConversation = createSelector(
  [getActiveConversationChannel],
  activeChannel => !!activeChannel
);

function addHotkeyText(activeConvo, activeHotkey) {
  if (activeConvo && activeHotkey) {
    activeHotkey = activeHotkey.set('text', activeHotkey.getIn(['hotkey', 'text']));
    return activeConvo.set('activeHotkey', activeHotkey);
  } else {
    return activeConvo;
  }
}

/**
 * get currently selected conversation, hydrated with visitor, location, users, and active hotkey
 * @returns {Map}
 */
export const getActiveConversation = createSelector(
  [getActiveConversationChannel, getAllConversations, getActiveHotkey],
  (activeChannel, conversations, activeHotkey) => {
    let activeConvo = conversations.get(activeChannel);
    activeConvo = addHotkeyText(activeConvo, activeHotkey);
    return activeConvo;
  }
);

/**
 * Get all messages for the active conversation, hydrated with avatar and sentByMe, sorted by timestamp (asc)
 * @returns {Seq}
 */
export const getActiveConversationMessages = createSelector(
  [getActiveConversation, getAllUsers, getLoggedInUserId],
  (conversation, users, loggedInUserId) => {
    if (conversation) {
      return conversation
        .get('messages', Map())
        .sort(makeSortByNumericKey('messageId'))
        .sort(makeSortByMomentKey('timestamp'))
        .map(detectBotMessages)
        .map(detectFacebookMessengerMessages)
        .map(addMessageAvatar.bind(null, users, conversation))
        .map(addMessageAgentName.bind(null, users))
        .map(addMessageDisplayType.bind(null, conversation))
        .map(addSentOrReceived.bind(null, loggedInUserId))
        .groupBy(detectDayOfMessage)
        .toSeq();
    }
    return new Seq();
  }
);

// todo test
export const getConversationMessages = (state, conversation) => {
  if (!conversation) return Map();

  const users = getAllUsers(state);
  const convoUsers = conversation.get('userIds', Set());
  return conversation
    .get('messages', Map())
    .sort(makeSortByMomentKey('timestamp'))
    .map(detectBotMessages)
    .map(detectFacebookMessengerMessages)
    .map(addMessageAvatar.bind(null, users, conversation))
    .map(addMessageDisplayType.bind(null, conversation))
    .map(addSentOrReceived.bind(null, convoUsers.first()))
    .groupBy(detectDayOfMessage)
    .toSeq();
};

function detectBotMessages(message) {
  const isBot = message.get('type') === 'bot-to-visitor';
  return message.set('isBot', isBot);
}

function detectFacebookMessengerMessages(message) {
  const isFacebookMessenger = message.get('type') === 'facebook-messenger';
  return message.set('isFacebookMessenger', isFacebookMessenger);
}

function detectDayOfMessage(message) {
  return message.get('timestamp').format('MMMM D, YYYY');
}

function addMessageAvatar(users, convo, message) {
  let avatar = DEFAULT_AVATAR;
  let initials;
  if (message.get('isBot')) {
    avatar = DEFAULT_BOT_AVATAR;
  } else if (message.get('userId')) {
    avatar = getAvatar(users.get(message.get('userId')));
    initials = getInitials(users.get(message.get('userId')));
  }
  return message.set('avatar', avatar).set('initials', initials);
}

function addMessageAgentName(users, message) {
  let agentName = '';
  if (message.get('isBot')) {
    agentName = 'virtual assistant';
  } else if (message.get('userId')) {
    agentName = getFullName(users.get(message.get('userId')));
  }
  return message.set('agentName', agentName);
}

function addSentOrReceived(loggedInUserId, message) {
  return message.set(
    'sentByMe',
    message.get('userId') === loggedInUserId ||
      message.get('isBot') ||
      message.get('isFacebookMessenger')
  );
}

/**
 * Get a Map containing unread message counts for all conversations
 * @returns {Map}
 */
export const getUnreadMessageCounts = createSelector(
  [getAllConversations, getLoggedInUserId],
  (conversations, loggedInUserId) => conversations.map(getUnreadCount.bind(null, loggedInUserId))
);

function getUnreadCount(loggedInUserId, conversation) {
  return getUnreadMessages(loggedInUserId, conversation).size;
}

/**
 * Get waitingOnMe and lastMessageSent for each conversation
 * @returns {Map}
 */
export const getWaitingStatuses = createSelector([getAllConversations], conversations =>
  conversations.map(getWaitingStatus)
);

function getWaitingStatus(conversation) {
  let waitingOnMe = false;
  let lastMessageSent = null;

  const lastMessage = conversation
    .get('messages', Map())
    .filter(filterMessageMessages)
    .sort(makeSortByMomentKey('timestamp'))
    .last();

  if (lastMessage) {
    lastMessageSent = lastMessage.get('timestamp');
    if (lastMessage.get('visitorId')) {
      waitingOnMe = true;
    }
  } else {
    waitingOnMe = true;
  }
  return Map({ waitingOnMe, lastMessageSent });
}

/**
 * filter message collection to only include those with the category of "message"
 * @param message
 * @returns {boolean}
 */
function filterMessageMessages(message) {
  return message.get('category') === 'message';
}

/**
 * a selector that returns a function to get all messages for a particular conversation
 */
const conversationMessagesSelector = createSelector([getAllConversations], conversations =>
  memoize(channel => conversations.getIn([channel, 'messages'], Map()))
);

/**
 * a selector that returns a function that returns a Map to get all received messages for a particular conversation
 * @returns {function}
 */
export const receivedMessagesSelector = createSelector(
  [
    conversationMessagesSelector,
    memoize(state => state.visitors),
    getAllUsers,
    getLoggedInUserId,
  ],
  (getMessages, visitors, users, userId) =>
    memoize(channel =>
      getMessages(channel)
        .filter(filterMessageMessages)
        .filter(filterMessagesByUserId.bind(null, userId))
        .map(addSenderToMessage.bind(null, visitors, users))
        .sort(makeSortByMomentKey('timestamp'))
    )
);

/**
 * filter message collection to only include those that were not sent by logged in user
 * @param userId
 * @param message
 * @returns {boolean}
 */
function filterMessagesByUserId(userId, message) {
  return message.get('userId') !== userId;
}

function addSenderToMessage(visitors, users, message) {
  if (visitors) message = message.set('visitor', visitors.get(message.get('visitorId')));
  if (users) message = message.set('user', users.get(message.get('userId')));

  return message;
}
