/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkAction } from 'redux-thunk';
import Constants from '../../constants';

export interface FetchLocationSettingsResponse {
  settings: {
    [key: string]: string;
  };
}

export function fetchLocationSettings(locationGuid: string): ThunkAction<any, any, any, any> {
  return (dispatch: any, getState: any, { APIFactory }: any): any => {
    dispatch({
      type: Constants.LOCATION_SETTINGS_REQUESTED,
      payload: { locationGuid },
    });
    return APIFactory.getInstance(getState())
      .get(`/embed/v4/settings/${locationGuid}`)
      .then((response: any) => {
        dispatch({
          type: Constants.LOCATION_SETTINGS_RESPONDED,
          payload: { locationGuid, response },
        });
      })
      .catch((errors: any) => {
        dispatch({
          type: Constants.LOCATION_SETTINGS_EXCEPTION,
          payload: { locationGuid, errors: Array.isArray(errors) ? errors : [errors] },
        });
      });
  };
}
