import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import React from 'react';

export type SplitProviderProps = {
  config?: SplitIO.IBrowserSettings;
  children: React.ReactNode;
};

const SplitProvider = ({ config, children }: SplitProviderProps) => {

  if (!config || !config.core?.authorizationKey || !config?.core?.key) return <>{children}</>;

  return (
    <SplitFactoryProvider config={config}>
      {children}
    </SplitFactoryProvider>
  );
};

export default SplitProvider;
