import { Map } from 'immutable';
import * as types from '../constants/actionTypes';

const initialState = Map({
  isFetchingGarageLink: false,
  isFetchingOsGarage: false,
});

export default function vehicleOfInterest(state = initialState, { type, payload, channel }) {
  switch (type) {
    case types.UPDATE_VEHICLE_OF_INTEREST:
      return state.setIn([`${channel}`, 'vehicle'], payload);
    case types.UPDATE_GARAGE_LINK_LOADER:
      return state.set('isFetchingGarageLink', payload);
    case types.OS_GARAGE_FETCHING:
      return state.set('isFetchingOsGarage', true);
    case types.OS_GARAGE_FETCHED:
      state.set('isFetchingOsGarage', false);

      return state.setIn([`${channel}`, 'garage'], {
        trades: payload.trades,
        vehicles: payload.vehicles,
      });
    case types.OS_GARAGE_FETCH_ERROR:
      return state.set('isFetchingOsGarage', false);
    default:
      return state;
  }
}
