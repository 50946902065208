import moment from "moment";
import assign from "lodash.assign";
import { Set } from "immutable";
import {
  redirectToRoute,
  buildFormErrors,
  castToNull,
} from "businessLogic/util";
import { getSelectedCustomerId } from "selectors/customerSelectors";
import { getAllLocations } from "selectors/locationSelectors";
import * as types from "../constants/actionTypes";
import {
  addErrorNotification,
  addSuccessNotification,
} from "../actions/notificationActions";
import { setSelectedCustomer } from "../actions/customerActions";
import { fetchLocationUserStatus } from "../actions/usersActions";
import { FORM_ERROR } from "final-form";

// todo: missing unit test
export function fetchLocation(locationId) {
  return (dispatch, getState, { APIFactory }) => {
    const location = getAllLocations(getState()).get(locationId);

    if (
      !location ||
      isLocationFetching(getState(), locationId) ||
      locationFetchedRecently(location)
    ) {
      return Promise.resolve();
    }

    dispatch({ type: types.LOCATION_FETCHING, payload: locationId });

    const api = APIFactory.getInstance(getState());
    return api
      .get(`/callcenter/location/${locationId}`)
      .then((response) => {
        dispatch({
          type: types.LOCATION_FETCHED,
          payload: { locationId, response: response.data },
        });
      })
      .catch(() => {
        dispatch({ type: types.LOCATION_FETCH_ERROR, payload: { locationId } });
      });
  };
}

function isLocationFetching(state, locationId) {
  return state.locations.hasIn(["fetching", locationId]);
}

function locationFetchedRecently(location, maxSeconds = 120) {
  if (!location.get("lastFetched")) return false;
  const secondsOld = Math.abs(
    location.get("lastFetched").diff(moment(), "seconds")
  );
  return secondsOld < maxSeconds;
}

export function setSelectedLocation(locationId) {
  return (dispatch) => {
    dispatch({ type: types.SET_SELECTED_LOCATION, payload: locationId });
    return locationId;
  };
}

export function fetchCustomerLocations(customerId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({
      type: types.CUSTOMER_LOCATIONS_FETCHING,
      payload: { customerId },
    });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/customer/${customerId}/locations`)
      .then((response) => {
        dispatch({
          type: types.CUSTOMER_LOCATIONS_FETCHED,
          payload: { customerId, locations: response.data.locations },
        });
      })
      .catch(() => {
        dispatch({
          type: types.CUSTOMER_LOCATIONS_FETCH_ERROR,
          payload: { customerId },
        });
      });
  };
}

export function fetchCustomerLocationStatus(customerId) {
  return (dispatch, getState) => {
    const locationIds = getState().customers.getIn(
      ["customers", customerId, "locationIds"],
      Set()
    );
    const promises = locationIds.map((locationId) =>
      dispatch(fetchLocationUserStatus(locationId))
    );
    return Promise.all(promises.toArray());
  };
}

export function fetchSelectedCustomerLocationStatus() {
  return (dispatch, getState) =>
    dispatch(fetchCustomerLocationStatus(getSelectedCustomerId(getState())));
}

export function fetchLocationDealerMessages(locationId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({
      type: types.LOCATION_DEALER_MESSAGES_FETCHING,
      payload: { locationId },
    });

    const api = APIFactory.getInstance(getState());
    return api
      .get(`/location/${locationId}/dealermessages`)
      .then((response) => {
        dispatch({
          type: types.LOCATION_DEALER_MESSAGES_FETCHED,
          payload: { locationId, messages: response.data.items },
        });
      })
      .catch(() => {
        dispatch({
          type: types.LOCATION_DEALER_MESSAGES_FETCH_ERROR,
          payload: { locationId },
        });
        dispatch(
          addErrorNotification("Error fetching messages from this dealer.")
        );
      });
  };
}

export function deactivateLocation(locationId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_DELETING });

    const api = APIFactory.getInstance(getState());
    return api
      .delete(`/admin/location/${locationId}`)
      .then(() => {
        dispatch({ type: types.LOCATION_DELETED, payload: { locationId } });
      })
      .catch(() => {
        dispatch({
          type: types.LOCATION_DELETE_ERROR,
          payload: { locationId },
        });
        dispatch(addErrorNotification("Error deactivating location"));
      });
  };
}
/**
 * Create a new location
 * 3 step process walking down the promise chain:
 *  1. create location
 *  2. update location settings
 *  3. upload avatar (optional)
 * @param formData
 */
export function createLocation(formData, reloadOnSubmit) {
  return (dispatch, getState, { APIFactory }) => {
    let locationId;
    const locationPayload = buildLocationPayload(formData);

    dispatch({ type: types.LOCATION_UPDATING });

    const api = APIFactory.getInstance(getState());
    return api
      .post("/admin/location", locationPayload) // save location
      .then((response) => {
        locationId = response.data.id;
        const guid = response.data.guid;
        dispatch({
          type: types.LOCATION_SAVED,
          payload: assign({}, locationPayload, { id: locationId, guid }),
        });
      })
      .then(() => {
        // save location settings
        const locationSettingsPayload = buildLocationSettingsPayload(formData);
        return api
          .patch(
            `/admin/location/${locationId}/settings`,
            locationSettingsPayload
          )
          .then((response) => {
            dispatch({
              type: types.LOCATION_SETTINGS_SAVED,
              payload: assign({}, locationSettingsPayload, { locationId }),
            });
          });
      })
      .then(() => {
        // save avatar if it exists
        if (formData.avatar && formData.avatar[0]) {
          return saveAvatar(api, dispatch, locationId, formData.avatar);
        } else {
          // since they didn't upload an avatar, we need to use the customer's avatar instead
          const customerAvatar = getState().customers.getIn([
            "customers",
            formData.customerId,
            "avatar",
          ]);
          dispatch({
            type: types.LOCATION_AVATAR_SAVED,
            payload: {
              locationId,
              avatar: customerAvatar,
              avatarType: "customer",
            },
          });
          return Promise.resolve();
        }
      })
      .then(() => {
        dispatch({ type: types.LOCATION_UPDATED });
        if (reloadOnSubmit) {
          dispatch({ type: types.SET_SELECTED_LOCATION, payload: locationId });
          redirectToRoute("/admin/location/edit");
        } else {
          redirectToRoute("/admin/location");
        }
      })
      .catch((error) => {
        dispatch({ type: types.LOCATION_UPDATE_ERROR });
        return buildFormErrors("Error creating location.", error.response);
      });
  };
}

function saveAvatar(api, dispatch, locationId, avatar) {
  const data = new window.FormData();
  data.append("avatar", avatar[0]);
  return api
    .post(`/admin/location/${locationId}/avatar`, data)
    .then((response) => {
      return Promise.resolve(response);
    })
    .then((response) =>
      dispatch({
        type: types.LOCATION_AVATAR_SAVED,
        payload: {
          locationId,
          avatar: response.data.avatar,
          avatarType: "location",
        },
      })
    );
}

function buildLocationPayload(formData) {
  const {
    customerId,
    name,
    url,
    contactEmail,
    contactName,
    timezone,
    phoneNumber,
    smsPhoneNumber,
    youtubeChannelId,
    diDashboardKey,
    slug,
    address,
    city,
    state,
    country,
    postcode,
    offlineMessage,
    billable,
    facebookPageId,
    facebookPageAccessToken,
    gaPropertyId,
    hasDms,
    ccid,
    engineId,
    carsDealerId,
    locationTypeId,
    locationOwnerId,
    oemId,
    ein,
    businessLegalName,
    twilioProfile,
    twilioBrand,
    twilioCampaign,
    twilioMessagingServiceId,
    twilioStatus,
    twilioFailureReason,
  } = formData;

  return {
    customerId,
    name,
    url,
    contactEmail,
    contactName,
    timezone,
    phoneNumber,
    smsPhoneNumber: castToNull(smsPhoneNumber),
    youtubeChannelId: castToNull(youtubeChannelId),
    diDashboardKey: castToNull(diDashboardKey),
    slug: castToNull(slug),
    address,
    city,
    state,
    country,
    postcode,
    offlineMessage,
    billable,
    facebookPageId: castToNull(facebookPageId),
    facebookPageAccessToken: castToNull(facebookPageAccessToken),
    gaPropertyId: castToNull(gaPropertyId),
    hasDms,
    ccid: castToNull(ccid),
    engineId: castToNull(engineId),
    carsDealerId: castToNull(carsDealerId),
    locationOwnerId,
    locationTypeId,
    oemId: castToNull(oemId),
    ein: ein && ein.replace(/[-—]/g, ''),
    businessLegalName,
    twilioProfile,
    twilioBrand,
    twilioCampaign,
    twilioMessagingServiceId,
    twilioStatus,
    twilioFailureReason,
  };
}

function buildLocationSettingsPayload(formData) {
  const {
    requireVisitorFields,
    enableManagedOverage,
    managedAllotment,
    enableManagedChat,
    unlimitedManaged,
    offlineHide,
    videoEnabled,
    bypassCustomizePanel,
    defaultFilterKeyword,
    locale,
    sendAllLeads,
    isVistaDash,
    isClarivoy,
    fbMessengerEnabled,
    fbMarketplaceEnabled,
    fbMarketplaceInventoryEnabled,
    isTradePendingEnabled,
    isGptEnabled,
    logUserStatus,
    directToFlow,
    oemDealerType,
    oemDealerCode,
    oemLocationCode,
    oemLocationOSSSubscriber,
    inventoryDealerId,
    inventoryApiId,
    cdkWebsiteId,
  } = formData;

  return {
    requireVisitorFields: !!requireVisitorFields,
    requiredFields: buildRequiredVisitorFields(formData),
    offlineAction: offlineHide ? "hide" : "message",
    bypassCustomizePanel: !!bypassCustomizePanel,
    defaultFilterKeyword,
    videoEnabled,
    enableManagedOverage,
    enableManagedChat,
    unlimitedManaged,
    managedAllotment,
    locale,
    sendAllLeads,
    isVistaDash,
    isClarivoy,
    fbMessengerEnabled,
    fbMarketplaceEnabled,
    fbMarketplaceInventoryEnabled,
    isTradePendingEnabled,
    isGptEnabled,
    logUserStatus,
    directToFlow,
    oemDealerType,
    oemDealerCode,
    oemLocationCode,
    oemLocationOSSSubscriber,
    inventoryDealerId,
    inventoryApiId,
    cdkWebsiteId,
  };
}

function buildRequiredVisitorFields({
  requireVisitorFields,
  requireFirstName,
  requireLastName,
  requireEmail,
}) {
  if (!!requireVisitorFields !== true) return null;

  const requiredFields = [];

  if (requireFirstName) requiredFields.push("first_name");
  if (requireLastName) requiredFields.push("last_name");
  if (requireEmail) requiredFields.push("email");

  return requiredFields.join(",");
}

export function updateLocation(formData) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_UPDATING });

    const api = APIFactory.getInstance(getState());
    const locationId = formData.locationId;

    // location data
    const locationPayload = buildLocationPayload(formData);
    const locationDataPromise = api
      .patch(`/admin/location/${locationId}`, locationPayload) // save location
      .then(() => {
        dispatch({
          type: types.LOCATION_SAVED,
          payload: assign({}, locationPayload, { id: locationId }),
        });
      })
      .catch(() => {
        throw new Error("Error saving location information.");
      });

    // location settings
    const locationSettingsPromise = updateLocationSettingsPromise(
      formData,
      api,
      dispatch
    );

    // location avatar
    let avatarPromise;
    if (formData.avatar && formData.avatar[0]) {
      avatarPromise = saveAvatar(
        api,
        dispatch,
        locationId,
        formData.avatar
      ).catch((error) => {
        // throw buildFormErrors('Error saving avatar.', error.response);
        throw new Error("Error saving avatar.");
      });
    } else {
      avatarPromise = Promise.resolve();
    }

    // run all three API calls in parallel
    return Promise.all([
      locationDataPromise,
      locationSettingsPromise,
      avatarPromise,
    ])
      .then(() => {
        dispatch({ type: types.LOCATION_UPDATED });
        return undefined;
      })
      .catch((error) => {
        dispatch({ type: types.LOCATION_UPDATE_ERROR });
        return { [FORM_ERROR]: error.message };
      });
  };
}

export function updateLocationSettingsPromise(formData, api, dispatch) {
  // location settings
  const locationId = formData.locationId;
  const locationSettingsPayload = buildLocationSettingsPayload(formData);
  return api
    .patch(`/admin/location/${locationId}/settings`, locationSettingsPayload)
    .then((response) => {
      dispatch({
        type: types.LOCATION_SETTINGS_SAVED,
        payload: assign({}, locationSettingsPayload, { locationId }),
      });
    })
    .catch((error) => {
      throw buildFormErrors("Error saving location settings.", error.response);
    });
}

export function removeAvatar(locationId, customerId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_AVATAR_DELETING, payload: { locationId } });

    const customerAvatar = getState().customers.getIn([
      "customers",
      customerId,
      "avatar",
    ]);

    const api = APIFactory.getInstance(getState());
    return api
      .delete(`/admin/location/${locationId}/avatar`)
      .then((response) =>
        dispatch({
          type: types.LOCATION_AVATAR_DELETED,
          payload: { locationId, customerAvatar },
        })
      )
      .catch(() => {
        dispatch({
          type: types.LOCATION_AVATAR_DELETE_ERROR,
          payload: { locationId },
        });
        dispatch(addErrorNotification("Error deleting location avatar."));
      });
  };
}

/**
 * Get ALL customers and locations. Can only be used by super admin users.
 */
export function searchAllLocationsAndCustomers(search) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.ALL_LOCATIONS_FETCHING });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/admin/search?query=${search}`)
      .then((response) => {
        dispatch({ type: types.ALL_LOCATIONS_FETCHED });
        return response.data;
      })
      .catch(() => {
        dispatch({ type: types.ALL_LOCATIONS_FETCH_ERROR });
        dispatch(addErrorNotification("Error fetching locations."));
      });
  };
}

export function updateLocationSettings(locationId, settings) {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    return api
      .patch(`/admin/location/${locationId}/settings`, settings)
      .then(() => {
        dispatch({
          type: types.LOCATION_SETTINGS_SAVED,
          payload: assign({}, settings, { locationId }),
        });
      });
  };
}

export function uploadFile(file) {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    const data = new window.FormData();
    data.append("upload", file[0]);

    return api
      .post("/utility/file", data, { timeout: 60000 })
      .then((response) => Promise.resolve(response));
  };
}

/**
 * Creates or cancels an SMS Phone Number.
 */
export function toggleTwilio(locationId, hasTwilio) {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    let req;
    if (hasTwilio) {
      req = api.delete(`/admin/twilio?locationId=${locationId}`);
    } else {
      req = api.post("/admin/twilio", { locationId });
    }

    dispatch({ type: types.LOCATION_UPDATING });

    return req
      .then((response) => {
        dispatch({
          type: types.LOCATION_SETTINGS_SAVED,
          payload: assign(
            {},
            { smsPhoneNumber: response.data.smsPhoneNumber },
            { locationId }
          ),
        });
        dispatch({ type: types.LOCATION_UPDATED });
        dispatch(
          addSuccessNotification(
            `Successfully ${
              hasTwilio ? "canceled the" : "created a"
            } Twilio number.`
          )
        );
      })
      .catch((err) => {
        dispatch({ type: types.LOCATION_UPDATED });
        dispatch(
          addErrorNotification(
            `Unable to ${hasTwilio ? "cancel this" : "create a"} Twilio number.`
          )
        );
      });
  };
}

/**
 * Resubmits a location to Twilio for verification
 */
export function resubmitTwilio(locationId) {
  return (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    const req = api.post("/admin/resubmit-twilio-verification", { locationId });

    dispatch({ type: types.LOCATION_UPDATING });

    return req
      .then((response) => {
        dispatch({
          type: types.LOCATION_SETTINGS_SAVED,
          payload: assign(
            {},
            {
              twilioCampaignStatus: response.data.twilioCampaignStatus,
              twilioFailureReason: response.data.twilioFailureReason,
            },
            { locationId }
          ),
        });
        dispatch({ type: types.LOCATION_UPDATED });
        dispatch(
          addSuccessNotification("Successfully resubmitted location to Twilio.")
        );
      })
      .catch((err) => {
        dispatch({ type: types.LOCATION_UPDATED });
        dispatch(
          addErrorNotification(`Unable to resubmit this location to Twilio: ${err.response.data.errors}`)
        );
      });
  };
}

export function reactivateLocation(locationId, password) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_REACTIVATING });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/admin/location/${locationId}/reactivate?password=${password}`)
      .then((response) => {
        dispatch({ type: types.LOCATION_REACTIVATED });
        dispatch(setSelectedCustomer(getSelectedCustomerId(getState())));
        dispatch(
          addSuccessNotification(
            `Successfully Reactivated location ID ${locationId}`
          )
        );
      })
      .catch((err) => {
        dispatch({ type: types.LOCATION_REACTIVATION_ERROR });
        if (err.response.status === 403) {
          dispatch(addErrorNotification(`Incorrect password`));
        } else if (err.response.data.errors) {
          dispatch(
            addErrorNotification(
              `Error reactivating location: ${err.response.data.errors}`
            )
          );
        } else {
          dispatch(
            addErrorNotification(`Error reactivating location: ${err.message}`)
          );
        }
      });
  };
}

export function duplicateLocation(locationId, password) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_DUPLICATING });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/admin/location/${locationId}/duplicate?password=${password}`)
      .then((response) => {
        dispatch({ type: types.LOCATION_DUPLICATED });
        dispatch(setSelectedCustomer(getSelectedCustomerId(getState())));
        dispatch(
          addSuccessNotification(
            `Successfully Duplicated location ID ${locationId}`
          )
        );
      })
      .catch((err) => {
        dispatch({ type: types.LOCATION_DUPLICATING_ERROR });
        if (err.response.status === 403) {
          dispatch(addErrorNotification(`Incorrect password`));
        } else if (err.response.data.errors) {
          dispatch(
            addErrorNotification(
              `Error duplicating location: ${err.response.data.errors}`
            )
          );
        } else {
          dispatch(
            addErrorNotification(`Error duplicating location: ${err.message}`)
          );
        }
      });
  };
}

export function getFBMPRejections(locationId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_FBMP_INVENTORY_FETCHING });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/admin/fb-export-inventory-audit/${locationId}`)
      .then((response) => {
        dispatch({ type: types.LOCATION_FBMP_INVENTORY_FETCHED });
        return response.data;
      })
      .catch((err) => {
        dispatch({ type: types.LOCATION_FBMP_INVENTORY_ERROR });
        if (err.response.data.errors) {
          dispatch(
            addErrorNotification(
              `Error fetching data: ${err.response.data.errors}`
            )
          );
        } else {
          dispatch(addErrorNotification(`Error fetching data: ${err.message}`));
        }
      });
  };
}

export function getGBMResults(locationName) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_GBM_RESULTS_FETCHING });
    const api = APIFactory.getInstance(getState());
    return api
      .get(`/admin/location/gmb?pageToken&perPage&search=${locationName}`)
      .then((response) => {
        dispatch({ type: types.LOCATION_GBM_RESULTS_FETCHED });
        return response.data;
      })
      .catch((err) => {
        dispatch({ type: types.LOCATION_GBM_RESULTS_ERROR });
        if (err.response.data.errors) {
          dispatch(
            addErrorNotification(
              `Error fetching data: ${err.response.data.errors}`
            )
          );
        } else {
          dispatch(addErrorNotification(`Error fetching data: ${err.message}`));
        }
      });
  };
}

export function startGBMLocation(locationId, googlePlaceId, googlePlaceName, gbmContactName, gbmContactEmail) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_GBM_STARTING });
    const api = APIFactory.getInstance(getState());
    return api
      .post(`/admin/location/${locationId}/gbm/start`, {
        googlePlaceId,
        googlePlaceName,
        gbmContactName,
        gbmContactEmail,
      })
      .then((response) => {
        dispatch({ type: types.LOCATION_GBM_STARTED });
        return response.data;
      })
      .catch((err) => {
        dispatch({ type: types.LOCATION_GBM_START_ERROR });
        if (err.response.data.errors) {
          dispatch(
            addErrorNotification(
              `Error starting GBM location: ${err.response.data.errors}`
            )
          );
        } else {
          dispatch(
            addErrorNotification(`Error starting GBM location ${err.message}`)
          );
        }
      });
  };
}

export function removeGBMLocation(locationId) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_GBM_REMOVING });
    const api = APIFactory.getInstance(getState());
    return api
      .post(`/admin/location/${locationId}/gbm/remove`)
      .then((response) => {
        dispatch({ type: types.LOCATION_GBM_REMOVED });
        return response.data;
      })
      .catch((err) => {
        dispatch({ type: types.LOCATION_GBM_REMOVE_ERROR });
        if (err.response.data.errors) {
          dispatch(
            addErrorNotification(
              `Error removing GBM location: ${err.response.data.errors}`
            )
          );
        } else {
          dispatch(
            addErrorNotification(`Error removing GBM location: ${err.message}`)
          );
        }
      });
  };
}

export function toggleGoogleBusinessMessages(locationId, toggleState) {
  return (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_GBM_MESSAGE_TOGGLING });
    const api = APIFactory.getInstance(getState());
    const endpoint = toggleState
      ? `/admin/location/${locationId}/gbm/enable`
      : `/admin/location/${locationId}/gbm/disable`;
    return api
      .post(endpoint)
      .then((response) => {
        dispatch({ type: types.LOCATION_GBM_MESSAGE_TOGGLED });
        return response.data;
      })
      .catch((err) => {
        dispatch({ type: types.LOCATION_GBM_MESSAGE_ERROR });
        if (err.response.data.errors) {
          dispatch(
            addErrorNotification(
              `Error removing GBM location: ${err.response.data.errors}`
            )
          );
        } else {
          dispatch(
            addErrorNotification(`Error removing GBM location: ${err.message}`)
          );
        }
      });
  };
}

export function deleteUserFromLocation(api, dispatch, locationId, userId) {
  return api
    .delete('/admin/user/location', { data: { userId, locationId } })
    .then(() => dispatch({ type: types.USER_DELETED_FROM_LOCATION, payload: { location, userId } }));
}

export function addUserToLocation(api, dispatch, locationId, userId) {
  return api
    .post('/admin/user/location', { userId, locationId })
    .then(() => dispatch({ type: types.USER_ADDED_TO_LOCATION, payload: { location, userId } }));
}
