import { Map, Set } from 'immutable';

export const DYNAMIC_BDC_ID = 239;
export const CARCHAT24_ID = 216352;
export const BETA_BDC_ID = 220230;

export function addCustomer(state, customer) {
  return state.mergeDeepIn(
    ['customers', customer.id],
    Map({ id: customer.id, locationIds: Set(customer.locationIds) })
  );
}

export function hasCustomer(state, customerId) {
  return state.hasIn(['customers', customerId]);
}

export function addLocationToCustomer(state, customerId, locationId) {
  if (hasCustomer(state, customerId)) {
    return state.updateIn(['customers', customerId, 'locationIds'], ids => ids.add(locationId));
  }
  return state;
}
