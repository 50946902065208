import { createSelector } from 'reselect';
import { getActiveConversation } from './conversationSelectors';

export const getAllVisitors = state => state.visitors;

export const getActiveVisitor = createSelector(
  [getActiveConversation, getAllVisitors],
  (activeConvo, allVisitors) => {
    if (!activeConvo) return undefined;
    return allVisitors.get(activeConvo.get('visitorId'));
  }
);
