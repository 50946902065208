import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { getAllUsers } from './userSelectors';
import { getAllLocations } from './locationSelectors';
import { getAllTeams } from './teamSelectors';
import { getAllVisitors } from './visitorSelectors';
import { conversationTypes } from '../businessLogic/conversationHelper';

const allOffers = state => state.offers.get('offers');
export const isInitialOffersFetched = state => state.offers.get('initialFetchCompleted');

function addVisitor(visitors, offer) {
  if (!visitors || !offer.get('visitorId')) return offer;
  return offer.set('visitor', visitors.get(offer.get('visitorId')));
}

function addLocation(locations, offer) {
  if (!locations || !offer.get('locationId')) return offer;
  return offer.set('location', locations.get(offer.get('locationId')));
}

function addTeam(teams, offer) {
  if (!teams || !offer.get('teamId')) return offer;
  return offer.set('team', teams.get(offer.get('teamId')));
}

function addFromUser(users, offer) {
  if (!users || !offer.get('fromUserId')) return offer;
  return offer.set('fromUser', users.get(offer.get('fromUserId')));
}

/**
 * Determine how to display this offer type.  In most cases, the offer type will
 * suffice.  In others, we have to look into more detail and override the offer type (ex: cars.com).
 */
function addDisplayType(offer) {
  if (!offer) return offer;

  let displayType;
  switch (offer.get('conversationType')) {
    case 'web':
      if (offer.getIn(['location', 'carsDealerId'])) {
        displayType = conversationTypes.get('cars_com');
      } else {
        displayType = conversationTypes.get('web');
      }
      break;
    default:
      displayType = conversationTypes.get(offer.get('conversationType'));
      break;
  }

  return offer.merge(Map({ displayType }));
}

export const getAllOffers = createSelector(
  [allOffers, getAllVisitors, getAllLocations, getAllTeams, getAllUsers],
  (offers, visitors, locations, teams, users) =>
    offers
      .toSeq()
      .map(addVisitor.bind(null, visitors))
      .map(addLocation.bind(null, locations))
      .map(addTeam.bind(null, teams))
      .map(addFromUser.bind(null, users))
      .map(addDisplayType)
);
